<template>
   <div class="TaskEdit">
      <template v-if="!showSettings">
         <div class="task">
            <div class="title">{{ service.customerName }} {{ service.projectName }}</div>
            <div class="header">
               <div class="name">
                  <InputBasic class="name"
                     :readonly="isReadonly"
                     ref="serviceName"
                     v-model="service.name"
                     @input="onServiceChange"
                     @enter="onEditEnd" />
               </div>
               <div class="status">
                  <Status :readonly="isReadonly"
                     :exclude="['billed', 'paid']"
                     v-model="service.status"
                     @input="onServiceChange" />
               </div>
               <div class="btnSettings"
                  @click="onShowSettings(true)">
                  <Icon icon="iconSettings" />
               </div>
               <div v-if="billShow && service.pdf"
                  class="btnBill"
                  @click="onPdfShow(service)">
                  <Icon icon="iconBill" />
               </div>
            </div>
            <div class="assignedToRow">
               <div class="assignedTo">
                  <label>Zugewiesen an</label>
                  <div class="assignedToList">
                     <AssignedTo :config="config"
                        :service="service"
                        :readonly="isReadonly"
                        @update="update('assignedTo')" />
                  </div>
               </div>
               <div class="right">
                  <Input class="estimatedMinutes noNotification"
                     label="Geplant"
                     :readonly="isReadonly"
                     :value="formatToDecimal(service.estimatedMinutes)"
                     :options="{ after: 'Std.', mask: 'number' }"
                     @input="onServiceChange"
                     @blur="onEstimatedMinutesChange" />
                  <Input class="totalMinutes noNotification"
                     label="Total"
                     :readonly="true"
                     :value="formatToDecimal(service.totalMinutes)"
                     :options="{ after: 'Std.', mask: 'number', readonly: true }" />
               </div>
            </div>
         </div>
         <div class="timeEntrys">
            <div class="title">Arbeitszeiten</div>
            <div class="row">
               <TimeEntrys :config="config"
                  :readonly="timeEntrysReadonly"
                  :task="service"
                  @update="update('timeEntry')" />
            </div>
         </div>
         <BillingSlideDown v-if="billingShow"
            :show="billShow">
            <div class="billing">
               <div class="settings">
                  <CheckBox class="small"
                     :readonly="isReadonly"
                     v-model="settings.showText"
                     @input="onSettingsUpdate">Arbeitszeittext</CheckBox>
                  <CheckBox class="small"
                     :readonly="isReadonly"
                     v-model="settings.showDate"
                     @input="onSettingsUpdate">Datum</CheckBox>
                  <CheckBox class="small"
                     :readonly="isReadonly"
                     v-model="settings.showEmployee"
                     @input="onSettingsUpdate">Mitarbeiter</CheckBox>
                  <CheckBox class="small"
                     :readonly="isReadonly"
                     v-model="settings.showDuration"
                     @input="onSettingsUpdate">Dauer</CheckBox>
                  <CheckBox class="small"
                     :readonly="isReadonly"
                     v-model="settings.showHourlyRate"
                     @input="onSettingsUpdate">Stundensatz</CheckBox>
                  <CheckBox class="small"
                     :readonly="isReadonly"
                     v-model="settings.showFromTo"
                     @input="onSettingsUpdate">Beginn/Ende</CheckBox>
                  <CheckBox class="small"
                     :readonly="isReadonly"
                     v-model="settings.showNonBillable"
                     @input="onSettingsUpdate">n. verrechenbar</CheckBox>
               </div>
               <TaskBilling :service="service"
                  :readonly="isReadonly"
                  mode="edit"
                  @update="onServiceChange"
                  :config="config" />

            </div>
         </BillingSlideDown>
         <div v-if="!isReadonly"
            class="action">
            <Button v-if="showDeleteBtn"
               class="red"
               @click="$emit('delete')">Löschen</Button>
            <Button class="undo"
               :enabled="undoEnabled"
               @click="undoChanges">
               <div class="undo">
                  <span>Rückgängig</span>
                  <Icon :icon="$static.iconUndo" />
               </div>
            </Button>
            <Button @click="onEditEnd">Schliessen</Button>
         </div>
      </template>
      <template v-else>
         <ServiceSettings :config="config"
            :readonly="isReadonly"
            :service="service"
            @close="onShowSettings(false)"
            @billingRateUpdate="onBillingRateUpdate"
            @convert="$emit('convert', $event)" />
      </template>
   </div>
</template>

<script>
import TaskBilling from './TaskBilling.vue';
import TimeEntrys from '../components/TimeEntrys.vue';
import Dropdown from '@components/Form/Dropdown.vue';
import Input from '@components/Form/Input.vue';
import InputBasic from '@components/Form/InputBasic.vue';
import CheckBox from '@components/Form/CheckBox.vue';
import { minutes } from 'src/utils/utils'
import api from "@components/Portal/api.js"
import Icon from "@icons/Icon.vue"
import { Search, Bill, Undo, Settings } from "@icons/appFabric/icons"
import Button from '@components/Form/Button.vue'
import Status from '../components/Status.vue';
import AssignedTo from '../components/AssignedTo.vue';
import utils from '@SyoLab/utils'
import BillingSlideDown from '../components/BillingSlideDown.vue';
import { popupOpen, editServiceUpdate } from "../components/utils"
import { onTaskUpdate, getTaskDataObject, } from './task'
import { recordUpdate, hourlyRateUpdate } from '../components/timeEntrys'
import ServiceSettings from '../components/ServiceSettings.vue';

export default {
   name: 'TaskEdit',
   components: {
      TaskBilling,
      TimeEntrys,
      Dropdown,
      Input,
      InputBasic,
      Icon,
      Button,
      CheckBox,
      Status,
      AssignedTo,
      BillingSlideDown,
      ServiceSettings
   },
   props: {
      taskData: { required: true, type: Object },
      config: { required: true, type: Object },
      readonly: { required: false },
      selectable: { required: false, default: false },
   },
   data() {
      return {
         customerSearchString: null,
         projectSearchString: null,
         service: {},
         undoEnabled: false,
         showSettings: false
      }
   },
   static: {
      iconSearch: Search,
      iconBill: Bill,
      iconUndo: Undo,
      iconSettings: Settings
   },
   methods: {
      async onCustomerSearch(customerSearchString) {
         if (!customerSearchString) return []
         return await api.get(`customer/customerSearch/${customerSearchString}`)
      },
      onCustomerSelect(selectedItem) {
         if (this.service.customer_id != selectedItem._id) {
            this.service.project_id = null
            this.service.projectName = null
         }
         this.service.customer_id = selectedItem._id
         this.service.customerName = selectedItem.name
      },
      async onProjectSearch(projectSearchString) {
         if (!projectSearchString) return []
         return await api.get(`customer/customerSearch/${projectSearchString}`)
      },
      onProjectSelect(selectedItem) {
         this.service.project_id = selectedItem._id
         this.service.projectName = selectedItem.name
      },
      formatToDecimal(value) {
         return value ? minutes.toFormatDecimal(value) : null
      },
      onEstimatedMinutesChange(value) {
         this.service.estimatedMinutes = minutes.parse(value)
      },
      onSettingsUpdate() {
         onTaskUpdate(this.service, this.config)
      },
      update(reason) {
         onTaskUpdate(this.service, this.config, reason)
         this.undoEnabled = true
      },
      onEditEnd() {
         this.service.timeEntrys.sort((a, b) => {
            if (a.date < b.date) return -1
            if (a.date > b.date) return 1
            return 0
         })
         this.$emit('editEnd', this.service)
      },
      onPdfShow(service) {
         if (!this.config.billingPdfUrl) {
            console.error('pdfUrl is not set')
            return
         }
         popupOpen(window, `${this.config.billingPdfUrl}/${service.pdf}`, 'bill', { height: 1200, width: 1200 })
      },
      onServiceChange() {
         this.undoEnabled = true
      },
      undoChanges() {
         this.service = getTaskDataObject(this.taskData)
         this.undoEnabled = false
      },
      onShowSettings(show) {
         setTimeout(() => {
            this.showSettings = show
         }, 1)
      },
      onBillingRateUpdate() {
         this.service.timeEntrys.forEach(timeEntry => {
            hourlyRateUpdate(timeEntry, this.config, this.service.settings)
            recordUpdate(timeEntry)
         })

         this.update()
         this.onShowSettings(false)

      },
   },
   computed: {
      taskTotal() {
         let total = 0
         this.service.services.forEach(service => {
            total += service.value
         })
         return total ? total.toFixed(2) : null
      },
      customerDropdownValue() {
         if (this.service.customer_id) {
            return {
               _id: this.service.customer_id,
               name: this.service.customerName
            }
         }
         return null
      },
      projectDropdownValue() {
         if (this.service.project_id) {
            return {
               _id: this.service.project_id,
               name: this.service.projectName
            }
         }
         return null
      },
      showDeleteBtn() {
         return this.service.timeEntrys.length == 0
      },
      settings() {
         return this.service.settings
      },
      isReadonly() {
         if (this.readonly) return true
         if (this.service.billNo) return true
         if (this.service.status == 'billed') return true
         if (this.service.status == 'payed') return true
         return false
      },
      billShow() {
         if (this.service.status == 'billed') return true
         if (this.service.billNo) return true
         if (this.selectable) return true
         return false
      },
      billingShow() {
         return this.config.showBilling ? true : false

      },
      timeEntrysReadonly() {
         return this.isReadonly || this.service.status == 'done'
      }
   },
   created() {
      this.service = getTaskDataObject(this.taskData)
   },
   watch: {
      taskData: {
         handler() {
            editServiceUpdate(this.taskData, this.service)
         },
         deep: true
      }
   },
   mounted() {
      if (this.service?._isNew) {
         this.$refs.serviceName.select()
      }
   }
}
</script>

<style scoped>
.TaskEdit {
   display: flex;
   flex-direction: column;
   row-gap: 25px;
   padding-top: 30px;
   width: 1000px;
   min-height: 490px;
}

.btnBill {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 28px;
   border-radius: 3px;
   background-color: #e9e9e9;
   cursor: pointer;
}

.task {
   display: flex;
   flex-direction: column;
   row-gap: 15px;
}

.TaskBilling {
   border: 1px solid #d3d2d2;
   border-radius: 9px;
   padding: 15px;
}



.status {
   display: flex;
   align-items: center;
}

.header {
   display: flex;
   column-gap: 35px;

}

.header>.name {
   width: 550px;
   height: 40px;
   padding-left: 2px;
   background-color: #e9e9e9;
   display: flex;
   align-items: center;
   border-radius: 3px;
}

.header>.name .Input {
   width: 100%;
   height: 100%;
}

.assignedToRow {
   display: flex;
   column-gap: 35px;
   margin-top: 5px;
}

.assignedToRow .right {
   display: flex;
   column-gap: 25px;
}

.assignedTo {
   display: flex;
   flex-direction: column;
   row-gap: 2px;
}

.assignedToList {
   position: relative;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   column-gap: 20px;
   width: 550px;
   min-height: 36px;
   padding-bottom: 6px;
}

.assignedToList::after {
   content: ' ';
   position: absolute;
   bottom: 0px;
   left: 0;
   width: 100%;
   height: 1px;
   border-bottom: 1px solid #619cca;
}

.title {
   font-size: 22px;
   font-weight: 300;
}

.billing {
   display: flex;
   flex-direction: column;
   row-gap: 15px;

}

.billing .settings {
   display: flex;
   flex-wrap: wrap;
   column-gap: 25px;
   padding-bottom: 5px;
}

.timeEntrys {
   display: flex;
   flex-direction: column;
   row-gap: 15px;
}

.Dropdown.customerSearch,
.Dropdown.projectSearch {
   width: 300px;
}

.task>.row {
   column-gap: 20px;
}

.Dropdown.status {
   width: 115px;
}

.Input.customer {
   width: 150px;
}


.Input.estimatedMinutes,
.Input.totalMinutes {
   width: 85px;
}

.action {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
}

.undo {
   display: flex;
   column-gap: 5px;
}

.btnSettings {
   display: flex;
   align-items: center;
   padding: 10px;
   cursor: pointer;
   font-size: 20px;
   background-color: #e9e9e9;
}

.btnSettings:hover {
   background-color: var(--activeHover)
}
</style>./task