<template>
	<div class="appContainer">Default Page</div>
</template>

<script>
export default {
	name: "DefaultApp",
	props: {
		appConfig: Object,
	},
};
</script>

<style>
</style>