import { defineStore } from "pinia";
import Vue from 'vue'
//import { watch, toRefs } from '@vue/composition-api'
import i18n from "src/i18n"
import api from "@components/Portal/api"


function browserLocale() {
   return (
      navigator.userLanguage ||
      (navigator.languages && navigator.languages.length && navigator.languages[0]) ||
      navigator.language ||
      navigator.browserLanguage ||
      navigator.systemLanguage ||
      "en"
   );
}

const portalStateP = defineStore({
   id: "portalState",
   state() {
      return {
         // user
         userConfig: {
            userId: null,
            firstName: null,
            lastName: null,
            stayLoggedIn: null,
            ttl: null,
            defaultApp: null,
            locale: browserLocale(),
            manager: null,
            persNo: null,
            orgUnit: null
         },
         userApps: {},
         //currentApp
         currentApp: {
            name: undefined,
            component: null,
            auth: null,
            mounted: false,
            delay: false,
            timeout: false,
            error: false,
         },
         // proxy
         proxyProvider: null,
         // portal:
         loader: false,
         authenticated: null,
         stayLoggedIn: false,
         ttl: null,
         locales: [], // list of available locales
         locale: null, // active locale == this.i18n.locale
         menu: {
            expanded: true
         },
         mounted: false, // trigger cloak
         ready: false, // application mounted, but not ready
         // progressBar
         progressStack: [],
      }
   },
   getters: {
      userApp() {
         if (!this.currentApp) return null
         return this.userApps[this.currentApp.name]
      },
      user() {
         return {
            userId: this.userConfig.userId,
            userName: this.userConfig.firstName + ' ' + this.userConfig.lastName,
         }
      }
   },
   actions: {
      async setLocale(locale, saveToUserConfig) {

         locale = locale || browserLocale()
         locale = locale.split("-")[0];

         if (this.locale != locale) {
            this.locale = locale;
            i18n.locale = locale
         }

         if (saveToUserConfig && this.authenticated && this.userConfig.locale != locale) {
            this.userConfig.locale = locale
            api.put("user/lang/" + locale);
         }

      },
      onLoginSuccess(response) {
         this.stayLoggedIn = response.clientConfig.stayLoggedIn || false
         this.ttl = response.clientConfig.ttl || 120
         this.userApps = response.userApps
         this.userConfig = response.userConfig;
         if (this.userConfig.locale) {
            this.setLocale(this.userConfig.locale, false)
         }
         this.ready = true;
         this.authenticated = true;
         this.proxyProvider = response.proxyProvider || null;


      },
      logOff() {
         console.log('logoff')
         //wipe userConfig
         Object.keys(this.userConfig).forEach((key) => {
            this.userConfig[key] = undefined;
         });
         //wipe userApps
         this.userApps = {};
         this.authenticated = false;
         api.get('auth/logoff')
      },
      stayLoggedInChange(value) {
         this.stayLoggedIn = value;
         api.put(`user/stayLoggedIn/` + value.toString());
      },
      progressStart(id) {
         this.progressStack.push(id);
      },
      progressStop(id) {
         if (id) {
            //remove this one id
            let index = this.progressStack.indexOf(id);
            if (index > -1) {
               this.progressStack.splice(index, 1);
            }
         } else {
            //remove all
            Vue.$set(this.progressStack, []);
         }
      },


   }
});
export default portalStateP

// const portalState = portalStateP($pinia)
// watch(portalState.$state, (value) => {
//    console.log('portalstate change', value)
// })

// let { test } = toRefs(portalState.$state)
// watch(test, (value) => {
//    console.log('portalstate 2 change', value)
// })
