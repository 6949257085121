<template>
   <div class="ContactsStatisics"
      @click="onAppClick">
      <div class="statistics">
         <div class="wrapper"
            @click.stop>
            <div class="action">
               <div class="prev active"
                  @click="onPrev">
                  <Icon icon="iconPrev" />
               </div>
               <div class="next"
                  :class="{ active: nextIsActive }"
                  @click="onNext">
                  <Icon icon="iconNext" />
               </div>
            </div>
            <div class="grid"
               :style="gridStyle">
               <div class="header">
                  <div class="employee">
                     <div class="month">{{ activeMonth }}</div>
                  </div>
                  <div v-for="item in gridColumns"
                     :class="itemClass(item)"
                     :style="itemStyle(item)"
                     :key="item.id"
                     @click="onColumnClick(item)">
                     <span>{{ item.header1 }}</span>
                     <span class="dateFormatted">{{ item.header2 }}</span>
                  </div>
                  <div class="total">Total</div>
               </div>
               <div v-for="record in records"
                  class="gridRow"
                  :key="record.userId">
                  <div class="userName">{{ record.userName }}</div>
                  <div v-for="item in gridColumns"
                     :class="itemClass(item)"
                     :style="itemStyle(item)"
                     :key="item.id"
                     @click="onColumnClick(item, record)">{{ record[item?.date] }}</div>
                  <div class="total">{{ record.total }}</div>
               </div>
            </div>
         </div>
      </div>

      <div v-if="showEmployeeContacts"
         class="employeeContacts">
         <div class="wrapper"
            @click.stop>
            <div class="name">{{ employeeUserName }}</div>
            <Grid :gridColumns="employeeGridColumns"
               :data="employeeGridRecords"
               :showActions="false"
               @recordClick="onEmployeeRecordClick" />
         </div>

      </div>
   </div>
</template>

<script>
import { Day } from '@SyoLab/date-time'
import api from '@components/Portal/api'
import Icon from "@icons/Icon.vue"
import { ChevronLeft, ChevronRight } from "@icons/appFabric/icons"
import Grid from '../dataGrid/Grid.vue'
export default {
   name: 'ContactsStatisics',
   components: { Icon, Grid },
   props: {},
   data() {
      let startDate = new Day().startOf('month').date
      let endDate = new Day().endOf('month').date
      return {
         endDate,
         startDate,
         records: [],
         employeeGridRecords: [],
         employeeUserName: null,
      }
   },
   static: {
      iconPrev: ChevronLeft,
      iconNext: ChevronRight,
   },
   methods: {
      async getData() {

         let records = await api.post('contacts/customerCommunication/statistics', {
            startDate: this.startDate,
            endDate: this.endDate,
         })

         this.records = records
         this.employeeGridRecords = []
      },
      async getEmployeeData(params) {
         let records = await api.post('contacts/customerCommunication', {
            userId: params.userId,
            date: params.date,
         })
         this.employeeGridRecords = records
         this.employeeUserName = params.userName
      },
      itemStyle(item) {
         if (!item) return {}
         return {
            gridColumn: item.id,
         }
      },
      itemClass(item) {
         if (!item) return {}
         return {
            day: true,
            weekEnd: item.weekDay == 6 || item.weekDay == 7,
            kw: item.header1 == 'KW',
         }
      },
      onPrev() {
         this.endDate = new Day(this.endDate).minus({ months: 1 }).endOf('month').date
         this.startDate = new Day(this.endDate).startOf('month').date

         this.getData()
      },
      onNext() {
         if (!this.nextIsActive) return
         this.endDate = new Day(this.endDate).plus({ months: 1 }).endOf('month').date
         this.startDate = new Day(this.endDate).startOf('month').date

         this.getData()
      },
      onColumnClick(item, record) {
         let data = {
            date: item.date,
            count: record[item.date],
            userId: record.userId,
            userName: record.userName,

         }
         this.getEmployeeData(data)

      },
      onAppClick() {
         this.employeeUserName = null
      },
      onEmployeeRecordClick(record) {
         this.$router.push(`/customer/${record.customer_id}`)
      },
   },
   computed: {
      gridColumns() {
         let columns = []
         let d = new Day(this.startDate)
         while (d.date <= this.endDate) {
            columns.push({
               id: `d${d.date}`,
               date: d.date,
               weekDay: d.weekDay,
               header1: d.toFormat('ccc'),
               header2: this.mode == 'month' ? d.toFormat('dd') : d.toFormat('dd.MM'),
            })

            if (d.weekDay == 7) {
               columns.push({
                  id: `w${d.week}`,
                  date: d.week,
                  header1: 'KW',
                  header2: d.weekNo,
               })
            }
            d = d.plus({ days: 1 })
         }

         return columns
      },
      gridStyle() {
         let width = '40px'
         let gridTemplateColumns = '[userName] max-content '
         gridTemplateColumns += this.gridColumns.map(item => `[${item.id}] ${width}`).join(' ')
         gridTemplateColumns += ' [total] max-content'
         return {
            gridTemplateColumns
         }
      },
      nextIsActive() {
         return this.endDate < new Day().endOf('month').date
      },
      activeMonth() {
         return new Day(this.startDate).toFormat('MMMM yyyy')
      },
      employeeGridColumns() {
         return [
            { name: 'customerName', grid: 'name', text: "Kunde", width: 'max-content', emit: 'recordClick' },
            { name: 'contactName', grid: 'contactName', text: "Kontakt", width: 'max-content', emit: 'recordClick' },
            {
               name: 'nextContactDate', grid: 'nextContactDate', text: "Nächster Kontakt", width: 'max-content', emit: 'recordClick', value(record, column) {
                  return record.nextContactDate ? new Day(record.nextContactDate).toEuDate() : null
               }
            },

            { name: 'userName', grid: 'userName', text: "Mitarbeiter", width: 'max-content', emit: 'recordClick' },
            {
               name: 'date', grid: 'date', text: "Datum", width: 'max-content', emit: 'recordClick', value(record, column) {
                  return record.date ? new Day(record.date).toEuDate() : null
               }
            },
            { name: 'comment', grid: 'comment', text: "Kommentar", width: '600px', emit: 'recordClick', style: { "white-space": "unset" } },
         ]
      },
      showEmployeeContacts() {
         return this.employeeUserName ? true : false
      },
   },

   created() {
      let config = sessionStorage.getItem("ContactsStatistics")
      if (config) {
         config = JSON.parse(config)
         this.startDate = config.startDate
         this.endDate = config.endDate
      }
      this.getData()
   },
   beforeDestroy() {
      sessionStorage.setItem("ContactsStatistics", JSON.stringify({
         startDate: this.startDate,
         endDate: this.endDate,
      }))
   },
}
</script>

<style scoped>
.ContactsStatisics {
   flex-direction: column;
   display: flex;
   flex-grow: 1;
}

.statistics {
   display: inline-flex;
}

.wrapper {
   display: inline-flex;
   flex-direction: column;
}

.action {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   column-gap: 25px;
   font-size: 14px;
   padding-bottom: 20px;
   padding-right: 20px;
   position: relative;
}

.action>div {
   color: #939292;
}

.action>div.active {
   cursor: pointer;
   color: black;
}

.month {
   height: 100%;
   display: flex;
   align-items: center;
   padding-top: 3px;
   font-size: 16px;
   color: rgb(1, 1, 1, 0.7);
}


.grid {
   display: grid;
}

.grid .day {
   display: flex;
   flex-direction: column;
   align-items: center;
   cursor: pointer;
}

.grid .day.weekEnd {
   background-color: whitesmoke;
}

.grid .day.kw {
   font-size: 12px;
   font-weight: 500;
   color: #2727c9;
   display: flex;
   justify-content: center;
   background-color: whitesmoke;
}

.grid .total {
   color: #2727c9;
   /* background-color: #edf4fb; */
   display: flex;
   justify-content: flex-end;
   align-items: center;
   padding: 0 5px;
}

.grid .header .total {
   padding-top: 3px;
   display: flex;
   align-items: center;
}

.header .day {
   font-size: 12px;
   font-weight: 500;
   color: rgba(0, 0, 0, 0.7);
   padding-top: 3px;
}

.header>div {
   padding-bottom: 10px;
}

.grid .header {
   display: contents;
}

.grid .gridRow {
   display: contents;
}

.grid .gridRow:hover>div {
   background-color: whitesmoke;
}

.grid .userName {
   grid-column: userName;
   padding-right: 10px;
   min-width: 95px
}

.employeeContacts {
   display: inline-flex;
   padding-top: 35px;

   .name {
      font-weight: 500;
      padding-left: 8px;
   }
}
</style>