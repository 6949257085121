<template>
	<div v-if="menuShow"
		id="PortalMenu"
		class="Menu"
		:class="menuClass">
		<div ref="toggle"
			class="menuToggle">
			<div class="menuItem"
				@click="onMenuToggleClick">
				<div class="grow"></div>
				<div class="iconSlot">
					<Icon :icon="iconExpand" />
				</div>
			</div>
		</div>
		<vueScrollbar class="scroll">
			<div class="items"
				:class="itemsClass"
				@mouseenter="onMouseEnter"
				@mouseleave="onMouseLeave">
				<div v-for="(item, index) in appMenus"
					class="item"
					:key="index"
					:class="{ currentApp: isActive(item) }">
					<MenuItem v-for="(menu, i) in item.menus"
						:key="i"
						:menuItem="menu"
						:level="0"
						@toggleMenuChildren="onToggleMenuChildren"
						@click="onMenuItemClick" />
				</div>
			</div>
		</vueScrollbar>
	</div>
</template>

<script>
import { ChevronRight } from '@icons/appFabric/icons'
import MenuItem from './MenuItem.vue'
import Icon from '@icons/Icon.vue'
import { delay, delayClear } from './utils'
import eventBus from './eventBus'
import vueScrollbar from '@components/Tools/vue-scrollbar.vue'

import { mapActions, mapState, mapWritableState } from 'pinia'
import portalStateP from './portalState'
import clientSettingsP from '@components/Portal/clientSettings.js'
const clientSettings = clientSettingsP()

export default {
	name: 'Menu',
	components: {
		MenuItem,
		Icon,
		vueScrollbar,
	},
	props: {
		appMenus: {
			type: Array,
			required: false,
		},
	},
	data() {
		return {
			opening: false,
			closing: false,
			iconExpand: ChevronRight,
			mouseIsOver: false,
			expanded: false,
			fixed: false,
		}
	},
	methods: {
		onMouseEnter(e) {
			this.mouseIsOver = true
			setTimeout(() => {
				if (this.mouseIsOver) {
					this.expanded = true
				} else {
					this.expanded = false
				}
			}, 700)
		},
		onMouseLeave() {
			this.mouseIsOver = false
			setTimeout(() => {
				if (!this.mouseIsOver) {
					this.expanded = false
				}
			}, 500)
		},
		onMenuItemClick(e) {
			this.expanded = false
		},
		onMenuToggleClick() {
			this.clientSettingsMenu.expanded = !this.clientSettingsMenu.expanded
			clientSettings.set('menuExpanded', this.clientSettingsMenu.expanded)
			if (!this.clientSettingsMenu.expanded) this.expanded = false
		},
		onToggleMenuChildren: function (menuItem) {
			//get children.show
			if (!menuItem.items || menuItem.items.length == 0) return
			let show = menuItem.items[0].show
			//toggle all children
			this.toggleMenuChildren(menuItem, !show)
		},
		//get reference to menuItem descripbed in path
		getMenuItemRef: function (path) {
			path = path.split('.')
			//
			let elRef = this.menuData
			for (let i = 0; i < path.length; i++) {
				if (i == 0) {
					let menuDataIndex = elRef.findIndex(item => {
						return item._id == path[i]
					})
					elRef = elRef[menuDataIndex]
				} else {
					let menuDataIndex = elRef.items.findIndex(item => {
						return item._id == path[i]
					})
					elRef = elRef.items[menuDataIndex]
				}
			}
			return elRef
		},
		//set all menuItem.items[].show to value recursive
		toggleMenuChildren: function (menuItem, value) {
			if (!menuItem.items) return
			menuItem.items.forEach((item, index) => {
				menuItem.items[index].show = value
				//if setting false => set all children recursive
				if (menuItem.items[index].items && value == false) {
					this.toggleMenuChildren(menuItem.items[index], value)
				}
			})
		},
		transitionEnd: function (event) {
			if (event.target == this.$el) {
				this.opening = false
				this.closing = false
			}
		},
		menuItemClass: function (menudata) {
			if (menudata.active) return ' active ' + menudata.class
			return ' ' + menudata.class
		},
		isActive(menuItem) {
			// route is from app.routes and not from menuItem
			if (this.$route.meta.isAppRoute) {
				return this.$route.meta.appName == menuItem.appName
			}
			return false
		},
	},
	computed: {
		...mapWritableState(portalStateP, { clientSettingsMenu: 'menu' }),
		menuClass: function () {
			return {
				expanded: this.clientSettingsMenu.expanded,
				collapsed: !this.clientSettingsMenu.expanded,
				fixed: this.clientSettingsMenu.expanded,
			}
		},
		itemsClass() {
			return {
				expanded: this.clientSettingsMenu.expanded || this.expanded,
			}
		},
		menuShow: function () {
			return this.appMenus.length > 0 ? true : false
		},
	},
	mounted: function (params) {
		this.$el.addEventListener('transitionend', this.transitionEnd)
	},
	updated() {
		this.$el.removeEventListener('transitionend', this.transitionEnd)
		this.$el.addEventListener('transitionend', this.transitionEnd)
	},
	beforeDestroy() {
		this.$el.removeEventListener('transitionend', this.transitionEnd)
	},

	watch: {
		menuState: {
			deep: true,
			handler(to) {
				//collapse iv menu is retracted
				if (!to.expanded) {
					this.appMenus.forEach(appMenu => {
						appMenu.menus.forEach(menu => {
							this.toggleMenuChildren(menu, false)
						})
					})
				}
			},
		},
	},
}
</script>

<style scoped>
.Menu {
	width: 50px;
	height: 100%;
	min-height: 100%;
	position: relative;
	transition: width 0.5s;
	transition-timing-function: ease;
	background-color: var(--menuBg);
}

.Menu .scroll {
	height: calc(100% - 50px);
}

.Menu.expanded {
	width: 200px;
}

.items {
	min-height: 100%;
	width: 50px;
	background-color: #baccdd;
	z-index: 5;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	transition: width 0.5s;
	transition-timing-function: ease;
}

.Menu.expanded .items,
.items.expanded {
	width: 200px;
}

.Menu.fixed .menuToggle .icon {
	transform: rotate(-180deg);
}

.menuToggle {
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.menuItem {
	display: flex;
	justify-content: flex-start;
	height: 50px;
	width: 100%;
	position: relative;
	cursor: pointer;
}

.menuItem .grow {
	flex-grow: 0;
	transition: all 0.5s;
}

.Menu.fixed .grow {
	flex-grow: 1;
}

.menuItem .iconSlot {
	height: 100%;
	width: 50px;
	position: relative;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.menuItem .icon {
	transition: transform 0.5s;
	transition-timing-function: ease;
}

.item {
	margin: 0;
}

.item.currentApp {
	background-color: var(--menuBgActive);
}

.item.currentApp:deep(.level1) .menuItem:hover {
	background-color: var(--menuBgActive);
}

/* menuItems */
.menuItem:hover {
	background-color: var(--menuBgActive);
}

/* .menuItemsGroup.active:after {
	content: '';
	background-color: var(--accentBlue);
} */
</style>
