<template>
	<div v-if="menuShow"
		class="MenuMobile"
		:class="menuClass">
		<div class="head">
			<div class="waffle"
				@click="drawerShow = !drawerShow">
				<Icon icon="iconWaffle" />
			</div>
		</div>
		<transition name="slideInOut">
			<div v-if="drawerShow"
				class="drawer">
				<div class="menuClose">
					<div class="btn"
						@click="drawerShow = false"
						@touchstart="touchStart">
						<Icon icon="iconClose" />
					</div>
				</div>
				<div class="scroll light">
					<div class="items"
						:class="itemsClass">
						<div v-for="(item, index) in menuItems"
							class="item"
							:key="index"
							:class="{ currentApp: isActive(item) }">
							<MenuItemMobile v-for="(menu, i) in item.menus"
								:key="i"
								:menuItem="menu"
								:level="0"
								@toggleMenuChildren="onToggleMenuChildren"
								@click="onMenuItemClick" />
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { ChevronRight, WaffleOffice365, ChromeClose } from '@icons/appFabric/icons'
import MenuItemMobile from './MenuItemMobile.vue'
import Icon from '@icons/Icon.vue'
import { delay, delayClear } from './utils'
import eventBus from './eventBus'

import { mapActions, mapState, mapWritableState } from 'pinia'
import portalStateP from './portalState'
import clientSettingsP from '@components/Portal/clientSettings.js'
const clientSettings = clientSettingsP()

export default {
	name: 'Menu',
	components: {
		MenuItemMobile,
		Icon,
	},
	props: {
		appMenus: {
			type: Array,
			required: false,
		},
	},
	data() {
		return {
			opening: false,
			closing: false,
			iconExpand: ChevronRight,
			mouseIsOver: false,
			expanded: false,
			fixed: false,
			drawerShow: false,
		}
	},
	static: {
		iconWaffle: WaffleOffice365,
		iconClose: ChromeClose,
	},
	methods: {
		touchStart(e) {
			console.log('touchStart', e)
		},
		onMenuItemClick(e) {
			console.log('onMenuItemClick', e)
			this.drawerShow = false
		},
		onMenuToggleClick() {
			this.clientSettingsMenu.expanded = !this.clientSettingsMenu.expanded
			clientSettings.set('menuExpanded', this.clientSettingsMenu.expanded)
			if (!this.clientSettingsMenu.expanded) this.expanded = false
		},
		onToggleMenuChildren: function (menuItem) {
			//get children.show
			let show = menuItem.items[0].show
			//toggle all children
			this.toggleMenuChildren(menuItem, !show)
		},
		//get reference to menuItem descripbed in path
		getMenuItemRef: function (path) {
			path = path.split('.')
			//
			let elRef = this.menuData
			for (let i = 0; i < path.length; i++) {
				if (i == 0) {
					let menuDataIndex = elRef.findIndex(item => {
						return item._id == path[i]
					})
					elRef = elRef[menuDataIndex]
				} else {
					let menuDataIndex = elRef.items.findIndex(item => {
						return item._id == path[i]
					})
					elRef = elRef.items[menuDataIndex]
				}
			}
			return elRef
		},
		//set all menuItem.items[].show to value recursive
		toggleMenuChildren: function (menuItem, value) {
			if (!menuItem.items) return
			menuItem.items.forEach((item, index) => {
				menuItem.items[index].show = value
				//if setting false => set all children recursive
				if (menuItem.items[index].items && value == false) {
					this.toggleMenuChildren(menuItem.items[index], value)
				}
			})
		},
		transitionEnd: function (event) {
			if (event.target == this.$el) {
				this.opening = false
				this.closing = false
			}
		},
		menuItemClass: function (menudata) {
			if (menudata.active) return ' active ' + menudata.class
			return ' ' + menudata.class
		},
		isActive(menuItem) {
			// route is from app.routes and not from menuItem
			if (this.$route.meta.isAppRoute) {
				return this.$route.meta.appName == menuItem.appName
			}
			return false
		},
	},
	computed: {
		...mapWritableState(portalStateP, { clientSettingsMenu: 'menu' }),
		menuItems: function () {
			return this.appMenus
			// return this.menuData.filter((menuItem) => {
			// 	console.log(menuItem);
			// 	//if appName is set on menuItem -> check appSettings[appName] if it should be displayed
			// 	// if (menuItem.appName) {
			// 	// 	return this.appSettings[menuItem.appName] ? true : false;
			// 	// }
			// 	//default is to return menuItem
			// 	return true;
			// });
		},
		menuClass: function () {
			return {
				expanded: this.clientSettingsMenu.expanded,
				collapsed: !this.clientSettingsMenu.expanded,
				fixed: this.clientSettingsMenu.expanded,
			}
		},
		itemsClass() {
			return {
				expanded: this.clientSettingsMenu.expanded || this.expanded,
			}
		},
		menuShow: function () {
			return this.appMenus.length > 0 ? true : false
		},
	},
	mounted: function (params) {
		this.$el.addEventListener('transitionend', this.transitionEnd)
	},
	updated() {
		this.$el.removeEventListener('transitionend', this.transitionEnd)
		this.$el.addEventListener('transitionend', this.transitionEnd)
	},
	beforeDestroy() {
		this.$el.removeEventListener('transitionend', this.transitionEnd)
	},

	watch: {
		menuState: {
			deep: true,
			handler(to) {
				//collapse iv menu is retracted
				if (!to.expanded) {
					this.appMenus.forEach(appMenu => {
						appMenu.menus.forEach(menu => {
							this.toggleMenuChildren(menu, false)
						})
					})
				}
			},
		},
	},
}
</script>

<style scoped>
.MenuMobile {
	position: absolute;
	transition: width 0.5s;
	transition-timing-function: ease;
	z-index: 50;
}

.head {}

.head .waffle {
	height: var(--appToolbar-height);
	width: var(--appToolbar-height);
	font-size: 18px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.drawer {
	position: absolute;
	top: var(--appToolbar-height);
	width: 300px;
	height: calc(100vh - var(--appToolbar-height));
	background-color: var(--menuBg);
	border-right: 1px solid grey;
}

.scroll {
	height: calc(100% - 50px);
	overflow-y: scroll;
}

.items {
	min-height: 100%;
	width: 100%;
	z-index: 5;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	transition: width 0.5s;
	transition-timing-function: ease;
}

.menuItem {
	display: flex;
	justify-content: flex-start;
	height: 50px;
	width: 100%;
	position: relative;
	cursor: pointer;
}

.menuItem .iconSlot {
	height: 100%;
	width: 50px;
	position: relative;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.menuItem .icon {
	transition: transform 0.5s;
	transition-timing-function: ease;
}

.item {
	margin: 0;
}

.item.currentApp {
	background-color: var(--menuBgActive);
}

.item.currentApp:deep(.level1) .menuItem:hover {
	background-color: var(--menuBgActive);
}

/* menuItems */
.menuItem:hover {
	background-color: var(--menuBgActive);
}

.menuClose {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: #323130;
}

.menuClose .btn {
	height: 50px;
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.slideInOut-enter-active,
.slideInOut-leave-active {
	transition: left 0.5s ease !important;
}

.slideInOut-enter-to,
.slideInOut-leave {
	left: 0;
}

.slideInOut-enter,
.slideInOut-leave-to {
	left: -300px;
}</style>
