

import { minutes } from 'src/utils/utils'
import utils from "@SyoLab/utils"
import { DateTime, Day } from '@SyoLab/date-time'

export function durationUpdate(record, fromDuration = false) {

   if (fromDuration && record.duration) {
      record.minutes = minutes.parse(record.duration)
      record.minutes = Math.ceil(record.minutes / 15) * 15
      record.duration = minutes.toFormatHH_MM(record.minutes)
   } else if (record.from && record.to) {
      record.minutes = minutes.parse(record.to) - minutes.parse(record.from)
      if (record.minutes > 0) {
         record.minutes = Math.ceil(record.minutes / 5) * 5
      } else {
         record.minutes = null
      }
      record.minutes = Math.ceil(record.minutes / 5) * 5
   } else {
      record.minutes = null
   }
}

export function recordUpdate(record) {
   if (!record.employee_id || !record.minutes || !record.hourlyRate || !record.workType) {
      record.value = null
      record.error = true
      return
   }
   record.value = record.minutes / 60 * record.hourlyRate
   record.error = false
}

export function getNewTimeEntry(service, config) {
   let employee = config.employees.find(employee => employee._id === config.employee_id)
   let workType = service.defaultWorkType
   let hourlyRate = config.defaultHourlyRate

   if (employee) {

      // workType from employee has priority
      if (employee.workType) workType = employee.workType
      // workType previously set on same employee has priority
      let timeEntrys = service.timeEntrys.filter(te => te.employee_id == employee._id && te.workType)
      if (timeEntrys.length > 0) {
         let timeEntryWorkTypeCount = timeEntrys.reduce((acc, te) => {
            if (!te.workType) return acc
            if (!acc[te.workType]) acc[te.workType] = 0
            acc[te.workType] += 1
            return acc
         }, {})

         let workTypeMostUsed = Object.entries(timeEntryWorkTypeCount).sort((a, b) => b[1] - a[1])[0]
         workType = workTypeMostUsed[0]
      }
      hourlyRate = getHourlyRate(workType, config, service.settings)
   }

   return {
      _id: utils.shortId(),
      date: new Day().date,
      notBillable: null,
      text: null,
      from: null,
      to: null,
      duration: null,
      minutes: null,
      employee_id: employee?._id || null,
      employee: employee?.fullName || null,
      workType: workType,
      hourlyRate,
      value: null,
      error: true
   }
}

export function hourlyRateUpdate(record, config, settings) {
   record.hourlyRate = getHourlyRate(record.workType, config, settings)
}

function getHourlyRate(workType, config, settings) {
   // set default hourly rate
   let hourlyRate = config.defaultHourlyRate
   // assign hourly rate from workType
   let wt = config.workTypes.find(w => w.id === workType)
   if (wt?.hourlyRate) hourlyRate = wt.hourlyRate
   // assign hourly rate from settings
   if (settings?.[workType]) hourlyRate = settings[workType]
   return hourlyRate
}