<template>
   <div class="ServiceSettings">
      <div class="buttonRow">
         <div class="btn">
            <Icon icon="iconBack"
               @click="$emit('close')" />
         </div>
      </div>
      <div class="body">
         <div class="projectSettings">
            <div class="lastUpdateRow">
               <div class="column">
                  <div class="lastUpdate">
                     Letzte Aktualisierung: {{ lastUpdate }}
                  </div>
                  <div class="lastUpdate">
                     User: {{ service.userId }}
                  </div>
               </div>
               <div class="column">
                  <div class="lastUpdate">
                     Erstellt: {{ createdTimeStamp }}
                  </div>
                  <div class="lastUpdate">
                     User: {{ service.createdBy }}
                  </div>
               </div>
               <div v-if="showConvert"
                  class="column">
                  <div v-if="this.service.type == 'task'"
                     class="btn text"
                     @click="$emit('convert', 'fixedPriceTask')">in Festpreis-Task konvertieren</div>
                  <div v-if="this.service.type == 'fixedPriceTask'"
                     class="btn text"
                     @click="$emit('convert', 'task')">in Task konvertieren</div>
               </div>
            </div>
            <div class="customerRow">
               <Dropdown class="customer"
                  label="Kunde"
                  :value="service.customer_id"
                  :readonly="true"
                  :options="customerDropdownOptions" />
               <Dropdown class="project"
                  :readonly="readonly"
                  label="Projekt"
                  :value="selectedProjectItem"
                  :options="projectDropdownOptions"
                  :autocomplete="onProjectSearch"
                  @input="onProjectSelect"
                  @autocomplete="searchString = $event" />
            </div>
            <div v-if="showBillingRates"
               class="billingRates">
               <div class="grid">
                  <div v-for="(workType, index) in workTypes"
                     :key="index"
                     class="workType">
                     <div class="name">{{ workType.name }}</div>
                     <div class="value">
                        <Input class="slim noNotification basic"
                           mask="number"
                           :placeholder="getPlaceholder(workType)"
                           :value="getValue(serviceSettings, workType.id)"
                           @input="onHourlyRateChange(workType.id, $event)" />
                     </div>
                  </div>
               </div>
               <div class="update">
                  <div class="btn text"
                     @click="onSettingsUpdate">Update</div>
                  <div class="btn text"
                     :class="{ disabled: enableUpdate === false }"
                     @click="onBillingRateUpdate">Neuberechnung</div>
               </div>
            </div>
            <div v-if="Object.keys($scopedSlots).length"
               class="settings">
               <slot></slot>
            </div>
         </div>

      </div>
   </div>
</template>

<script>
import Input from '@components/Form/Input.vue'
import Icon from "@icons/Icon.vue"
import { Search, Group, Add, Settings, Back } from "@icons/appFabric/icons"
import Dropdown from '@components/Form/Dropdown.vue'
import api from "@components/Portal/api.js"
import { DateTime } from '@SyoLab/date-time'


export default {
   name: 'ServiceSettings',
   components: { Input, Icon, Dropdown },
   props: {
      config: { required: true },
      service: { required: true },
      readonly: { required: true, default: false },
   },
   data() {
      return {
         searchString: null,
         enableUpdate: false
      }
   },
   static: {
      iconBack: Back,
   },
   methods: {
      getPlaceholder(workType) {
         return String(workType.hourlyRate)
      },
      getValue(serviceSettings, id) {
         return serviceSettings?.[id] || null
      },
      onHourlyRateChange(id, value) {
         // cast value
         if (value) {
            value = Number(value)
            if (isNaN(value) || value <= 0) {
               value = null
            }
         }
         // set or delete value
         if (value) {
            this.$set(this.service.settings, id, value)
         } else {
            this.$delete(this.service.settings, id)
         }
         this.enableUpdate = true
      },
      async onProjectSearch(searchString) {
         return await api.get(`${this.config.apiUrl}/projectSearch/${this.service.customer_id}/${searchString}`)
      },
      onProjectSelect(selectedItem) {
         if (!selectedItem) {
            this.service.project_id = null
            this.service.projectName = null
         }
         else {
            this.service.project_id = selectedItem.project_id
            this.service.projectName = selectedItem.projectName
         }

      },
      onBillingRateUpdate(e) {
         // ctrlKey to force update
         if (!this.enableUpdate && !e.ctrlKey) return
         setTimeout(() => {
            this.$emit('billingRateUpdate')
         }, 1)
      },
      async onSettingsUpdate() {

         let customer_id = this.service.customer_id;
         let project_id = this.service.type == 'project' ? null : this.service.project_id

         let settings = await api.post(`${this.config.apiUrl}/projectSettings`, {
            customer_id,
            project_id,
            type: 'project'
         })

         // update settings
         this.config.workTypes.forEach(workType => {
            // delete if set, but not in settings
            if (this.service.settings[workType.id] && !settings[workType.id]) {
               delete this.service.settings[workType.id]
            } else if (settings[workType.id]) {
               // add to settings
               this.service.settings[workType.id] = settings[workType.id]
            }
         })
         this.enableUpdate = true

      }
   },
   computed: {
      workTypes() {
         return this.config.workTypes
      },
      serviceSettings() {
         return { ...this.service.settings }
      },
      customerDropdownOptions() {
         return {
            listItems: [{ customer_id: this.service.customer_id, customerName: this.service.customerName }],
            autocomplete: true,
            listItemKey: 'customer_id',
            listItemValueKey: 'customerName',
         }
      },
      projectDropdownOptions() {
         return {
            listItems: this.selectedProjectItem ? [this.selectedProjectItem] : [],
            autocomplete: true,
            clearable: true,
            returnListItem: true,
            listItemKey: 'project_id',
            listItemValueKey: 'projectName',
         }
      },
      selectedProjectItem() {
         if (!this.service.project_id) return null
         return {
            project_id: this.service.project_id,
            projectName: this.service.projectName
         }
      },
      lastUpdate() {
         if (!this.service.timeStamp) return null
         return DateTime.fromISO(this.service.timeStamp, { zone: 'utc' }).setZone('Europe/Berlin').toFormat('dd.MM.yyyy HH:mm')
      },
      createdTimeStamp() {
         if (!this.service.createdTimeStamp) return null
         return DateTime.fromISO(this.service.createdTimeStamp, { zone: 'utc' }).setZone('Europe/Berlin').toFormat('dd.MM.yyyy HH:mm')
      },
      showBillingRates() {
         if (!this.config?.showBilling) return false
         if (this.service.type == 'project') return true
         if (this.service.type == 'task') return true
         return false
      },
      showConvert() {
         return this.config?.showBilling ? true : false
      }
   },
}
</script>

<style scoped>
.ServiceSettings {}

.body {
   display: flex;
   justify-content: center;
   column-gap: 55px;
}

.grid {
   display: grid;
   grid-template-columns: [name] max-content [value] max-content;
   grid-gap: 10px;
}

.workType {
   display: contents;
}

.grid .name {
   display: flex;
   align-items: center;
   grid-column: name;
}

.grid .value {
   display: flex;
   align-items: center;
   grid-column: value;
}

.buttonRow {
   padding: 15px;
   justify-content: center;
   display: flex;
   font-size: 20px;
}

.billingRates {
   display: flex;
   justify-content: center;
   column-gap: 35px;
}

.billingRates .update {
   display: flex;
   flex-direction: column;
   justify-content: center;
   row-gap: 10px;
}

.settings {
   display: flex;
   justify-content: center;
}

.projectSettings {
   display: flex;
   flex-direction: column;
   row-gap: 15px;
}

.customerRow {
   display: flex;
   align-items: center;
   column-gap: 10px;
}


.Dropdown.customer {
   width: 440px;
}

.Dropdown.project {
   width: 440px;
}

.lastUpdateRow {
   display: flex;
   column-gap: 55px;
   font-size: 14px;
   font-weight: 500;
   color: #474646;
}

.lastUpdateRow .column {
   display: flex;
   flex-direction: column;
}
</style>