<template>
   <div class="BillingSlideDown">
      <div class="row">
         <div class="title">Rechnung</div>
         <div class="arrowDown">
            <div class="btn">
               <Icon :class="{ open: billingShow }"
                  icon="iconArrowDown"
                  @click="billingShow = !billingShow" />
            </div>
         </div>
      </div>
      <SlideDown :show="show == true || billingShow">
         <slot></slot>
      </SlideDown>
   </div>
</template>

<script>
import Icon from "@icons/Icon.vue"
import SlideDown from '@components/Containers/SlideDown.vue';
import { ChevronDown } from "@icons/appFabric/icons"
export default {
   name: 'BillingSlideDown',
   components: {
      Icon,
      SlideDown
   },
   props: {
      show: { required: false, default: false }
   },
   data() {
      return {
         billingShow: false
      }
   },
   static: {
      iconArrowDown: ChevronDown,
   },
   methods: {},
   computed: {
   },
}
</script>

<style scoped>
.BillingSlideDown {
   display: flex;
   flex-direction: column;
   width: 100%;
}

.title {
   font-size: 22px;
   font-weight: 300;
}

.arrowDown {
   display: flex;
   align-items: center;
   padding-left: 10px;
   color: rgb(77, 77, 77);
}

.btn {
   cursor: pointer;
   padding: 5px;
}

.SlideDown {
   padding-top: 5px;
}

.Icon {
   cursor: pointer;
   transition: transform 0.3s;
}

.Icon.open {
   transform: rotate(180deg);
}
</style>