<template>
	<div class="fullScreen">
		<div v-if="connected"
			id="authenticate">
			<div v-if="!showStayLoggedInDialog"
				class="login boxShadow">
				<h4>{{ $t('welcome') }}</h4>
				<Input ref="username"
					class="input"
					:label="$t('username')"
					v-model="userId"
					:autocomplete="credentialAPI ? 'off' : 'username'"
					:notification="userError"
					@enter="onFocusNext" />
				<Input class="input"
					ref="password"
					:label="$t('password')"
					type="password"
					:autocomplete="credentialAPI ? 'off' : 'current-password'"
					v-model="password"
					:notification="pwError"
					@enter="onLoginClick" />
				<Button class="submit"
					@click.stop="onLoginClick"
					:loading="loading">Login</Button>
			</div>
			<div v-if="showStayLoggedInDialog"
				class="stayLoggedInDialog">
				<h3>{{ userFirstName }}</h3>
				<p>{{ $t('stayLoggedinMsg1') }}</p>
				<p class="settingsRow">
					<span class="settingsIcon">
						<Icon :icon="icons.Contact" />
					</span>
					{{ $t('stayLoggedinMsg2') }}
				</p>
				<p class="infoRow">{{ $t('stayLoggedinMsg3') }}</p>
				<div class="row">
					<Button class="submit yes blue"
						@click="stayLoggedInClick(true)">{{ $t('yes') }}</Button>
					<Button class="submit"
						@click="stayLoggedInClick(false)">{{ $t('no') }}</Button>
				</div>
			</div>
		</div>
		<div v-if="!connected">connecting...</div>
	</div>
</template>

<script>
// pinia

import Input from '@components/Form/Input'
import Button from '@components/Form/Button'
import fetch from '@components/Portal/fetch'
import Icon from '@icons/Icon.vue'
import { Contact } from '@icons/appFabric/icons'

import { mapActions, mapState, mapWritableState } from 'pinia'
import pinia from 'src/pinia'
import portalStateP from '@components/Portal/portalState'
const portalState = portalStateP(pinia)

export default {
	name: 'Authenticate',
	components: {
		Input,
		Button,
		Icon,
	},
	data: function () {
		return {
			userId: undefined,
			password: undefined,
			userError: undefined,
			pwError: undefined,
			showStayLoggedInDialog: false,
			connected: false,
			loading: false,
			userFirstName: undefined,
			icons: {
				Contact,
			},
			credentialAPI: null,
			loginResponse: null,
		}
	},
	methods: {
		async initialize() {

			// set if we want to enable credentialAPI
			this.credentialAPI = this.$portal.portalConfig.credentialAPI
			if (this.credentialAPI && !window.PasswordCredential) {
				// credentialAPI is enabled but not supported by browser
				console.log('credentialAPI not supported by browser')
				this.credentialAPI = false
			}
			console.log('Authenticate mounted', this.connected, this.credentialAPI)
			//check to see if connection attempt was made
			if (this.connected === false) {
				await fetch.authorize()
			}
			this.connected = true
			if (portalState.authenticated) {
				return
			}
			if (this.credentialAPI) {
				this.withCredentials()
			}
		},
		/**
		 * Login submit
		 */
		async onLoginClick() {

			//check values
			if (!this.userId || this.userId.length < 5) return
			if (!this.password || this.password.length < 2) return

			this.loading = true

			//try to fetch
			try {
				this.loginResponse = await fetch.login(this.userId, this.password)
			} catch (error) {
				this.loading = false
				this.onUnauthorized(error.message)
				//do not proceed to login
				return
			}
			this.userError = null
			this.pwError = null

			this.userFirstName = this.loginResponse.userConfig.firstName

			//set credentials
			if (this.credentialAPI) {
				await this.storeCredentials()
			}

			//show stayLoggedIn
			this.showStayLoggedInDialog =
				typeof this.loginResponse.clientConfig.stayLoggedIn === 'undefined' ? true : false

			// this.loading = false;
			if (this.showStayLoggedInDialog === false) {
				//unlock the app
				fetch.onLoginSuccess(this.loginResponse)
			}
		},
		onFocusNext() {
			this.$refs.password.focus()
		},
		/**
		 * Login
		 */
		async onUnauthorized(message) {

			if (message == 'User not found') {
				this.userError = this.$t('userNotFound')
				this.pwError = undefined
			} else if (message == 'User inactive') {
				this.userError = this.$t('userInactive')
				this.pwError = undefined
			} else if (message == 'Authentication failed') {
				this.userError = undefined
				this.pwError = this.$t('passwordInvalid')
			} else {
				this.userError = undefined
				this.pwError = message
			}
		},
		async stayLoggedInClick(value) {
			fetch.onLoginSuccess(this.loginResponse)
			portalState.stayLoggedInChange(value)
		},
		/**
		 * Get stored Credentials and set them to passwordfields
		 */
		async withCredentials() {
			console.log('getCredentials')
			if (window.PasswordCredential) {
				const cred = await navigator.credentials.get({ password: true, mediation: 'optional' })
				//console.log('credentials found', cred)
				if (cred) {
					//this.$refs.username.blur();
					this.userId = cred.id
					this.password = cred.password

				} else {
					console.log('no credentials found')
				}
			}
		},
		/**
		 * store credentials to browser store
		 */
		async storeCredentials() {
			console.log('storeCredentials', this.userId)
			if (window.PasswordCredential) {
				const cred = new window.PasswordCredential({
					id: this.userId,
					password: this.password,
					name: this.userId,
				})

				await navigator.credentials.store(cred)
			}
		},
	},
	computed: {
		...mapWritableState(portalStateP, ['stayLoggedIn']),
	},
	mounted() {
		this.initialize()
	}
}
</script>

<i18n>
{
	"de": {
		"no": "Nein",
		"password": "Passwort",
		"passwordInvalid": "Passwort ungültig",
		"stayLoggedinMsg1": "Möchtest Du auf diesem Computer dauerhaft angemeldet bleiben?",
		"stayLoggedinMsg2": "Du findest die Einstellungen danach unter diesem Symbol",
		"stayLoggedinMsg3": "Durch Ausloggen oder Ändern der Einstellungen wird das angemeldet bleiben zurückgesetzt",
		"userInactive": "Benutzer inaktiv",
		"userNotFound": "Benutzer unbekannt",
		"username": "Benutzername",
		"welcome": "Willkommen",
		"yes": "Ja"
	},
	"fr": {
		"no": "Non",
		"password": "Mot de passe",
		"passwordInvalid": "Mot de passe non valable",
		"stayLoggedinMsg1": "Rester connecté en permanence sur cet ordinateur?",
		"stayLoggedinMsg2": "Cette icône permet d’accéder aux paramètres par la suite",
		"stayLoggedinMsg3": "La déconnexion ou une modification des paramètres annule le réglage «rester connecté».",
		"userInactive": "Utilisateur inactif",
		"userNotFound": "Utilisateur inconnu",
		"username": "Nom d’utilisateur",
		"welcome": "Bienvenue",
		"yes": "Oui"
	},
	"it": {
		"no": "No",
		"password": "Password",
		"passwordInvalid": "Password non valida",
		"stayLoggedinMsg1": "Vuoi rimanere collegato a questo computer in modo permanente?",
		"stayLoggedinMsg2": "Trovi le impostazioni sotto a questo simbolo",
		"stayLoggedinMsg3": "Eseguendo il logout o modificando le impostazioni, questa impostazione si azzera.",
		"userInactive": "Utente inattivo",
		"userNotFound": "Utente sconosciuto",
		"username": "Nome utente",
		"welcome": "Benvenuto",
		"yes": "Sì"
	}
}
</i18n>

<style scoped>
h4 {
	margin-top: 32px;
	font-size: 37px;
	margin-bottom: 20px;
}

.fullScreen {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.login {
	width: 350px;
	padding: 10px 50px;
}

.input {
	width: 100%;
	margin: 18px 0;
}

.input:deep(.notification) {
	color: red;
}

.login .submit {
	margin-top: 20px;
	margin-bottom: 50px;
	background-color: var(--apleona-blau2);
	color: white;
	height: 40px;
}

.login .submit:hover {
	background-color: var(--apleona-blau1);
	color: white;
}

.stayLoggedInDialog {
	border: 1px solid var(--lightGrey);
	width: 380px;
	padding: 10px 25px;
	border-top: 4px solid var(--accentBlue);
}

.stayLoggedInDialog .submit {
	margin-top: 30px;
	margin-bottom: 30px;
	height: 35px;
	width: 125px;
}

.stayLoggedInDialog .submit.yes {
	background-color: #eaeff5;
}

.stayLoggedInDialog .row {
	display: flex;
	justify-content: space-between;
}

.stayLoggedInDialog h1 {
	margin-bottom: 12px;
}

.settingsRow {
	margin-top: 24px;
	display: flex;
	align-items: flex-start;
}

.settingsRow .settingsIcon {
	border-radius: 50%;
	border: 1px solid grey;
	margin-bottom: 3px;
	min-width: 25px;
	min-height: 25px;
	margin-right: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 5px;
}

.infoRow {
	margin-top: 15px;
}
</style>
