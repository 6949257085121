<template>
   <div v-if="show"
      @click.stop
      class="TimeToggle">
      <div v-if="toggleItem"
         class="play stop"
         @click="onToggleEnd">
         <Icon icon="iconStop" />
      </div>
      <div v-else
         class="play"
         @click="onToggleStart">
         <Icon icon="iconPlay" />
      </div>
      <div class="text">
         <Textarea placeholder="Tätigkeit..."
            v-model="text"
            :style="textAreaStyle"
            @focus="onTextAreaFocus(true)"
            @blur="onTextAreaFocus(false)" />
         <div v-if="showTimeEntryTextList"
            class="textList"
            ref="textList">
            <pre v-for="(text, index) in timeEntryTextList"
               :key="index"
               class="item"
               @click="setText(text)">{{ text }}</pre>
         </div>
      </div>
      <div v-if="timeEntryTextList.length > 0"
         class="arrowDown"
         @click="showTimeEntryTextList = !showTimeEntryTextList">
         <Icon icon="iconArrowDown" />
      </div>
      <div v-if="text && text.length > 0"
         class="cancel"
         @click="text = null">
         <Icon icon="iconCancel" />
      </div>
   </div>
</template>

<script>
import { DateTime, Day } from '@SyoLab/date-time'
import { durationUpdate, recordUpdate, getNewTimeEntry } from '../components/timeEntrys'
import { onTaskUpdate } from '../task/task'
import { onFixedPriceTaskUpdate } from '../fixedPriceTask/fixedPriceTask'

import Icon from "@icons/Icon.vue"
import { PlaySolid, CaretSolidDown, Cancel, StopSolid } from "@icons/appFabric/icons"
import Textarea from '@components/Form/Textarea.vue';
import eventBus from '@components/Portal/eventBus'
export default {
   name: 'TimeToggle',
   components: { Icon, Textarea },
   props: {
      config: { required: true },
      service: { required: true },
      readonly: { required: true },
      toggleItems: { required: false }
   },
   data() {
      return {
         text: null,
         showTimeEntryTextList: false,
         textAreaFocus: false
      }
   },
   static: {
      iconPlay: PlaySolid,
      iconStop: StopSolid,
      iconArrowDown: CaretSolidDown,
      iconCancel: Cancel
   },
   methods: {
      setText(text) {
         this.text = text
         if (this.toggleItem) {
            this.toggleItem.text = this.text
            this.$emit('update')
         }
         setTimeout(() => {
            this.showTimeEntryTextList = false
         }, 1)

      },
      onTextAreaFocus(focus) {
         this.textAreaFocus = focus
         this.showTimeEntryTextList = false
         if (!focus) {
            if (this.toggleItem) {
               this.toggleItem.text = this.text
               this.$emit('update')
            }
         }
      },
      onToggleStart() {
         let timeEntry = getNewTimeEntry(this.service, this.config)
         timeEntry.from = DateTime.now().toFormat('HH:mm')
         if (this.text) timeEntry.text = this.text
         this.service.status = 'wip'
         this.service.timeEntrys.push(timeEntry)
         this.$emit('update')
      },
      onToggleEnd() {
         if (!this.toggleItem) return
         let record = this.toggleItem
         record.to = DateTime.now().toFormat('HH:mm')
         durationUpdate(record)
         recordUpdate(record)
         if (record.error) {
            eventBus.emit('notify.warning', 'Fehler beim Erstellen des Zeiteintrags')
            console.error('TimeEntry error')
            this.$emit('update')
            return
         }

         if (this.service.type == 'task') {
            onTaskUpdate(this.service, this.config, 'timeEntry')
         }
         if (this.service.type == 'fixedPriceTask') {
            onFixedPriceTaskUpdate(this.service, this.config, 'timeEntry')
         }

         this.$emit('update')

      },
      outsideClickFn(e) {
         if (this.showTimeEntryTextList && this.el && this.refs.textList && this.refs.textList.contains(e.target)) return
         this.showTimeEntryTextList = false
      },
   },
   computed: {
      timeEntryTextList() {
         let textList = []
         this.service.timeEntrys.filter(item => item.text).forEach(item => {
            if (!textList.includes(item.text)) textList.push(item.text)
         })

         return textList.slice(0, 5)
      },
      textAreaStyle() {
         if (this.textAreaFocus) return {
            minHeight: '28px',
            backgroundColor: 'var(--rowBgColor)',
            zIndex: 2
         }

         return {
            maxHeight: '24px',
            backgroundColor: 'transparent'
         }
      },
      toggleItem() {
         return this.service.timeEntrys.find(item => {
            if (item.employee_id !== this.config.employee_id) return false
            return item.from && !item.to
         })
      },
      show() {
         if (this.toggleItem) return true
         if (this.toggleItems && this.toggleItems.length == 0) return true
         return false
      }
   },
   watch: {
      toggleItem() {
         if (!this.toggleItem) this.text = null
      }
   },
   created() {
      if (this.toggleItem) {
         this.text = this.toggleItem.text
      }
   },
   mounted() {
      this.$portal.$on('documentTouchClick', this.outsideClickFn)
   },
   beforeDestroy() {
      this.$portal.$off('documentTouchClick', this.outsideClickFn)
   }
}
</script>

<style scoped>
.TimeToggle {
   display: flex;
   align-items: flex-start;
   height: 100%;

}

.play {
   width: 32px;
   height: 100%;
   background-color: #6ca2eb;
   color: white;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 12px;
   align-self: center;
}

.play.stop {
   background-color: #0ba9a5;
   font-size: 11px;
}

.arrowDown {
   align-self: center;
   padding: 3px;
   font-size: 12px;
}

.cancel {
   align-self: center;
   padding: 3px;
   font-size: 14px;
}

.text .Textarea {
   width: 250px;
   padding: 4px;
}

.text {
   position: relative;
   padding-left: 3px;
   padding-top: 4px;
}

.text .textList {
   position: absolute;
   top: 100%;
   left: 3px;
   width: 250px;
   background-color: white;
   display: flex;
   flex-direction: column;
   box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
   border-radius: 3px;
   z-index: 3;
}

.textList .item {
   border-top: 1px solid #d9d9d9;
   padding: 4px;
}

.textList .item:first-child {
   border-top: 0;
}

.textList .item:hover {
   background-color: #f5f5f5;
}
</style>