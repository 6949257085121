<template>
	<div class="UserSwitch"
		@click="onShowDialog"
		:class="{ proxy: proxyInfo }">
		<div class="toolbarIcon">
			<Icon icon="iconSwitchUser" />
		</div>
		<Dialog class="blue"
			v-if="showDialog"
			:header="$t('dialogTitle')"
			transferTo="modal"
			top="10vh"
			@close="showDialog = false">
			<div class="dlgBody">
				<Dropdown ref="Dropdown"
					class="userId"
					label="User"
					placeholder="Suche"
					v-model="user"
					:options="{
						autocomplete: true,
						listItems: userList,
						listItemKey: 'persNo',
						listItemValueKey: 'name',
						returnListItem: true,
						autocompleteKeys: true,
					}"
					:autocomplete="onAutocomplete"
					@enter="onUserSwitch"
					@hook:mounted="onDropdownMounted" />

				<Button class="blue"
					@click="onUserSwitch">{{ $t('userSwitch') }}</Button>
				<Button class="red"
					@click="onUserSwitchBack">{{ $t('userSwitchBack') }}</Button>
			</div>
		</Dialog>
		<div v-if="proxyInfo"
			class="proxyInfo">
			<div class="userId">{{ proxyInfo }}</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import portalStateP from '@components/Portal/portalState'
const portalState = portalStateP()

import Icon from '@icons/Icon.vue'
import { SwitchUser, Cancel } from '@icons/appFabric/icons'
import Dialog from '@components/Dialog/Dialog.vue'
import Dropdown from '@components/Form/Dropdown.vue'
import Button from '@components/Form/Button.vue'
import api from '@components/Portal/api'
import fetch from '@components/Portal/fetch'
import eventBus from '../eventBus'
export default {
	name: 'UserSwitch',
	components: { Icon, Dropdown, Dialog, Button },
	props: {},
	data() {
		return {
			showDialog: false,
			user: '',
		}
	},
	static: {
		iconSwitchUser: SwitchUser,
		iconClose: Cancel,
	},
	methods: {
		onShowDialog() {
			if (this.showDialog) {
				this.showDialog = false
				this.user = null
			} else {
				if (!portalState.proxyProvider) {
					this.user = null
				}
				this.showDialog = true
			}
		},
		async onUserSwitch() {
			if (!this.user) return
			let res
			try {
				res = await api.post('proxy/switch', { userId: this.user.userId })
				fetch.onLoginSuccess(res)
				this.showDialog = false
				setTimeout(() => {
					window.location.assign(window.location.origin)
				}, 500)
			} catch (error) {
				eventBus.emit('notify.warning', error.message)
			}
		},
		async onUserSwitchBack() {
			let res = await api.delete('proxy/switch')
			this.userId = ''
			fetch.onLoginSuccess(res)
			this.showDialog = false
			setTimeout(() => {
				window.location.assign(window.location.origin)
			}, 500)
		},
		async onAutocomplete(value) {
			if (!value) return []
			let res = await api.post('proxy/user/list', { search: value })
			return res
				.map(user => {
					user.name = `${user.firstName} ${user.lastName} (${user.userId})`
					return user
				})
				.filter(item => {
					if (portalState.proxyProvider) {
						return item.key !== portalState.proxyProvider.userId
					} else {
						return item.key !== portalState.userConfig.userId
					}
				})
		},
		onDropdownMounted() {
			this.$refs.Dropdown.focus()
		},
	},
	computed: {
		userList() {
			let ret = []
			if (portalState.proxyProvider) {
				ret.push({
					persNo: portalState.userConfig.persNo,
					name: `${portalState.userConfig.firstName} ${portalState.userConfig.lastName} (${portalState.userConfig.userId})`,
				})
			}
			return ret
		},
		proxyInfo() {
			if (portalState.proxyProvider) {
				return portalState.userConfig.userId
			}
			return null
		},
	},
	created() {
		if (portalState.proxyProvider) {
			this.userId = portalState.userConfig.userId
		}
	},
}
</script>

<i18n>
{
	"de": {
		"dialogTitle": "Benutzer wechseln",
		"userSwitch": "Wechseln",
		"userSwitchBack": "Zurück"
	},
	"fr": {
		"dialogTitle": "Changer d'utilisateur",
		"userSwitch": "Échanger",
		"userSwitchBack": "Retour"
	},
	"it": {
		"dialogTitle": "Cambiare utente",
		"userSwitch": "Cambiare",
		"userSwitchBack": "Indietro"
	}
}
</i18n>

<style scoped>
.UserSwitch {
	position: relative;
	height: 100%;
	width: 64px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
	position: relative;
}

.UserSwitch.proxy {
	background-color: yellow;
}

.UserSwitch.proxy .toolbarIcon {
	color: black;
	border-color: black;
}

.toolbarIcon {
	height: 32px;
	width: 32px;
	border-radius: 50%;
	border: 1px solid #e2e1e1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
}

.toolbarIcon .icon {
	font-size: 22px;
	transform: translate(2px, 0px);
}

.Dropdown.userId {
	width: 355px;
}

.dlgBody {
	display: flex;
	flex-direction: column;
	row-gap: 15px;
	justify-content: space-around;
}

.proxyInfo {
	position: absolute;
	bottom: -31px;
	right: -27px;
	background-color: yellow;
	color: black;
	padding: 4px 10px;
}</style>
