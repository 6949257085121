<template>
   <div class="ProjectServiceGridRow"
      :title="title"
      :style="{ gridRow: row + 1 }"
      @click="$emit('click', service)">
      <div class="borderLeft"
         :style="borderLeftStyle">
      </div>
      <div class="projectName"
         :style="{ gridRow: row + 1 }">{{ service.projectName }}</div>
      <div class="middle"
         :style="{ gridRow: row + 1 }">
         <div class="total">{{ totalWorkTime }}</div>
         <div v-if="estimatedWorkTime"
            class="planned">{{ estimatedWorkTime }}</div>
      </div>
      <div class="right">
         <div v-if="showRight == 'projectTotal'"
            class="projectTotal">
            {{ projectTotal }}
         </div>
      </div>
      <div class="action"
         :style="{ gridRow: row + 1 }"
         @click.stop>
         <CheckBox v-if="isSelectable"
            class="small"
            :value="isSelected"
            @input="$emit('select', service, $event)" />

      </div>
   </div>
</template>

<script>
import CheckBox from '@components/Form/CheckBox.vue';
import Status from '../components/Status.vue';
import { getStatusBgColor, getProjectStatus } from '../components/utils'
import { minutes } from 'src/utils/utils'

export default {
   name: 'ProjectServiceGridRow',
   components: { CheckBox, Status, },
   props: {
      config: { required: true },
      service: { required: true },
      row: { required: true },
      selectable: { required: false, default: false },
      selectedCustomer_id: { required: false, default: false },
      selectedServices: { required: false, default: false }
   },
   data() {
      return {}
   },

   methods: {},
   computed: {
      title() {
         let serviceType = this.config.addServiceList.find(item => item.serviceType === this.service.serviceType)
         return serviceType?.value || 'Projekt'
      },
      status() {
         return getProjectStatus(this.service._services)
      },
      isSelectable() {
         if (!this.selectable) return false
         if (this.selectedCustomer_id && this.selectedCustomer_id != this.service.customer_id) return false
         return true
      },
      isSelected() {
         return this.service._services.some(s => this.selectedServices.includes(s._id))
      },
      borderLeftStyle() {
         return {
            gridRow: this.row + 1,
         }
      },
      showRight() {
         if (this.config.showBilling === false) return null
         return 'projectTotal'
      },
      projectTotal() {
         this.service._services
         let value = this.service._services.reduce((acc, s) => {
            let value = s.value || 0
            return acc + value
         }, 0)

         value = value.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
         return `CHF ${value}`
      },
      estimatedWorkTime() {
         let value = this.service._services.reduce((acc, s) => {
            let value = s.estimatedMinutes || 0
            return acc + value
         }, 0)
         if (value == 0) return null
         return `(${minutes.toFormatDecimal(value)} Std)`
      },
      totalWorkTime() {
         let value = this.service._services.reduce((acc, s) => {
            let value = s.totalMinutes || 0
            return acc + value
         }, 0)
         if (value == 0) {
            if (this.estimatedWorkTime) return '0 Std'
         }
         return `${minutes.toFormatDecimal(value)} Std`
      },

   },
}
</script>

<style scoped>
* {
   --fontColor: #6f6f64;
   --borderColor: #F0F1F2;
}

.ProjectServiceGridRow {
   display: grid;
   grid-column: 1/-1;
   grid-template-columns: subgrid;
   grid-template-rows: subgrid;
   cursor: pointer;
}

.ProjectServiceGridRow>* {
   display: flex;
   align-items: center;
   padding: 1px 0;
}

.projectName {
   grid-column: borderLeft/middle;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   color: var(--fontColor);
   font-size: 16px;
   font-weight: 500;
}

.select {
   grid-column: select;
   padding-left: 3px;
}

.middle {
   grid-column: middle;
}

.middle .planned {
   margin-left: 5px;
}

.billingTotal {
   padding-right: 10px;
   text-align: right;
   width: 100%;
}

.right {
   grid-column: right;
   display: flex;
   align-items: center;
   padding: 0;
   border: 0;
}

.action {
   grid-column: action;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: default;
}

.right .projectTotal {
   padding-right: 10px;
   text-align: right;
   width: 100%;
   color: var(--fontColor);
   font-size: 16px;
   font-weight: 500;
}
</style>