import utils from "@SyoLab/utils"
import { Settings } from "../../@icons/appFabric/icons"

import AppLoading from "@components/Portal/ErrorPages/AppLoading.vue"
import AppLoadingError from "@components/Portal/ErrorPages/AppLoadingError.vue"
import { defineAsyncComponent } from 'vue'
const component = defineAsyncComponent({
   loader: () => import(/* webpackChunkName: "Config" */ './Config.vue'),
   loadingComponent: AppLoading,
   delay: 200,
   errorComponent: AppLoadingError,
})

const appName = 'config'
export default {
   name: appName,
   auth: true, //auth: if authenticated must have app in userApps
   menuShow: false, //menuShow overrides everything
   menuPosition: 30, //sorting menu position of app
   component: component,
   routes: [
      {
         path: "/config*"
      }
   ],
   menu: [
      {
         "_id": utils.shortId(),
         "appName": appName,
         "text": "Konfiguration",
         "icon": Settings,
         "class": "siteClass",
         "route": "/config", //route path
         "action": undefined, //action emitted to app
         "enabled": true, // enabled / disable menu item (can be used as function with appConfig)
         // status
         "active": false, // is active menu item
         "show": true, // show / hide, toggles visibility on click
      }
   ]
}