<template>
	<transition-group tag="div" class="notifyPanel" name="stack" @before-leave="beforeLeave">
		<div
			v-for="notification in notifications"
			:key="notification.id"
			:ref="notification.id"
			:class="{ 'stack-wrap': true, info: notification.info }"
		>
			<SlideDown :show="notification.slideDown">
				<Notification :notification="notification" @dismiss="onDismiss"></Notification>
			</SlideDown>
		</div>
	</transition-group>
</template>

<script type="text/javascript">
import utils from "@SyoLab/utils";
import notification from "./Notification.vue";
import eventBus from "./eventBus";
import SlideDown from "../Containers/SlideDown.vue";
let listeners = [];
export default {
  name: "NotificationPanel",
  components: {
    Notification: notification,
    SlideDown,
  },
  data: function () {
    return {
      notifications: [],
    };
  },
  methods: {
    notify: function (msg, options = {}) {
      //null => clear notifications
      if (msg === null) {
        this.notifications = [];
        return;
      }
      let notif = {
        id: utils.shortId(),
        slideDown: false,
        msg: msg,
				...options,
      };

      //set Timeout
      if (notif.timeout) {
        setTimeout(() => {
          this.onDismiss(notif.id);
        }, notif.timeout);
      }
      //show notification
      this.notifications.push(notif);
      setTimeout(() => {
        notif.slideDown = true;
      }, 10);
    },
    onDismiss: function (id) {
      let idx = this.notifications.findIndex((item) => item.id == id);
      this.notifications.splice(idx, 1);
    },
    //before leave transition starts
    beforeLeave: function (el) {
      //set height to be able to transform
      el.style.height = el.getBoundingClientRect().height + "px";
    },
  },
  beforeCreate: function () {
    listeners.push(
      eventBus.on("notify", (msg, options) => {
        let props = {
          type: "info",
          timeout: 5000,
					...options,
        };
        this.notify(msg, props);
      })
    );
    listeners.push(
      eventBus.on("notify.error", (msg, options) => {
        let props = {
          type: "error",
          timeout: 5000,
					...options,
        };
        this.notify(msg, props);
      })
    );
    listeners.push(
      eventBus.on("notify.warning", (msg, options) => {
        let props = {
          type: "warning",
          timeout: 5000,
					...options,
        };
        this.notify(msg, props);
      })
    );
    listeners.push(
      eventBus.on("notify.info", (msg, options) => {
        let props = {
          type: "info",
          timeout: 5000,
					...options,
        };
        this.notify(msg, props);
      })
    );
    listeners.push(
      eventBus.on("notify.clearAll", (msg, options) => {
        this.notify(null, {});
      })
    );
  },
  beforeDestroy() {
    listeners.forEach((listener) => {
      if (typeof listener == "function") {
        listener.off();
      }
    });
  },
};
</script>

<style scoped>
	.notifyPanel {
		margin: 10px;
		display: flex;
		flex-direction: column-reverse;
		overflow: hidden;
		padding-bottom: 4px;
		z-index: 10;
	}
	.stack-wrap {
		margin-bottom: 4px;
		overflow: hidden;
	}

	/* start height is set by javascript beforLeave*/
	.stack-leave-active {
		transition: opacity 0.3s, height 0.5s ease 0.3s, margin-bottom 0.2s linear 0.6s;
	}
	.stack-leave-to {
		opacity: 0 !important;
		height: 0 !important; /* target height */
		margin-bottom: 0 !important;
	}
</style>
