<template>
	<div class="UserSettings"
		:class="{ active: show }"
		@click="show = !show">
		<div class="toolbarIcon">
			<Icon icon="iconUser" />
		</div>
		<Popover v-if="show"
			:anchorEl="$portal.$refs.Portal"
			:refEl="$el"
			orientation="bottom"
			align="right"
			@outsideClick="onOutsideclick">
			<div class="toolbarAction boxShadow triangle-top">
				<div class="popup">
					<h6>{{ $t('myAccount') }}</h6>
					<p class="bold">{{ user.firstName }} {{ user.lastName }}</p>
					<p>{{ user.userId }}</p>
					<div class="row settings">
						<span>{{ $t('stayLoggedIn') }}</span>
						<ToggleButton :labels="{ checked: $t('yes'), unchecked: $t('no') }"
							:width="50"
							:value="stayLoggedIn"
							@change="onStayLoggedInChange" />
					</div>
					<div class="row lang">
						<span>{{ $t('languages') }}:</span>
						<div class="langList">
							<span v-for="(lang, index) in $portal.portalConfig.languages"
								:key="index"
								:class="{ active: activeLang(lang) }"
								@click="setLang(lang)">{{ getLang(lang) }}</span>
						</div>
					</div>
					<div class="row lang">
						<span>Build:</span>
						<span>{{ $portal.portalConfig.build }}</span>
					</div>
					<div class="row">
						<Button @click="onLogOff">{{ $t('logOff') }}</Button>
					</div>
				</div>
			</div>
		</Popover>
	</div>
</template>

<script>
import Popover from '@components/Containers/Popover.vue'
import ToggleButton from '@components/Form/Switcher.vue'
import Button from '@components/Form/Button.vue'
import eventBus from '../../Portal/eventBus'

import pinia from 'src/pinia'
import { mapActions, mapState, mapWritableState } from 'pinia'
import portalStateP from '../portalState'
const portalState = portalStateP(pinia)

import { Contact } from '@icons/appFabric/icons'
import Icon from '@icons/Icon.vue'

export default {
	components: {
		Popover,
		ToggleButton,
		Button,
		Icon,
	},
	props: {
		user: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			preventClose: false,
			show: false,
		}
	},
	static: {
		iconUser: Contact,
	},
	methods: {
		...mapActions(portalStateP, ['logOff']),
		onStayLoggedInChange: function (e) {
			portalState.stayLoggedInChange(e.value)
		},
		onOutsideclick(e) {
			if (this.preventClose) return
			this.$emit('close')
			this.show = false
		},
		async setLang(lang) {
			//display loader
			this.preventClose = true
			portalState.loader = true
			await this.$displayUpdate()
			portalState.setLocale(lang, true)
			this.$i18n.locale = lang
			await this.$displayUpdate()
			//hide loader
			this.preventClose = false
			portalState.loader = false
		},
		getLang(lang) {
			return lang.toUpperCase()
		},
		activeLang(lang) {
			return this.$i18n.locale.split('-')[0] == lang
		},
		onLogOff() {
			this.$emit('close')
			this.show = false
			portalState.logOff()
		},
	},
	computed: {
		...mapWritableState(portalStateP, ['stayLoggedIn', 'stayLoggedInChange']),
	},
	beforeDestroy() {
		this.show = false
		if (this.$el.parentNode) {
			this.$el.parentNode.removeChild(this.$el)
		}
	},
}
</script>

<i18n>
{
	"de": {
		"languages": "Sprachen",
		"logOff": "Abmelden",
		"myAccount": "Mein Konto",
		"no": "Nein",
		"stayLoggedIn": "Angemeldet bleiben",
		"yes": "Ja"
	},
	"fr": {
		"languages": "Langues",
		"logOff": "Déconnexion",
		"myAccount": "Mon compte",
		"no": "Non",
		"stayLoggedIn": "Rester connecté",
		"yes": "Oui"
	},
	"it": {
		"languages": "Lingue",
		"logOff": "Logout",
		"myAccount": "Il mio account",
		"no": "No",
		"stayLoggedIn": "Rimani collegato",
		"yes": "Si"
	}
}
</i18n>

<style scoped>
.UserSettings {
	height: 100%;
	width: 64px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
	position: relative;
}

.UserSettings.active {
	background-color: #557593;
	color: var(--apleona-blau);
}

.Popover {
	margin-right: 5px;
}

.toolbarAction {
	position: relative;
}

.popup {
	position: relative;
	margin-top: 16px;
	background-color: #fff;
	min-width: 230px;
	padding: 15px 15px 25px 15px;
	border: none;
	z-index: 1000;
	border-radius: 0;
	text-align: left;
}

.popover {
	padding-right: 10px;
}

.bold {
	font-weight: 500;
}

.row {
	margin-top: 15px;
	display: flex;
	align-items: center;
}

.row.settings {
	justify-content: space-between;
	margin-bottom: 20px;
}

.button {
	height: 28px !important;
}

.triangle-top::before {
	left: unset;
	right: 10px;
}

.row.lang {
	justify-content: space-between;
}

.langList {
	margin-left: 5px;
}

.langList span {
	padding: 0px 5px;
	background-color: #bfcbd9;
	margin-left: 5px;
	color: white;
	font-size: 13px;
	cursor: pointer;
}

.langList span.active {
	background-color: #4c668b;
	color: inherit;
}

.toolbarIcon {
	height: 32px;
	width: 32px;
	border-radius: 50%;
	border: 1px solid #e2e1e1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
}

.toolbarIcon .icon {
	font-size: 20px;
	transform: translateY(-2px);
}
</style>
