import Vue from 'vue'

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

// import DragDropTouch from "@components/Portal/utils/DragDropTouch"

import eventBus from '@components/Portal/eventBus';

//mixin
import globalMixin from "@components/Portal/globalMixin";

Vue.config.productionTip = false
Vue.config.performance = process.env.NODE_ENV != "production";
Vue.mixin(globalMixin);

import Portal from "@components/Portal/Portal";
import router from "./router"
import i18n from './i18n'
import pinia from "./pinia"


if (window) {
	let currentGroupDepth = 0;

	const consoleLog = window.console.log
	const consoleGroup = window.console.group
	const consoleGroupCollapsed = window.console.groupCollapsed
	const consoleGroupEnd = window.console.groupEnd

	// window.console.log = function () {
	// 	consoleLog('-')
	// 	consoleLog.apply(window, arguments)
	// }

	window.console.group = function () {
		currentGroupDepth++
		consoleGroup.apply(window, arguments)
		return currentGroupDepth
	}
	window.console.groupCollapsed = function () {
		currentGroupDepth++
		consoleGroupCollapsed.apply(window, arguments)
		return currentGroupDepth
	}
	window.console.groupEnd = function (targetDepth) {
		if (!targetDepth && targetDepth !== 0) targetDepth = currentGroupDepth - 1
		while (targetDepth < currentGroupDepth) {
			consoleGroupEnd()
			currentGroupDepth--
		}
	}
}


Vue.config.errorHandler = (err, vm, info) => {

	console.error(err)

	let componentName = vm.$options._componentTag

	eventBus.emit('logger.error', {
		errorType: "Vue",
		vueComponentName: componentName,
		vueInfo: info,
		errorMessage: err.message,
		errorStack: err.stack.toString(),
	})

};

new Vue({
	render: h => h(Portal),
	pinia,
	i18n,
	router,
	data: { locale: null, locales: [] }
}).$mount('#app')
