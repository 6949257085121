<template>
  <div class="scrollbar-vertical">
    <div v-if="height < 100"
      ref="container"
      class="vue-scrollbar__scrollbar-vertical"
      @click="jump">
      <div ref="scrollbar"
        :class="'scrollbar' + (dragging || draggingFromParent ? '' : ' vue-scrollbar-transition')"
        :style="{
          height: height + '%',
          top: scrolling + '%',
        }"
        @touchstart="startDrag"
        @mousedown="startDrag"
        @click.stop="() => { }"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    draggingFromParent: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    scrolling: Number,
    // eslint-disable-next-line vue/require-default-prop
    wrapper: Object,
    // eslint-disable-next-line vue/require-default-prop
    area: Object,
    // eslint-disable-next-line vue/require-default-prop
    onChangePosition: Function,
    // eslint-disable-next-line vue/require-default-prop
    onDragging: Function,
    // eslint-disable-next-line vue/require-default-prop
    onStopDrag: Function,
  },

  data() {
    return {
      height: 0,
      dragging: false,
      start: 0,
    };
  },

  watch: {
    "wrapper.height"(val, old) {
      this.calculateSize(this);
    },

    "area.height"(val, old) {
      this.calculateSize(this);
    },
  },

  mounted() {
    this.calculateSize(this);

    // Put the Listener
    document.addEventListener("mousemove", this.onDrag);
    document.addEventListener("touchmove", this.onDrag);
    document.addEventListener("mouseup", this.stopDrag);
    document.addEventListener("touchend", this.stopDrag);
    document.addEventListener("click", this.onClick);
  },

  beforeDestroy() {
    // Remove the Listener
    document.removeEventListener("mousemove", this.onDrag);
    document.removeEventListener("touchmove", this.onDrag);
    document.removeEventListener("mouseup", this.stopDrag);
    document.removeEventListener("touchend", this.stopDrag);
    document.removeEventListener("touchend", this.onClick);
  },

  methods: {
    startDrag(e) {
      e.preventDefault();
      e = e.changedTouches ? e.changedTouches[0] : e;

      // Prepare to drag
      (this.dragging = true), (this.start = e.clientY);
    },

    onDrag(e) {
      if (this.dragging) {
        // Make The Parent being in the Dragging State
        this.onDragging();

        e.preventDefault();
        e.stopPropagation();

        e = e.changedTouches ? e.changedTouches[0] : e;

        let yMovement = e.clientY - this.start;
        let yMovementPercentage = (yMovement / this.wrapper.height) * 100;

        // Update the last e.clientY
        this.start = e.clientY;

        // The next Vertical Value will be
        let next = this.scrolling + yMovementPercentage;

        // Tell the parent to change the position
        this.onChangePosition(next, "vertical");
      }
    },

    stopDrag(e) {
      e.preventDefault();
      //e.stopPropagation();
      if (this.dragging) {
        // Parent Should Change the Dragging State
        this.onStopDrag();
        this.dragging = false;
      }
    },

    jump(e) {
      let isContainer = e.target === this.$refs.container;

      if (isContainer) {
        // Get the Element Position
        let position = this.$refs.scrollbar.getBoundingClientRect();

        // Calculate the vertical Movement
        let yMovement = e.clientY - position.top;
        let centerize = this.height / 2;
        let yMovementPercentage = (yMovement / this.wrapper.height) * 100 - centerize;

        // Update the last e.clientY
        this.start = e.clientY;

        // The next Vertical Value will be
        let next = this.scrolling + yMovementPercentage;

        // Tell the parent to change the position
        this.onChangePosition(next, "vertical");
      }
    },

    calculateSize(source) {
      // Scrollbar Height
      this.height = (source.wrapper.height / source.area.height) * 100;
    },

    getSize() {
      // The Elements
      let $scrollArea = this.$refs.container.parentElement;
      let $scrollWrapper = $scrollArea.parentElement;

      // Get new Elements Size
      let elementSize = {
        // Scroll Area Height and Width
        scrollAreaHeight: $scrollArea.children[0].clientHeight,
        scrollAreaWidth: $scrollArea.children[0].clientWidth,

        // Scroll Wrapper Height and Width
        scrollWrapperHeight: $scrollWrapper.clientHeight,
        scrollWrapperWidth: $scrollWrapper.clientWidth,
      };
      return elementSize;
    },
  },
};
</script>
