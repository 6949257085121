<template>
	<portal class="Dialog "
		:to="settings.transferTo"
		:disabled="settings.transferTo ? false : true">
		<transition appear
			name="animate"
			@before-leave="dialogScaleOut">
			<div class="background"
				ref="dialogBg"
				:class="$vnode.data.staticClass"
				@keydown="onKeydown">
				<div class="loader"
					:style="loaderStyle"
					v-if="dialogAnimationStep == 'loader'">
					<Loader />
				</div>
				<div class="container"
					ref="dialog"
					:style="containerStyle"
					:class="containerClass"
					@click.stop="containerClick">
					<div v-if="settings.header"
						:style="headerStyle"
						class="dialogHeader">
						<h6>{{ settings.header }}</h6>
						<div v-if="settings.close"
							class="close"
							@click="$emit('close')">
							<Icon :icon="$static.iconClose" />
						</div>
					</div>
					<div v-if="settings.scroll"
						ref="content"
						class="content scroll"
						:style="contentStyle">
						<div :style="scrollStyle">
							<slot></slot>
						</div>
					</div>

					<div v-else
						class="content"
						ref="content"
						:style="contentStyle">
						<slot></slot>
					</div>
					<div v-if="!settings.header && settings.closeBtn"
						class="close"
						@click="$emit('close')">
						<Icon :icon="$static.iconClose" />
					</div>
				</div>

			</div>
		</transition>
	</portal>
</template>

<script>
import Icon from '@icons/Icon.vue'
import { ChromeClose } from '@icons/appFabric/icons'
import Loader from '@components/Tools/Loader.vue'
export default {
	name: 'Dialog',
	components: { Icon, Loader },
	props: {
		// transfer to apContainer
		// <portal-target  name="portal-app"></portal-target>
		transferTo: {
			type: String,
			required: false,
		},
		// if set => top position of dialog container
		// default is center in viewport
		top: {
			type: String,
			required: false,
		},
		// if set => left position of dialog container
		// default is center in viewport
		left: {
			type: String,
			required: false,
		},
		// max in relation to background
		maxHeight: { required: false },
		maxWidth: { required: false },
		width: { required: false },
		// if set => center dialog in reference to this $el
		center: {
			type: [String, Object, HTMLElement],
			required: false,
		},
		// adds scrollbars to content
		// true | scrollY | scrollX
		scroll: {
			required: false,
			default: false,
		},
		// close X in header
		close: {
			required: false,
			default: true,
		},
		// close button at the end
		closeBtn: {
			required: false,
			default: false,
		},
		// if not allowed => emits close event
		outsideClick: {
			required: false,
			default: false,
		},
		padding: {
			required: false,
			default: '24px',
		},
		header: {
			required: false,
			default: null,
		},
		// displays loader instead of content
		// can be used to show while fetching dialog data 
		loader: {
			required: false,
			default: false,
		},
		options: {
			required: false,
			default: () => {
				return {}
			},
		},
		fullScreen: {
			required: false,
			default: false,
		},
	},

	data() {
		return {
			mounted: false,
			dialogAnimationStep: null,
			update: 0,
			small: true,
			scaleIn: 200
		}
	},
	static: {
		iconClose: ChromeClose,
	},
	methods: {
		doesItFit() {
			let values = [...arguments]
			let total = values[0]
			for (let index = 1; index < values.length; index++) {
				total -= values[index]
			}

			return total >= 0
		},
		get$El(value) {
			if (typeof value == 'string') {
				return document.querySelector(value)
			}
			if (typeof value == 'object') {
				if (value.constructor && value.constructor == 'VueComponent') {
					return value.$el
				}
				if (value instanceof HTMLElement) {
					return value
				}
			}

			return null
		},
		async dialogScaleIn() {
			this.dialogAnimationStep = 'fadeInEnter'
			setTimeout(() => {
				this.dialogAnimationStep = 'fadeInEnd'
				this.update += 1
			}, 1)

		},
		async dialogScaleOut() {
			this.$refs.dialog.classList.add('small')
		},
		outsideClickFn(e) {
			if (this.$el && this.$refs.dialog && this.$refs.dialog.contains(e.target)) return
			if (!this.settings.outsideClick) this.$emit('close')
			this.$emit('outsideClick', e)
		},
		updatePos() {
			this.update += 1
		},
		getMaxHeight() {
			// set by param
			if (this.settings.maxHeight) return this.settings.maxHeight

			if (!this.isPortalTransfer) return 'unset'
			// transfer,
			if (this.$portal.portalHeight > 800) {
				// medium / large screen
				return parseInt(this.$portal.portalHeight * 0.8) + 'px'
			}
			// small screen
			return parseInt(this.$portal.portalHeight * 0.95) + 'px'
		},
		getMaxWidth() {

			if (this.fullScreen) return '100%'
			// set by param
			if (this.settings.maxWidth) return this.settings.maxWidth
			if (this.settings.center && this.centerRect) {
				return this.centerRect.width + 'px'
			} else {
				return parseInt(this.$portal.portalWidth * 0.9) + 'px'
			}
		},
		containerClick(e) {
			this.$portal.$emit('documentTouchClick', e)
		},
		getPaddingRight() {
			let paddings = this.settings.padding.split(' ')
			if (paddings.length == 4) return paddings[1]
			if (paddings.length == 2) return paddings[1]
			return paddings[0]
		},
		getPaddingBottom() {
			let paddings = this.settings.padding.split(' ')
			if (paddings.length == 4) return paddings[2]
			if (paddings.length == 2) return paddings[0]
			return paddings[0]
		},
		onDocumentKeyDown(e) {
			if (e.key == 'Escape') {
				if (this.settings.closeOnEsc) this.$emit('close')
				this.$emit('esc')
			}
		},
		onKeydown(e) {
			if (e.key == 'ArrowDown' || e.key == 'ArrowUp') {
				e.preventDefault()
			}
		},
	},
	computed: {
		dialogBg$el() {
			if (this.settings.transferTo) return this.$portal.$refs.App
			return this.$refs.dialogBg
		},
		// background of dialog
		dialogBgRect() {
			this.update
			if (!this.mounted) return { top: 0, left: 0, width: 0, height: 0 }
			let rect = this.dialogBg$el.getBoundingClientRect()
			return {
				bottom: rect.width,
				height: rect.height,
				left: rect.left,
				right: rect.right,
				top: rect.top,
				width: rect.width,
				x: rect.x,
				y: rect.y,
			}
		},
		// dialog (modal)
		dialogRect() {
			this.update
			if (!this.mounted || !this.$refs.dialog) {
				return { top: 0, left: 0, width: 0 }
			}
			if (this.dialogAnimationStep == 'fadeInEnd') {
				let rect = this.$refs.dialog.getBoundingClientRect()
				return {
					bottom: rect.width,
					height: rect.height,
					left: rect.left,
					right: rect.right,
					top: rect.top,
					width: rect.width,
					x: rect.x,
					y: rect.y,
				}
			}
			// size correction because before animation
			let rect = this.$refs.dialog.getBoundingClientRect()
			let leftCorr = (rect.width * 1.25 - rect.width) / 2
			return {
				bottom: rect.bottom,
				height: rect.height * 1.25,
				left: rect.left + leftCorr,
				right: rect.right,
				top: rect.top,
				width: rect.width * 1.25,
				x: rect.x,
				y: rect.y,
			}
		},

		// ---- top position ------
		topPxRequested() {
			if (!this.settings.top) return null
			if (!this.dialogBgRect) return null

			if (this.settings.top.includes('px')) {
				return parseInt(this.settings.top)
			}
			if (this.settings.top.includes('vh')) {
				let top = (this.$portal.portalHeight / 100) * parseInt(this.settings.top)
				return parseInt(top)
			}
			if (this.settings.top.includes('%')) {
				let top = (this.dialogBgRect.height / 100) * parseInt(this.settings.top)
				return top
			}
			return null
		},
		topPosEffective() {
			if (this.topPxRequested === null) return null
			if (!this.dialogRect) return null
			if (this.topPxRequested === 0) return 0

			let availableHeight = this.dialogBgRect.height

			if (this.isPortalTransfer) {

				if (this.dialogRect.height == 0 && this.topPxRequested) {
					return this.topPxRequested
				}
				if (
					!this.doesItFit(
						availableHeight, // availabe height
						this.dialogRect.height, // dialog height
						this.topPxRequested,
					)
				) {
					//console.log("does not fit height", availableHeight, this.dialogRect.height, this.topPxRequested);
					// if dialog does not fit  and transfer ist set=> center
					return null
				} else if (this.topPxRequested > 0) {
					// because of transform translate-x(-50%) add 1/2 from
					return this.topPxRequested + parseInt(this.dialogRect.height / 2)
				}
				return null
			}
			return this.topPxRequested
		},
		// ---- left position -----
		center$el() {
			if (this.settings.left) return null
			//if (!this.mounted) return false;
			if (this.settings.center) {
				let $el = this.get$El(this.settings.center)
				if (!$el) console.warn('Dialog center element not found')
				return $el
			}
			// parent HtmlElement is default to center
			if (this.settings.transferTo) {
				return this.$refs.dialogBg
			}
			return this.$el.parentElement
		},
		centerRect() {
			this.update
			return this.center$el ? this.center$el.getBoundingClientRect() : null
		},
		leftPxRequested() {
			// left is also in sticky in relation to background
			let leftOffset = 0

			// left explicitly set
			if (this.settings.left) {
				if (this.settings.left.includes('px')) {
					return {
						marginLeft: leftOffset,
						left: parseInt(this.settings.left),
						marginRight: leftOffset,
					}
				}
				if (this.settings.left.includes('vw')) {
					return {
						marginLeft: leftOffset,
						left: parseInt((this.dialogRect.width / 100) * parseInt(this.settings.left)),
						marginRight: leftOffset,
					}
				}
				if (this.settings.left.includes('%')) {
					return {
						marginLeft: leftOffset,
						left: parseInt((this.dialogRect.width / 100) * parseInt(this.settings.left)),
						marginRight: leftOffset,
					}
				}
				return {
					marginLeft: leftOffset,
					left: parseInt(this.settings.left),
					marginRight: leftOffset,
				}
			}

			// default try to center on parent HtmlElement
			if (this.isPortalTransfer && this.centerRect) {
				let centerElOffset = this.centerRect.left - this.dialogBgRect.left // absolute
				let left = this.centerRect.width - this.dialogRect.width
				return {
					centerElOffset,
					leftOffset: parseInt(left / 2),
				}
			}
			// transferTo and nothing set => center with css
			return null
		},
		leftPosEffective() {
			if (!this.leftPxRequested) return null
			if (!this.dialogRect) return null
			if (this.leftPxRequested == 0) return 0

			if (this.isPortalTransfer) {
				if (
					this.settings.center &&
					!this.doesItFit(
						this.dialogBgRect.width, // availabe width
						this.dialogRect.width, // dialog width
						this.leftPxRequested.centerElOffset,
						this.leftPxRequested.leftOffset,
					)
				) {
					//console.log("does not fit width");
					// center to bg instead of centerEl
					let margins = this.dialogBgRect.width - this.dialogRect.width
					// because of transform translate-y(-50%) add 1/2 of dialog width
					return parseInt(margins / 2) + parseInt(this.dialogRect.width / 2)
				} else if (this.leftPxRequested.centerElOffset < 0) {
					// center to bg instead of centerEl
					let margins = this.dialogBgRect.width - this.dialogRect.width
					// because of transform translate-y(-50%) add 1/2 of dialog width
					return parseInt(margins / 2) + parseInt(this.dialogRect.width / 2)
				} else {
					return (
						// because of transform translate-y(-50%) add 1/2 of dialog width
						this.leftPxRequested.centerElOffset +
						this.leftPxRequested.leftOffset +
						parseInt(this.dialogRect.width / 2)
					)
				}
			}

			return this.leftPxRequested.marginLeft + this.leftPxRequested.left
		},
		isPortalTransfer() {
			return this.settings.transferTo && this.settings.transferTo == 'portal-app'
		},
		// ---- applied style ----
		containerStyle() {
			//if (!this.mounted) return {}
			this.update

			let display = 'none'
			let transition = 'scale 0.2s ease-in, opacity 0.1s ease-in'
			let opacity = '1'
			let scale
			if (this.dialogAnimationStep == 'fadeInEnterFrom') {
				display = 'flex'
				scale = '0.8'
				opacity = '0'
			}
			if (this.dialogAnimationStep == 'fadeInEnter') {
				display = 'flex'
				scale = '1'
				opacity = '1'
			}
			if (this.dialogAnimationStep == 'fadeInEnd') {
				display = 'flex'
			}
			if (this.dialogAnimationStep == 'loader') {
				transition = 'none'
			}
			//console.log('containerStyle', this.dialogAnimationStep, opacity, scale, transition)

			if (this.fullScreen) {
				return {
					display,
					position: 'absolute',
					transform: 'none',
					width: '100%',
					height: '100%',
					top: 0,
					left: 0,
					// animation
					transition,
					scale,
					opacity
				}
			}

			return {
				display,
				// position
				...(this.settings.maxHeight
					? { maxHeight: this.settings.maxHeight }
					: this.isPortalTransfer
						? { maxHeight: this.getMaxHeight(), maxWidth: this.getMaxWidth() }
						: {}),
				// top position
				...(this.topPosEffective === null ? { top: '50%' } : { top: this.topPosEffective + 'px' }), // overwrite if topPos is set
				// left position
				...(this.leftPosEffective === null ? { left: '50%' } : { left: this.leftPosEffective + 'px' }), // overwrite if leftPos is set
				// width
				...(this.settings.width ? { width: this.settings.width } : {}),
				// animation
				transition,
				scale,
				opacity
			}


		},
		containerClass() {

			return {
				translateX: this.left ? false : true,
				translateY: this.isPortalTransfer || this.topPosEffective === null,
			}
		},
		loaderStyle() {
			return {
				...this.containerStyle,
				display: this.dialogAnimationStep == 'loader' ? 'flex' : 'none',
			}
		},
		settings() {
			return {
				transferTo: this.transferTo || null,
				top: this.top || null,
				left: this.left || null,
				maxHeight: this.maxHeight || null,
				maxWidth: this.maxWidth || null,
				width: this.width || null,
				center: this.center || null,
				scroll: this.scroll,
				close: this.close,
				closeBtn: this.closeBtn,
				outsideClick: this.outsideClick,
				padding: this.padding,
				header: this.header || null,
				closeOnEsc: true,
				...this.options,
			}
		},
		contentStyle() {
			if (this.settings.scroll) {
				return {
					padding: this.settings.padding,
					paddingRight: 0,
					paddingBottom: 0,
					paddingTop: 0,
				}
			}
			return {
				padding: this.settings.padding,
				paddingTop: 0,
			}
		},
		headerStyle() {
			return {
				paddingLeft: this.settings.padding,
				paddingRight: this.settings.padding,
			}
		},
		scrollStyle() {
			if (!this.mounted) return []
			this.settings.scroll
			this.settings.padding
			return {
				overflowY: this.settings.scroll && this.settings.scroll != 'scrollX',
				overflowX: this.settings.scroll && this.settings.scroll != 'scrollY',
				paddingRight: this.getPaddingRight(),
				paddingBottom: this.getPaddingBottom(),
			}
		},
	},
	watch: {
		loader() {
			if (!this.loader) {
				this.dialogScaleIn()
			}
		}
	},
	created() {
		this.dialogAnimationStep = this.loader ? 'loader' : 'fadeInEnterFrom'
		document.addEventListener('keydown', this.onDocumentKeyDown)
	},
	mounted() {
		setTimeout(() => {

			this.mounted = true
			if (!this.loader) {
				setTimeout(() => {
					this.dialogScaleIn()
				}, 100)
			}
			// add outsideClick listener
			this.$portal.$on('documentTouchClick', this.outsideClickFn)
			if (this.$refs.dialogBg) {
				new ResizeObserver(this.updatePos).observe(this.$refs.dialogBg)
			}

		}, 10)
	},
	beforeDestroy() {
		this.$portal.$off('documentTouchClick', this.outsideClickFn)
		document.removeEventListener('keydown', this.onDocumentKeyDown)
	},


}
</script>

<style scoped>
/* Background */
.Dialog {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 30;
}

.background {
	position: absolute;
	background-color: rgba(255, 255, 255, 0.4);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 30;
}

/* Background - animation */
.Dialog.animate-enter {
	opacity: 0;
}

.Dialog.animate-enter-active {
	transition: all 0.2s ease-in;
}

.Dialog.animate-enter-to {
	opacity: 1;
}

.Dialog.animate-leave {
	opacity: 1;
}

.Dialog.animate-leave-active {
	transition: all 0.2s ease-in-out;
	transition-delay: 0.1s;
	/*must be after transition property! */
}

.Dialog.animate-leave-to {
	opacity: 0;
}

/* Container */
.container {
	box-shadow: rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px;
	background-color: rgb(255, 255, 255);
	box-sizing: border-box;
	position: absolute;
	top: 50vh;
	left: 50vw;
	max-height: calc(100vh - 70px);
	min-height: 176px;
	min-width: 288px;
	max-width: calc(100vw - 70px);
	border-radius: 2px;
	outline: transparent solid 3px;
	z-index: 100;
	opacity: 1;
	display: flex;
	flex-direction: column;
}



.container.translateX {
	translate: -50%;
}


.container.translateY {
	translate: 0 -50%;
}


.container.translateY.translateX {
	translate: -50% -50%;
}


.Dialog.animate-leave-active .container {
	transition: all 0.3s ease-in-out;
}

.Dialog.blue .container {
	border-top: 4px solid var(--accentBlue);
}

.Dialog.red .container {
	border-top: 4px solid #e73838;
}

.Dialog.red:deep(.DialogHeader)>h6,
.Dialog.red .dialogHeader>h6 {
	color: #e73838;
}

.loader {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	max-height: calc(100vh - 70px);
}

.content {
	display: flex;
	flex-direction: column;
	max-height: 100%;
	max-width: 100%;
	min-height: 0;
	min-width: 0;
	position: relative;
	flex-grow: 1;
}

.content.scroll {
	padding-right: 0;
}

.content.scroll>div {
	overflow: auto;
	display: flex;
	flex-direction: column;
	max-height: 100%;
	max-width: 100%;
	min-height: 0;
	min-width: 0;
	padding-right: 24px;
}

.close {
	position: absolute;
	width: 30px;
	height: 30px;
	right: 23px;
	top: 10px;
	font-size: 17px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 2;
}

.close .Icon {
	margin-right: 0;
}

.close:hover {
	background-color: var(--activeHover);
}

.dialogHeader {
	margin: 10px 0;
	display: flex;
	position: relative;
	align-items: center;
	padding: 0 15px;
}

.dialogHeader>h6 {
	color: var(--accentBlue);
	margin: 0;
	flex-grow: 1;
}

.dialogHeader>.close {
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #323130;
	cursor: pointer;
	font-size: 15px;
	top: 1px;
	right: 9px;
}
</style>
