<template>
   <div class="InputItems"
      :class="{ open: showAddInput }">
      <div class="itemRow">
         <TransitionGroup name="fadeGroupInOut"
            mode="out-in"
            tag="div"
            class="items">
            <div v-for="(item, index) in items"
               class="item"
               :key="index"
               :style="itemStyle(item)"
               @click.stop="onItemClick(item)">
               <div v-if="item.color || itemColor"
                  class="color"
                  :style="{ backgroundColor: item.color || itemColor }"></div>
               <div class="name">
                  {{ itemDisplayName(item) }}
               </div>
               <div v-if="!readonly"
                  class="close">
                  <Icon class="close"
                     :icon="$static.iconClose"
                     @click.stop="onRemove(item, index)" />
               </div>
            </div>
         </TransitionGroup>
         <div v-if="!readonly"
            ref="addItem"
            class="addItem"
            :class="{ marginLeft: items.length > 0, readonly, expanded: showAddInput, open: searchResult.length > 0 }"
            :style="addItemStyle">
            <div class="wrap"
               :style="addItemWrapStyle">
               <transition name="fadeInOut">
                  <div v-if="showAddInput === false"
                     class="addIcon"
                     :style="{ fontSize: addItemIconFontSize }"
                     @click="onShowAddInput">
                     <Icon icon="iconAdd" />
                  </div>
               </transition>
               <transition name="fadeInOut">
                  <div v-if="showAddInput === true"
                     class="addInput"
                     :style="addInputStyle">
                     <Input ref="addInput"
                        class="textAlignRight basic noNotification noLabel"
                        :value="addValue"
                        :options="inputOptions"
                        v-model="addValue"
                        @input="onInput"
                        @arrowDown="onKeyNav('down')"
                        @arrowUp="onKeyNav('up')"
                        @enter="onEnter"
                        @esc="reset">
                     <template #before>
                        <div class="search">
                           <Icon :icon="$static.iconSearch" />
                        </div>
                     </template>
                     <template #after>
                        <div class="actions">
                           <div class="action">
                              <Icon class="close"
                                 :icon="$static.iconClose"
                                 @click="onCloseAddInput" />
                           </div>

                        </div>
                     </template>
                     </Input>
                  </div>
               </transition>
               <div v-if="showAddInput === true && searchResult.length > 0"
                  class="searchResultList">
                  <div v-for="(item, index) in searchResult"
                     class="item"
                     :class="{ active: index == activeListItem }"
                     :key="item[options.listItemKey]"
                     @click="select(item)">{{ toListItemText(item) }}</div>
               </div>
            </div>
            <div v-if="placeholder && items.length == 0"
               class="placeholder"
               key="placeholder">{{ placeholder }}</div>
         </div>
      </div>

   </div>
</template>

<script>
import Input from '@components/Form/Input.vue'
import Dropdown from '@components/Form/Dropdown.vue'
import Icon from '@icons/Icon.vue'
import { ChromeClose, Add, Accept, Search } from '@icons/appFabric/icons'
import GrowShrink from '@components/Containers/GrowShrink.vue'

import utils from '@SyoLab/utils'

export default {
   name: 'InputItems',
   components: { Input, Icon, Dropdown, GrowShrink },
   props: {
      index: { required: false, default: null },
      type: { required: false, default: 'input' },
      options: { required: false, default: () => ({}) },
      items: { required: false, default: () => [] },
      readonly: { required: false, default: false },
      // placeholder
      placeholder: { required: false, default: null },
      // addItem and Item
      bgColor: { required: false, default: 'white' }, // background color
      borderColor: { required: false, default: '#d9d9d9' }, // border color 
      color: { required: false, default: null }, // font color
      height: { required: false, default: '36px' }, // height of button an item
      fontSize: { required: false, default: '0.938rem', }, // font size of button an item
      // addItem only
      addItemWidth: { required: false },
      addItemGap: { required: false, default: '25px' }, // gap of assItem to items 
      addItemIconFontSize: { required: false, default: '11px' },
      addItemInputPlaceholder: { required: false, default: null },
      addItemBtnBorderColor: { required: false, },
      // item:
      itemColor: { required: false, default: null },
      itemBgColor: { required: false, },
      itemWidth: { required: false, },
      itemMinWidth: { required: false, default: '200px' },
      // search
      onAsyncSearch: { required: false, default: null, type: Function },
      onSelect: { required: false, default: null, type: Function },
      onDelete: { required: false, default: null, type: Function },
   },
   data() {
      return {
         addValue: null,
         showAddInput: false,
         addItemOverflowHidden: false,
         hideAddItem: false,
         searchText: null,
         searchResult: [],
         activeListItem: null,
      }
   },
   static: {
      iconClose: ChromeClose,
      iconAdd: Add,
      iconCheck: Accept,
      iconSearch: Search,
   },
   methods: {
      onInput(value) {
         this.searchText = value
         if (this.onAsyncSearch) {
            this.throttledSearch()
            return
         }
         this.$emit('search', value)
      },
      onShowAddInput() {
         if (this.readonly) return
         this.toggleShowAddInput()
         setTimeout(() => {
            this.$refs.addInput.focus()
            this.onInput('')
         }, 200);
      },
      onCloseAddInput() {
         this.toggleShowAddInput()
         this.addValue = null
         this.searchResult = []
         this.$emit('close')
      },
      toggleShowAddInput() {
         this.addItemOverflowHidden = true
         setTimeout(() => {
            this.showAddInput = !this.showAddInput
            setTimeout(() => {
               this.addItemOverflowHidden = false
            }, 200);
         }, 1);
      },
      onAdd() {
         if (!this.addValue) {
            this.onCloseAddInput()
            return
         }
         this.showAddInput = null
         setTimeout(() => {
            this.$emit('add', this.addValue)
            this.addValue = null
            setTimeout(() => {
               this.showAddInput = false
            }, 150);
         }, 200);
      },
      onRemove(item, index) {
         if (this.onDelete) {
            let res = this.onDelete(item, this, index)
            if (res) {
               this.items.splice(index, 1)
               this.$emit('update')
               return
            }
            return
         }
         setTimeout(() => {
            this.items.splice(index, 1)
            this.$emit('delete', item, index)
         }, 1);

      },
      outsideClickFn(e) {
         if (!this.showAddInput) return
         if (this.$refs.addItem && this.$refs.addItem.contains(e.target)) return
         this.onCloseAddInput()
      },
      select(item) {

         setTimeout(() => {
            this.hideAddItem = true
            this.showAddInput = null
            this.addValue = null
            this.searchResult = []
         }, 1);

         if (this.onSelect) {
            let res = this.onSelect(item, this.index)
            if (res) {
               this.items.push(res)
               this.$emit('update')
            }
         } else {
            this.$emit('select', item)
         }


         setTimeout(() => {
            this.hideAddItem = false
            setTimeout(() => {
               this.showAddInput = false
            }, 1);
         }, 250);
      },
      itemDisplayName(item) {
         if (item[this.options.listItemValueKey]) return item[this.options.listItemValueKey]
         if (item[this.options.listItemKey]) return item[this.options.listItemKey]
         return item
      },
      reset() {
         this.searchText = null
         this.searchResult = []
         this.addValue = null
         this.activeListItem = null
         this.onCloseAddInput()
      },
      onKeyNav(dir) {
         if (dir == 'up') {
            if (this.activeListItem === null) {
               this.activeListItem = this.searchResult.length - 1
               return
            }
            this.activeListItem -= 1
            if (this.activeListItem < 0) this.activeListItem = this.searchResult.length - 1
         }
         if (dir == 'down') {
            if (this.activeListItem === null) {
               this.activeListItem = 0
               return
            }
            this.activeListItem += 1
            if (this.activeListItem > this.activeListItem.length - 1) {
               this.activeListItem = 0
            }
         }
      },
      onEnter() {
         if (this.searchResult.length == 0) return
         if (this.activeListItem !== null) {
            this.select(this.searchResult[this.activeListItem])
         } else {
            this.select(this.searchResult[this.searchResult.length - 1])
         }
         this.reset()
      },
      throttledSearch: utils.throttleAsync(async function () {
         let res = await this.onAsyncSearch(this.searchText, this.index)
         if (!this.showAddInput) return
         this.searchResult = res
      }),
      itemStyle(item) {
         return {
            height: this.height,
            fontSize: this.fontSize,
            width: this.itemWidth || null,
            minWidth: this.itemMinWidth || null,
            backgroundColor: item.active ? '#d7e9fb' : this.itemBgColor,
            color: this.color,
            borderColor: this.borderColor,
         }
      },
      onItemClick(item) {
         if (this.readonly) return
         item.active = !item.active
         this.$emit('itemclick', item)
         this.$emit('update')
      },
      toListItemText(item) {
         return item[this.options.listItemValueKey] || item[this.$locale] || item.name
      }
   },
   computed: {
      addItemStyle() {
         let width = this.height
         if (this.showAddInput) {
            width = this.addItemWidth || this.itemWidth || this.itemMinWidth
         }
         let marginLeft = this.addItemGap
         if (!this.placeholder && this.items.length == 0) {
            // no placeholder and no items
            marginLeft = 0
         }
         let borderColor = this.borderColor
         if (this.addItemBtnBorderColor && !this.showAddInput) {
            borderColor = this.addItemBtnBorderColor
         }

         return {
            width: width,
            height: this.height,
            overflow: this.addItemOverflowHidden ? 'hidden' : 'visible',
            display: this.hideAddItem ? 'none' : '',
            borderColor,
            color: this.color,
            fontSize: this.fontSize,
            marginLeft,
         }
      },
      addItemWrapStyle() {
         let borderRadius = parseInt(this.height) / 2
         borderRadius = borderRadius + 'px'
         return {
            backgroundColor: this.bgColor,
            borderRadius,
         }
      },
      addInputStyle() {
         return {
            fontSize: this.fontSize,
         }
      },
      inputOptions() {
         return {
            fontSize: this.fontSize,
            placeholder: this.addItemInputPlaceholder,
         }
      }
   },
   mounted() {
      setTimeout(() => {
         this.$portal.$on('documentTouchClick', this.outsideClickFn)
      }, 100);
      this.$emit('update')
   },
   beforeDestroy() {
      this.$portal.$off('documentTouchClick', this.outsideClickFn)
   }
}
</script>

<style scoped>
.InputItems {
   display: flex;
   align-items: center;
   z-index: 1;
}

.InputItems.open {
   z-index: 5;
}

.InputItems.wrap .items {
   flex-wrap: wrap;
}

.itemRow {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   row-gap: 7px;
}

.items {
   display: flex;
   column-gap: 25px;
}

.items .close {
   display: flex;
   align-items: center;
   justify-content: center;
   padding-left: 5px;
   font-size: 11px;
   cursor: pointer;
   color: black;
   flex: none;
}

.items .color {
   width: 22px;
   height: 22px;
   border-radius: 50%;
   margin-right: 10px;
   flex: none;
}

.itemRow .items .item {
   height: 36px;
   display: flex;
   border-radius: 19px;
   align-items: center;
   padding: 0 10px;
   font-size: 15px;
   border-style: solid;
   border-width: 1px;
   cursor: pointer;
}

.itemRow .items .item .name {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   flex-grow: 1;

}

.placeholder {
   display: flex;
   align-items: center;
   padding-right: 15px;
   font-size: 15px;
   color: #9d9d9d;
   white-space: nowrap;
   position: absolute;
   top: 0;
   left: 43px;
   height: 100%;
}

.addItem {
   position: relative;
   transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
   z-index: 1;
}

.addItem.expanded {
   border-radius: 19px;
}

.addItem .wrap {
   position: absolute;
   top: 0;
   left: 0;
   min-height: 100%;
   width: 100%;
   border-width: 1px;
   border-style: solid;
   border-radius: 19px;
   border-color: inherit;
   overflow: hidden;
   z-index: 1;
   display: flex;
   flex-direction: column;
}

.addItem .addIcon {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   width: 100%;
   font-size: 11px;
   cursor: pointer;
   position: absolute;
   color: black;
}

.addItem.readonly .add {
   cursor: default;
   background-color: whitesmoke;
}


.addItem .add .Icon {
   font-size: 11px;
   color: #323232;
}

.addItem .addInput {
   display: flex;
   align-items: center;
   flex-grow: 1;
   padding: 0 5px;
}

/* .addItem.open .addInput {
   top: 8px;

} */

.addInput .Input {
   width: 100%
}

.addItem .actions {
   display: flex;
   padding-left: 4px;
   column-gap: 3px;
}

.addItem .action {
   display: flex;
   padding: 5px;
   cursor: pointer;
}

.addItem .action:hover {
   background-color: var(--activeHover);
}

.addItem .Icon.close {
   font-size: 11px;
}

.addItem .Icon.check {
   font-size: 14px;
   color: green;
}

.searchResultList {
   display: flex;
   flex-direction: column;
   width: 100%;
   padding: 5px 0;
   border-top: 1px solid #d9d9d9;
   background-color: white;
}

.searchResultList .item {
   display: flex;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
   padding: 2px 5px;
   cursor: pointer;
}

.searchResultList .item:hover {
   background-color: rgba(0, 0, 0, 0.04);

}

.searchResultList .item.active {
   background-color: rgba(0, 0, 0, 0.04);
}
</style>