<template>
	<transition name="slide">
		<div v-if="show"
			class="menuItemsWrapper"
			:class="wrapperClass">
			<div class="menuItem"
				:class="menuItemClass"
				@click="onMenuItemClick(menuItem)"
				@mouseenter="onMousenter"
				@mouseleave="onMouseleave">
				<div v-if="menuItem.icon"
					class="iconSlot"
					:class="menuItem.icon.class">
					<Icon :icon="menuItem.icon" />
				</div>
				<div v-if="!menuItem.icon"
					class="iconSlot iconDot">
					<Icon icon="iconDot" />
				</div>
				<div class="text">
					<span>{{ menuItem.text }}</span>
					<div class="iconOpen">
						<Icon v-if="menuItem.items"
							icon="iconOpen" />
					</div>
				</div>
			</div>

			<div class="itemsGroup"
				v-if="menuItem.items">
				<MenuItem v-for="(item, index) in menuItem.items"
					:key="index"
					:menu-item="item"
					:level="level + 1"
					:parent-path="path"
					@menuClick="menuClick(item)"
					@toggleMenuChildren="toggleMenuChildren"
					@show="onChildrenShow" />
			</div>
		</div>
	</transition>
</template>

<script type="text/javascript">
import eventBus from './eventBus'
import Icon from '@icons/Icon.vue'
import { ChevronDown, LocationDot } from '@icons/appFabric/icons'
import { delay, delayClear } from './utils'
export default {
	name: 'MenuItem',
	components: {
		Icon,
	},
	props: {
		menuItem: {
			type: Object,
			required: true,
		},
		parentPath: {
			type: String,
			required: false,
			default: '',
		},
		level: {
			type: Number,
			required: true,
		},
	},
	data: function () {
		return {
			mouseOverMenuItem: false,
			mouseOverWrapper: false,
		}
	},
	static: {
		iconOpen: ChevronDown,
		iconDot: LocationDot,
	},
	methods: {
		onMenuItemClick: function (item) {
			if (this.menuItem.items) {
				this.$emit('toggleMenuChildren', this.menuItem)
			} else {
				this.$emit('click', item)
				eventBus.emit('menu.click', item)
			}
		},
		//routes toggleMenuClick to top
		toggleMenuChildren: function (data) {
			this.$emit('toggleMenuChildren', data)
		},
		onMousenter: function () {
			this.mouseOverMenuItem = true
			//console.log("mousenter", this.menuItem._id);
		},
		onMouseleave: function (e) {
			this.mouseOverMenuItem = false
			//console.log("mouseleave", this.menuItem._id, e);
			this.$emit('mouseleave', this.menuItem._id)
		},
		onExpandChildren() {
			this.$emit('toggleShow')
		},
		onChildrenShow() {
			this.menuItem.items.forEach(item => {
				item.show = true
			})
		},
	},
	computed: {
		show() {
			if (typeof this.menuItem.show == 'function') {
				let userConfig = this.$portal.userApps[this.menuItem.appName]
				let portalApp = this.$portal.portalApps[this.menuItem.appName]
				return this.menuItem.show(userConfig, portalApp)
			}
			return this.menuItem.show
		},
		wrapperClass() {
			return {
				isGroup: this.menuItem.items ? true : false,
				[` level${this.level}`]: true,
				mouseover: this.mouseOverWrapper,
			}
		},
		menuItemClass: function () {
			return {
				active: this.menuItem.active,
				mouseover: this.mouseOverMenuItem,
			}
		},
		path: function () {
			if (this.parentPath.length > 0) {
				return this.parentPath + '.' + this.menuItem._id
			}
			return this.menuItem._id
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: function () {
				this.menuItem.active = this.$route.name == this.menuItem._id
				if (this.menuItem.active) this.$emit('show')
			},
		},
	},
}
</script>

<style scoped>
.menuItem {
	height: 45px;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	color: #323130;
}

/* .menuItemsWrapper.level0 .menuItem {
	width: 200px;
}
.menuItemsWrapper.level1 .menuItem {
	width: 190px;
}
.menuItemsWrapper.level2 .menuItem {
	width: 180px;
} */
.iconSlot {
	height: 100%;
	width: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: width 0.5s;
	transition-timing-function: ease;
	font-size: 18px;
}

.iconSlot.iconDot {
	width: 30px;
	margin-left: 5px;
}

.text {
	height: 100%;
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: 0 10px 0 0;
}

.text>span {
	flex-grow: 1;
	text-align: left;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.menuItemsWrapper {
	width: 100%;
	overflow: hidden;
	/* transition: width 0.3s ease-in-out; */
	display: flex;
	flex-direction: column;
	background-color: inherit;
	z-index: 10;
}

.itemsGroup {
	padding-left: 10px;
}

.iconOpen {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding-right: 8px;
}

/* .appMenu.collapsed .menuItemsWrapper.mouseover,
								.appMenu.collapsed .menuItemsWrapper.mouseover .menuItemsWrapper {
									width: 200px;
								} */

/* menuItems */
.menuItem:hover,
.menuItem.active {
	background-color: #c9dbec;
}

.menuItemsGroup.active:after {
	content: '';
	background-color: #2185d0;
}

.slide-enter-active {
	transition: max-height 0.5s ease-in-out;
}

.slide-leave-active {
	transition: max-height 0.5s ease-in-out;
}

.slide-enter-to,
.slide-leave {
	max-height: 100px;
	overflow: hidden;
}

.slide-enter,
.slide-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>
