<template>
   <div class="Color">
      <div :style="{ ...style }"></div>
   </div>
</template>

<script>
export default {
   name: 'Color',
   components: {},
   props: {
      record: { required: true },
      column: { required: true },
   },
   data() {
      return {}
   },
   methods: {},
   computed: {
      style() {
         let backgroundColor = null
         if (this.record.status == 'new') {
            backgroundColor = '#FF7574'
         } else if (this.record.status == 'wait') {
            backgroundColor = '#F4F6F8'
         } else if (this.record.status == 'wip') {
            backgroundColor = '#66CCFF'
         } else if (this.record.status == 'done') {
            backgroundColor = '#469E9C'
         } else if (this.record.status == 'billed') {
            backgroundColor = '#442a1e'
         } else if (this.record.status == 'paid') {
            backgroundColor = '#442a1e'
         } else if (this.record.status == 'notBillable') {
            backgroundColor = '#f7f752'
         }
         return {
            backgroundColor
         }
      }
   },
}
</script>

<style scoped>
.Color {
   display: flex;
   align-items: center;
   justify-content: center;
}

.Color div {
   width: 16px;
   height: 16px;
}
</style>