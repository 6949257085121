<template>
   <div class="BchSubscriptionGridRow"
      :style="{ gridRow: row + 1 }"
      @click="$emit('click', service)">
      <div class="borderLeft"
         :style="borderLeftStyle"></div>
      <div class="taskName"
         :style="{ gridRow: row + 1 }">{{ service.name }}</div>
      <div class="middle"
         :style="{ gridRow: row + 1 }">
         <div class="subscriptionInfo">
            {{ serviceStartDate }} - {{ serviceEndDate }}
         </div>
      </div>
      <div class="status"
         :style="{ gridRow: row + 1 }">
         <div class="billingTotal">
            {{ serviceValue }}
         </div>
      </div>
      <div class="action"
         :style="{ gridRow: row + 1 }"
         @click.stop>
         <CheckBox v-if="isSelectable"
            class="small"
            :value="isSelected"
            @input="$emit('select', service, $event)" />
         <ServiceGridBillIcon v-else-if="service.billNo"
            :config="config"
            :service="service" />
         <MenuMore v-else-if="menuMoreItems.length > 0"
            orientation="right">
            <div v-for="(menuItem, index) in menuMoreItems"
               :key="index"
               class="menuItem"
               @click="onMenuItemClick(menuItem)">
               <Icon :class="menuItem.icon"
                  :icon="$static[menuItem.icon]" />
               <span>{{ menuItem.text }}</span>
            </div>
         </MenuMore>
      </div>
   </div>
</template>

<script>
import Icon from "@icons/Icon.vue"
import { Bill, Delete, Refresh } from "@icons/appFabric/icons"
import CheckBox from '@components/Form/CheckBox.vue';
import InputItems from '../components/InputItems.vue';
import { Day } from '@SyoLab/date-time'
import ServiceGridBillIcon from "../components/ServiceGridBillIcon.vue"
import MenuMore from "@components/Navigation/MenuMore.vue";
export default {
   name: 'BchSubscriptionGridRow',
   components: {
      CheckBox,
      InputItems,
      Icon,
      ServiceGridBillIcon,
      MenuMore
   },
   props: {
      service: { required: true },
      row: { required: true },
      config: { required: true },
      selectable: { required: false, default: false },
      selectedCustomer_id: { required: false, default: false },
      selectedServices: { required: false, default: false },
      readonly: { required: false, default: false },
   },
   data() {
      return {}
   },
   static: {
      iconBill: Bill,
      iconDelete: Delete,
      iconReload: Refresh
   },
   methods: {
      onMenuItemClick(menuItem) {
         if (menuItem.operation == 'delete') {
            this.$emit('delete')
         }
         if (menuItem.operation == 'move') {
            this.$emit('move', menuItem.move)
         }
         if (menuItem.operation == 'bchReload') {
            this.$emit('bchReload', this.service.bchOrder.id)
         }
      }
   },
   computed: {
      borderLeftStyle() {
         let color = this.service.color || this.config.defaultServiceColor
         return {
            gridRow: this.row + 1,
            backgroundColor: color,
            borderTopColor: color,
            borderBottomColor: color,
         }
      },
      isSelectable() {
         if (this.isReadonly) return false
         if (!this.selectable) return false
         if (this.selectedCustomer_id && this.selectedCustomer_id != this.service.customer_id) return false
         return true
      },
      isSelected() {
         return this.selectedServices.includes(this.service._id) ? true : false
      },
      isReadonly() {
         if (this.readonly) return true
         if (this.service.billNo) return true
         if (this.service.status == 'billed') return true
         if (this.service.status == 'payed') return true
         return false
      },
      serviceValue() {
         let value = this.service.value.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
         return `CHF ${value}`
      },
      serviceStartDate() {
         return new Day(this.service.startDate).toEuDate()
      },
      serviceEndDate() {
         return new Day(this.service.endDate).toEuDate()
      },
      menuMoreItems() {
         if (this.isReadonly) return []

         let menuItems = [
            { icon: 'iconReload', text: 'aktualisieren', operation: 'bchReload' }
         ]
         return menuItems
      },

   },
}
</script>

<style scoped>
.BchSubscriptionGridRow {
   display: grid;
   grid-column: 1/-1;
   grid-template-columns: subgrid;
   grid-template-rows: subgrid;
   cursor: pointer;
   background-color: var(--rowBgColor);
}

.BchSubscriptionGridRow>* {
   display: flex;
   align-items: center;
   height: var(--rowHeight);
   padding: 3px 0;
   border-top: 1px solid var(--borderColor);
   border-bottom: 1px solid var(--borderColor);
}

.BchSubscriptionGridRow>*:last-child {
   border-right: 1px solid var(--borderColor);
   ;
}

.borderLeft {
   grid-column: borderLeft;
   background-color: var(--borderLeftColor);
   border-top-color: var(--borderLeftColor);
   border-bottom-color: var(--borderLeftColor);
}

.taskName {
   grid-column: left;
   padding-left: 10px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.select {
   grid-column: select;
   padding-left: 3px;
}

.middle {
   grid-column: middle;
}

.subscriptionInfo {
   padding-right: 10px;
   text-align: right;
   width: 100%;
}

.status {
   grid-column: right;
   display: flex;
   align-items: center;
   padding: 0;
   border: 0;
}

.billingTotal {
   padding-right: 10px;
   text-align: right;
   width: 100%;
}

.action {
   grid-column: action;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: default;
}
</style>