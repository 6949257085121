
import utils from "@SyoLab/utils"
import { AccountManagement, HomeGroup, Clock, Up } from "../../@icons/appFabric/icons"
//const defaultApp = () => import(/* webpackChunkName: "DefaultApp" */"./Default.vue")
import component from "./Default.vue"
const appName = 'default'
const basePath = "/timeTestAdmin"
export default {
    name: appName,
    auth: true, //auth: if authenticated must have app in userApps
    menuShow: false, //menuShow overrides everything
    menuPosition: 1, //sorting menu position of app
    component: component,
    // appConfig from base -> available in Component.appConfig
    appConfig: {
        foo: 'foo'
    },
    // routes are used to match all menuItem routes
    // the are referenced to the app
    routes: [
        {
            path: "/default"
        }
    ],
    menu: [
        {
            //"appName": appName, -> not needed appName is referenced on import
            "text": "TimeTest Admin",
            "icon": HomeGroup,
            "class": "defClass root",
            "route": null, //route path
            "action": undefined, //action emitted to app
            "enabled": true, // enabled / disable menu item (can be used as function with appConfig)
            // status
            "active": false, // is active menu item
            "show": true, // show / hide, toggles visibility on click
            items: [
                {
                    "appName": 'timeTestAdmin', // here you can optional reference a different appName
                    "text": "Time",
                    "icon": Clock,
                    "class": "defClass",
                    "route": `${basePath}/time`,
                    "action": undefined,
                    "enabled": true, // enabled / disable menu item (can be used as function with appConfig)
                    // status
                    "active": false,
                    "show": false, // initial false
                },
                {
                    "appName": 'timeUploads', // here you can optional reference a different appName
                    "text": "Upload",
                    "icon": Up,
                    "class": "defClass",
                    "route": `${basePath}/upload`,
                    "action": undefined,
                    "enabled": true, // enabled / disable menu item (can be used as function with appConfig)
                    // status
                    "active": false,
                    "show": false, // initial false
                    // appConfig from active Menu, overrides baseAppConfig
                    "appConfig": {
                        foo: 'bar',
                    }
                }
            ]
        }
    ]
}