<template>
	<div id="browserNotSupported">
		<div class="error">
			<div class="rip" v-if="isRip">
				<img class="ripImage" src="@/assets/portal/RIP.png" />
				<img class="ie" src="@/assets/portal/IE.svg" />
			</div>
			<div class="err" v-else>
				<img class="errImg" src="@/assets/portal/NotSupportedSmiley.svg" />
			</div>
			<div class="browser">
				Sorry, your Browser {{ browser.name }} Version {{ browser.version }} is not supported
			</div>
			<div class="upgrade">Please use a current browser like Chrome or Microsoft Edge</div>
		</div>
	</div>
</template>

<script>
import Bowser from "bowser";
const browser = Bowser.getParser(window.navigator.userAgent);
export default {
  name: "BrowserNotSupported",
  props: {
    browser: {
      required: true,
    },
    browserSupport: {
      required: true,
    },
  },
  computed: {
    isRip() {
      if (this.browserSupport.rip) {
        return browser.satisfies(this.browserSupport.rip);
      }
      return false;
    },
  },
};
</script>

<style scoped>
	#browserNotSupported {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.error {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.rip {
		position: relative;
	}
	.ripImage {
		height: 200px;
	}
	.errImg {
		height: 115px;
		margin-bottom: 39px;
	}
	.ie {
		width: 58px;
		height: 58px;
		position: absolute;
		top: 32px;
		left: 50%;
		transform: translateX(-58%);
	}
</style>