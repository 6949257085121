<template>
   <div class="ServiceAdd">
      <Dropdown v-if="!customer_id"
         ref="customerSearch"
         class="customerSearch"
         label="Kunde"
         placeholder="Suche"
         :options="customerDropDownOptions"
         :autocomplete="onCustomerSearch"
         @input="onCustomerSelect"
         @autocomplete="searchString = $event">
         <template #before>
            <Icon class="iconSearch"
               :icon="$static.iconSearch" />
         </template>
      </Dropdown>
      <Dropdown v-if="(selectedCustomer?._id || customer_id) && !project_id"
         ref="projectSearch"
         class="customerSearch"
         label="Projekt"
         placeholder="Suche"
         :options="projectDropDownOptions"
         :autocomplete="onProjectSearch"
         @input="onProjectSelect"
         @autocomplete="searchString = $event"
         @enter="onProjectEnter">
         <template #before>
            <Icon class="iconSearch"
               :icon="$static.iconSearch" />
         </template>
      </Dropdown>
      <div class="row center">
         <Button :enabled="allowSave"
            class="blue"
            @click="onAddTask">Speichern</Button>
      </div>
   </div>
</template>

<script>
import Dropdown from '@components/Form/Dropdown.vue'
import Input from '@components/Form/Input.vue'
import Icon from "@icons/Icon.vue"
import Button from '@components/Form/Button.vue'
import Textarea from '@components/Form/Textarea.vue'
import { Search, Group, Add } from "@icons/appFabric/icons"
import api from "@components/Portal/api.js"

export default {
   name: 'ServiceAdd',
   components: {
      Dropdown,
      Icon,
      Input,
      Button,
      Textarea
   },
   props: {
      customer_id: { required: false },
      customerName: { required: false },
      project_id: { required: false },
      projectName: { required: false },
   },
   data() {
      return {
         searchString: null,
         selectedCustomer: null,
         selectedProject: null,
      }
   },
   static: {
      iconSearch: Search,
   },
   methods: {
      async onCustomerSearch(searchString) {
         if (!searchString) {
            return []
         }
         return await api.get(`customer/customerSearch/${searchString}`)
      },
      onCustomerSelect(selectedItem) {
         this.selectedCustomer = selectedItem
         setTimeout(() => {
            this.$refs.projectSearch.focus()
         }, 200);
      },
      async onProjectSearch(searchString) {
         if (!searchString) {
            return []
         }
         let customer_id = this.selectedCustomer?.id || this.customer_id
         return await api.get(`customer/projectSearch/${customer_id}/${searchString}`)
      },
      onProjectSelect(selectedItem) {
         this.selectedProject = selectedItem
         this.onAddTask()
      },
      onProjectEnter(value) {
         // if enter was pressed and no project was selected
         if (!value) this.onAddTask()
      },
      onAddTask() {
         if (!this.customer_id && !this.selectedCustomer?._id) return
         this.$emit('addService', {
            customer_id: this.customer_id || this.selectedCustomer._id,
            customerName: this.customerName || this.selectedCustomer.name,
            project_id: this.project_id || this.selectedProject?.project_id || null,
            projectName: this.projectName || this.selectedProject?.projectName || null,
            settings: {
               ...this.selectedCustomer?.serviceSettings || {},
               ...this.selectedProject?.settings || {},
            }
         })
      },
   },
   computed: {
      allowSave() {
         if (!this.customer_id && !this.selectedCustomer?._id) return false
         return true
      },
      customerDropDownOptions() {
         return {
            autocomplete: true,
            listItemKey: '_id',
            listItemValueKey: 'nameCity',
            returnListItem: true,
            autocompleteKeys: true,
            triangle: false
         }
      },
      projectDropDownOptions() {
         return {
            autocomplete: true,
            listItemKey: 'project_id',
            listItemValueKey: 'projectName',
            returnListItem: true,
            autocompleteKeys: true,
            triangle: false
         }
      }
   },
   mounted() {
      if (!this.customer_id) {
         this.$refs.customerSearch.focus()
         return
      }
      if (!this.project_id) {
         this.$refs.projectSearch.focus()
      }
   }
}
</script>

<style scoped>
.ServiceAdd {
   display: flex;
   flex-direction: column;
   row-gap: 35px;
   width: 350px;
   padding-top: 25px;
   padding-bottom: 20px;
}

.Dropdown,
.Input {
   width: 100%;
}

.row.center {
   justify-content: center;
}

.billingText {
   display: flex;
   flex-direction: column;
   row-gap: 10px;
   position: relative;
}

.billingText .Textarea {
   width: 100%;
   position: relative;
}

.billingText::after {
   content: ' ';
   position: absolute;
   bottom: -9px;
   left: 0;
   width: 100%;
   height: 1px;
   border-bottom: 1px solid #619cca;
}

.iconSearch {
   font-size: 14px;
   margin-top: 3px;
}
</style>