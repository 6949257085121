<template>
   <div class="NextContacts">
      <div class="filter">
         <Dropdown placeholder="Mitarbeiter"
            width="200px"
            :listItems="contactsList"
            :options="{ listItemKey: 'userId', listItemValueKey: 'userName', emptyListItem: true, clearable: true }"
            v-model="filterByUserId" />
         <Switcher class="material"
            v-model="showAll">alle anzeigen</Switcher>
      </div>
      <Grid :gridColumns="gridColumns"
         :data="gridRecords"
         @recordClick="onRecordClick"
         @nextContactDone="onNextContactDone" />
      <Dialog v-if="showDialog"
         header="Status ändern"
         top="150px "
         left="250px"
         closeBtn="true"
         @close="onDialogClose"
         @outsideClick="onDialogClose">
         <div class="dlgBody">
            <MessageEditor ref="MessageEditor"
               :focus="true"
               :customer="customer"
               :contactIdPreset="contactId"
               @send="onCommentSend" />
            <div class="action">
               <Button class="blue"
                  @click="onSetStatus">Status erledigt</Button>
            </div>
         </div>
      </Dialog>
   </div>
</template>

<script>
import api from '@components/Portal/api'
import Grid from '../dataGrid/Grid.vue'
import Dropdown from '@components/Form/Dropdown.vue';
import DatePicker from '@components/Form/DatePicker.vue';
import Switcher from '@components/Form/Switcher.vue';
import { Day } from '@SyoLab/date-time'
import NextContactDone from './NextContactDone.vue'
import Dialog from '@components/Dialog/Dialog.vue';
import Textarea from '@components/Form/Textarea.vue';
import Icon from "@icons/Icon.vue"
import { Comment, Send, Delete, Edit } from "@icons/appFabric/icons"
import MessageEditor from '../../customer/MessageEditor.vue';
import Button from '@components/Form/Button.vue'
import eventBus from '@components/Portal/eventBus';
import portalStateP from '@components/Portal/portalState';
const portalState = portalStateP()
export default {
   name: 'NextContacts',
   components: { Grid, Dropdown, Switcher, NextContactDone, Dialog, Textarea, Icon, DatePicker, MessageEditor, Button },
   props: {},
   data() {
      return {
         showAll: true,
         filterByUserId: null,
         records: [],
         record: null,
         showDialog: false,
         customer: null,
         contactId: null,
         firstLoad: true
      }
   },
   static: {
      iconSend: Send,
   },
   methods: {
      async getData() {
         let records = await api.post('contacts/customerCommunication', { nextContact: true })
         records.sort((a, b) => {
            if (a.nextContactDate > b.nextContactDate) return 1
            if (a.nextContactDate < b.nextContactDate) return -1
            return 0
         })
         this.records = records

         if (this.firstLoad) {
            let sessionConfig = sessionStorage.getItem("NextContacts")
            if (sessionConfig) {
               sessionConfig = JSON.parse(sessionConfig)
               this.filterByUserId = sessionConfig.filterByUserId
            } else {
               this.filterByUserId = portalState.userConfig.userId
            }
            this.firstLoad = false
         }

      },
      onRecordClick(record) {
         this.$router.push(`/customer/${record.customer_id}`)
      },
      async onNextContactDone(data) {
         let record = this.records.find(r => r._id == data._id)

         if (!record) {
            eventBus.emit('notify.warning', 'Eintrag nicht gefunden', { timeout: 5000 })
            return
         }

         if (!data.value) {
            record.nextContactDone = false
            api.delete(`contacts/customerCommunication/nextContact/${this.record._id}/done`)
            return
         }

         let customer = await api.get(`contacts/customer/${record.customer_id}`)
         if (!customer) {
            eventBus.emit('notify.warning', 'Kunde nicht gefunden', { timeout: 5000 })
            return
         }

         this.record = record
         this.contactId = data.contactId
         this.customer = customer
         this.showDialog = true
      },
      onDialogClose() {
         this.record = null
         this.contactId = null
         this.customer = null
         this.showDialog = false
      },
      onTextareaEnter(e) {
         if (e.shiftKey) {
            return
         }
         e.preventDefault()
         this.onDialogClose()
      },
      onCommentSend(comment) {
         api.post('contacts/customerCommunication/comment', comment)

      },
      onSetStatus() {
         if (this.$refs.MessageEditor.comment) {
            this.$refs.MessageEditor.onCommentSend()
         }

         this.record.nextContactDone = true
         api.put(`contacts/customerCommunication/nextContact/${this.record._id}/done`)
         this.onDialogClose()

      }

   },
   computed: {
      gridColumns() {
         return [
            {
               name: 'nextContactDate', grid: 'nextContactDate', text: "Nächster Kontakt", width: 'max-content', emit: 'recordClick',
               value(record, column) {
                  return record.nextContactDate ? new Day(record.nextContactDate).toEuDate() : null
               },
               style(record, column) {
                  if (record.nextContactDate < new Day().date) return { color: 'red' }
                  return {}
               }
            },
            { name: 'customerName', grid: 'name', text: "Kunde", width: 'max-content', emit: 'recordClick' },
            { name: 'contactName', grid: 'contactName', text: "Kontakt", width: 'max-content', emit: 'recordClick' },

            {
               name: 'nextContactDone', grid: 'nextContactDone', text: "Erledigt", width: 'max-content',
               component: NextContactDone, class: 'center'
            },

            { name: 'userName', grid: 'userName', text: "Mitarbeiter", width: 'max-content', emit: 'recordClick' },
            {
               name: 'date', grid: 'date', text: "Datum", width: 'max-content', emit: 'recordClick',
               value(record, column) {
                  return record.date ? new Day(record.date).toEuDate() : null
               }
            },
            { name: 'comment', grid: 'comment', text: "Kommentar", width: '600px', emit: 'recordClick', style: { "white-space": "unset" } },
         ]
      },
      gridRecords() {
         if (this.showAll && !this.filterByUserId) return this.records
         let d = new Day().endOf('week').date
         return this.records.filter(record => {
            if (!this.showAll && record.nextContactDate > d) return false
            if (this.filterByUserId && record.userId != this.filterByUserId) return false
            return true
         })


      },
      contactsList() {
         let userMap = {}
         this.records.forEach(record => {
            if (!userMap[record.userId]) {
               userMap[record.userId] = {
                  userId: record.userId,
                  userName: record.userName,
               }
            }
         })
         return Object.values(userMap)
      },
      contactDropdownListItems() {
         return this.customer.contacts.filter(item => item.name)
      },
      datePickerOptions() {
         return {
            format: 'toIsoDate',
            max: new Day().date
         }
      }

   },
   created() {
      this.getData()
   },
   beforeDestroy() {
      sessionStorage.setItem("NextContacts", JSON.stringify({
         filterByUserId: this.filterByUserId,
      }))
   },
}
</script>

<style scoped>
.NextContacts {}

.filter {
   display: flex;
   column-gap: 35px;
   align-items: center;
}

.dlgBody {
   display: flex;
   flex-direction: column;
   justify-content: center;
   flex-grow: 1;
   width: 650px;
   padding-top: 25px;
}

.action {
   display: flex;
   justify-content: center;
   padding-top: 25px;
}
</style>