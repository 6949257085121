<template>
   <div class="MessageEditor">
      <div class="paramsRow">
         <div class="items">
            <div class="item">
               <DatePicker class="basic"
                  displayFormat="toEuDate"
                  :options="datePickerOptions"
                  v-model="date" />
            </div>
            <div class="right">
               <div class="item">
                  <Dropdown class="slim"
                     width="250px"
                     placeholder="Kundenkontakt"
                     :options="contactDropdownOptions"
                     v-model="contactId" />
               </div>
               <div class="item">
                  <DatePicker class="basic"
                     width="175px"
                     placeholder="nächster Kontakt"
                     displayFormat="toEuDate"
                     :options="{ clearable: true, format: 'toIsoDate' }"
                     v-model="nextContactDate" />
               </div>
            </div>
         </div>
      </div>
      <div class="textarea new">
         <Textarea ref="Textarea"
            placeholder="neue Notiz..."
            title="Shift.Enter für Zeilenumbruch"
            :onEnter="onTextareaEnter"
            v-model="comment" />
         <div class="col right">
            <div class="btn"
               :class="{ active: comment && comment.trim().length > 0 }"
               @click="onCommentSend">
               <Icon icon="iconSend" />
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import portalStateP from '@components/Portal/portalState';
const portalState = portalStateP()
import Icon from "@icons/Icon.vue"
import { Comment, Send, Delete, Edit } from "@icons/appFabric/icons"
import Textarea from '@components/Form/Textarea.vue';
import DatePicker from '@components/Form/DatePicker.vue';
import Dropdown from '@components/Form/Dropdown.vue';
import { Day, Timestamp } from '@SyoLab/date-time';
import utils from "@SyoLab/utils"


export default {
   name: 'MessageEditor',
   components: { Textarea, DatePicker, Dropdown, Icon },
   props: {
      focus: { required: false, default: false },
      customer: { required: true },
      contactIdPreset: { required: false },
   },
   data() {
      return {
         nextContactDate: null,
         nextContactDone: null,
         contactId: null,
         date: null,
         comment: null,
      }
   },
   static: {
      iconSend: Send,
   },
   methods: {
      onTextareaEnter(e) {
         if (e.shiftKey) {
            return
         }
         e.preventDefault()
         this.onCommentSend()
      },
      onCommentSend() {
         if (!this.comment) return
         this.comment = this.comment.trim()
         if (!this.comment) return

         let contact = this.customer.contacts.find(contact => contact._id == this.contactId)

         let comment = {
            _id: utils.ObjectId(),
            customer_id: this.customer._id,
            type: contact?.type || null,
            date: this.date,
            nextContactDate: this.nextContactDate,
            nextContactDone: null,
            nextContactUserId: null,
            contactId: contact?._id || null,
            contactName: contact?.name || null,
            comment: this.comment,
            userName: portalState.user.userName,
            userId: portalState.user.userId,
            timeStamp: Timestamp(),
         }
         this.customer.customerCommunication.push(comment)

         this.comment = null
         this.date = new Day().date
         this.nextContactDate = null

         this.$emit('send', comment)

      },
      onCommentEdit(comment) {
         this.comment = comment.comment
         this.date = comment.date
         this.nextContactDate = comment.nextContactDate
         this.contactId = comment.contactId
      },
   },
   computed: {
      contactDropdownOptions() {
         return {
            listItems: this.contactDropdownListItems,
            listItemKey: '_id',
            listItemValueKey: 'name',
            clearable: true,
         }
      },
      contactDropdownListItems() {
         return this.customer.contacts.filter(item => item.name)
      },
      datePickerOptions() {
         return {
            format: 'toIsoDate',
            max: new Day().date
         }
      }
   },
   created() {
      this.date = new Day().date
      this.contactId = this.contactIdPreset || null
   },
   mounted() {
      if (this.focus) {
         this.$refs.Textarea.focus()
      }
   }
}
</script>

<style scoped>
.MessageEditor {
   display: flex;
   flex-direction: column;
   row-gap: 5px;

   .paramsRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 9px;
      column-gap: 15px;

      .items {
         display: flex;
         align-items: center;
         justify-content: space-between;
         flex-grow: 1;

         .item {
            display: flex;
            align-items: center;
            height: 24px;
            background-color: var(--activeHover);
            border-radius: 3px;
         }

      }

      .right {
         display: flex;
         column-gap: 20px;
      }
   }

}



.textarea {
   display: flex;
   align-items: center;
   flex-grow: 1;
   background-color: #f9f9f9fc;
   width: 100%;
   padding: 3px 0;
   border-radius: 3px;

   .Textarea {
      flex-grow: 1;
   }
}

.textarea.new {
   background-color: var(--activeHover);
   border-radius: 3px;

   .col {
      justify-content: center;
      align-items: center;
      padding: 0 5px;
      position: relative;

   }
}
</style>