import { render, staticRenderFns } from "./BillingDeletedGrid.vue?vue&type=template&id=237304c4&scoped=true"
import script from "./BillingDeletedGrid.vue?vue&type=script&lang=js"
export * from "./BillingDeletedGrid.vue?vue&type=script&lang=js"
import style0 from "./BillingDeletedGrid.vue?vue&type=style&index=0&id=237304c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237304c4",
  null
  
)

export default component.exports