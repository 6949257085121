import { DateTime, Day } from "@SyoLab/date-time"
import utils from "@SyoLab/utils"

function parseValue(value) {
   // ISO?
   let match = String(value).match(/\d{4}-\d{2}-\d{2}T(.*)/)
   if (match && match.length == 2) {
      return match[1].split(':')
   }
   // HH:MM
   return value.split(':')
}

export function isIso(value) {
   if (!value) return false
   return /^\d{4}-\d{2}-\d{2}T\d{1,2}:\d{1,2}$/.test(value)
}

export function isIsoDate(value) {
   if (!value) return false
   return /^\d{4}-\d{2}-\d{2}$/.test(value)
}

export function isDate(value) {
   if (!value) return false
   return /^\d{4}-\d{2}-\d{2}$/.test(value)
}

export function isMonth(value) {
   if (!value) return false
   return /^\d{4}-\d{2}$/.test(value)
}

export function isWeek(value) {
   if (!value) return false
   return /^\d{4}-W\d{2}$/.test(value)
}
export function isYear(value) {
   if (!value) return false
   return /^\d{4}$/.test(value)
}

export function isTime(value) {
   if (!value) return false
   return /^\d{1,2}:\d{2}/.test(value)
}

export function getTimeStamp() {
   return DateTime.utc().toISOTimeStamp()
}

export function isoGetYear(date) {
   if (!date || typeof date !== 'string') return null
   return date.split('-')[0]
}

export function isoGetMonth(date) {
   if (!date || typeof date !== 'string') return null
   let parts = date.split('-')
   if (parts.length < 2) return null
   return `${parts[0]}-${parts[1]}`
}

export function isoGetTime(iso) {
   if (!iso) return null;
   let parts = iso.split('T');
   let time = parts[parts.length - 1]
   return time.includes(':') ? time : null
}

export function isoGetDate(iso) {
   if (!iso) return null;
   let parts = iso.split('T');
   let date = parts[0]
   return date.includes('-') ? date : null
}

export function isoGetHr(iso) {
   if (!iso) return null;
   let time = isoGetTime(iso)
   if (!time) return null
   return time.split(':')[0]
}

export function isoGetEuDate(iso) {
   if (!iso) return null;
   let date = isoGetDate(iso)
   if (!date) return null
   let parts = date.split('-')
   return `${parts[2]}.${parts[1]}.${parts[0]}`
}

/**
 * parse different date formats to iso date (01.06.23, 01.06.2023, May 6, 2021)
 * @param {string} date 
 * @returns {string} isoDate or null (2023-10-01)
 */
export function dateParseToIsoDate(date) {

   // 01.06.23
   if (/\d{2}.\d{2}.\d{2}$/.test(date)) {
      date = date.split('.')
      return `20${date[2]}-${date[1]}-${date[0]}`
   }
   // 01.06.2023
   if (/\d{2}.\d{2}.\d{4}$/.test(date)) {
      date = date.split('.')
      return `${date[2]}-${date[1]}-${date[0]}`
   }
   // May 6, 2021
   if (/\D{3}\d{1,2}.*\d{4}$/.test(date)) {
      let d = new Date(date)
      if (isNaN(d.getTime())) return null
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      let day = d.getDate()
      return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`
   }

   if (isoGetDate(date)) {
      return isoGetDate(date)
   }

   return null
}

export const minutes = {

   toFormatHH_MM(minutes, padHrs) {
      if (!minutes) return "0:00"
      if (isNaN(minutes)) return "--"
      let hrs = Math.trunc(minutes / 60);
      let min = minutes % 60
      let negator = (hrs < 0 || min < 0) ? '-' : ''
      min = String(Math.abs(min)).padStart(2, "0");
      hrs = Math.abs(hrs)
      if (padHrs) hrs = String(hrs).padStart(2, "0")
      return `${negator}${hrs}:${min}`
   },

   toFormatDecimal(minutes, toFixed = false) {
      let hrs = Math.trunc(minutes / 60);
      let min = minutes % 60
      let decimal = 1 / 60 * min
      return utils.round(hrs + decimal, 2, toFixed)

   },

   parse(value) {

      if (!value) return 0

      // parse xx:xx
      if (typeof value === 'string' && value.includes(':')) {
         let parts = parseValue(value)

         let min = parseInt(parts[1])
         let hrs = parts[0].trim()
         if (!hrs || hrs == 0) {
            return min
         }
         if (hrs == '-' || hrs == '-0') {
            return min * -1
         }
         hrs = parseInt(hrs)
         return hrs > 0 ? (hrs * 60) + min : (hrs * 60) - min
      }

      if (isNaN(value)) {
         if (value.length == 1 && value == '-') return value
         console.error(`Error Parsing ${value}`)
         return null
      }


      let hrs = Math.trunc(value);
      let dec = parseFloat(value % 1)
      dec = utils.round(dec, 2)
      let min = Math.round(60 * dec)
      if (hrs == 0) return min
      return (hrs * 60) + min

   }
}