<template>
   <div class="ServicesEditDialog">
      <Dialog v-if="editService"
         transferTo="modal"
         :maxHeight="($portal.portalHeight - 50) + 'px'"
         :options="{ closeOnEsc: false, closeBtn: true, scroll: true }"
         @esc="onEditCancel"
         @close="onDialogClose"
         @outsideClick="onDialogClose">
         <TaskEdit v-if="editService.type == 'task'"
            ref="editService"
            :taskData="editService"
            :config="config"
            :selectable="selectable"
            @delete="onDeleteService(editService)"
            @editEnd="onEditEndService"
            @cancel="onEditCancel"
            @convert="onConvert($event, editService)" />
         <FixedPriceTaskEdit v-if="editService.type == 'fixedPriceTask'"
            ref="editService"
            :taskData="editService"
            :config="config"
            :selectable="selectable"
            @update="onUpdateService"
            @delete="onDeleteService(editService)"
            @editEnd="onEditEndService"
            @cancel="onEditCancel"
            @convert="onConvert($event, editService)"
            @hook:mounted="onMounted" />
         <ServiceEdit v-if="editService.type == 'service'"
            ref="editService"
            :taskData="editService"
            :config="config"
            :selectable="selectable"
            @delete="onDeleteService(editService)"
            @editEnd="onEditEndService"
            @cancel="onEditCancel" />
         <ProjectEdit v-if="editService.type == 'project'"
            ref="editService"
            :projectData="editService"
            :config="config"
            :selectable="selectable"
            :services="services"
            @delete="onDeleteService(editService)"
            @editEnd="onEditEndProject"
            @cancel="onEditCancel" />
         <BchSubscriptionEdit v-if="editService.type == 'bchSubscription'"
            ref="editService"
            :subscriptionData="editService"
            :config="config"
            :selectable="selectable"
            @bchReload="$emit('bchReload', $event)"
            @update="onEditEndService"
            @close="onEditCancel" />
         <BchProductEdit v-if="editService.type == 'bchProduct'"
            ref="editService"
            :taskData="editService"
            :config="config"
            :selectable="selectable"
            @update="onUpdateService"
            @delete="onDeleteService(editService)"
            @editEnd="onEditEndService"
            @cancel="onEditCancel" />
      </Dialog>
   </div>
</template>

<script>
import Dialog from '@components/Dialog/Dialog.vue';
import TaskEdit from './task/TaskEdit.vue';
import FixedPriceTaskEdit from './fixedPriceTask/FixedPriceTaskEdit.vue';
import ServiceEdit from './service/ServiceEdit.vue';
import ProjectEdit from './project/ProjectEdit.vue';
import BchSubscriptionEdit from './bch/BchSubscriptionEdit.vue';
import BchProductEdit from './bchProduct/BchProductEdit.vue';


import { toDbServiceObject, serviceHasChanges } from './components/utils'
import api from '@components/Portal/api'

import { getFixedPriceTaskDataObject } from "./fixedPriceTask/fixedPriceTask"
import { getTaskDataObject } from "./task/task"

import portalStateP from '@components/Portal/portalState';
const portalState = portalStateP()
import { Timestamp } from "@SyoLab/date-time"


export default {
   name: 'ServicesEditDialog',
   components: { Dialog, TaskEdit, ProjectEdit, BchSubscriptionEdit, FixedPriceTaskEdit, ServiceEdit, BchProductEdit },
   props: {
      config: { required: true },
      selectable: { required: true },
      services: { required: true },
   },
   data() {
      return {
         editService: false,
         updateOnMounted: false,
      }
   },
   methods: {
      onEditCancel() {
         this.editService = null
      },
      onDialogClose() {
         if (this.$refs.editService) {
            this.$refs.editService.onEditEnd()
         } else {
            this.editService = null
         }
      },
      onEditEndProject(project) {
         this.$emit('update', project)
         this.editService = null
      },
      onDeleteService(service) {
         this.$emit('delete', service)
         this.editService = null
      },
      onEditEndService(task) {
         this.$emit('update', task)
         this.editService = false
      },
      updateTimeStamp(record) {
         record.timeStamp = Timestamp()
         record.userId = portalState.user.userId
      },
      onUpdateService(task) {
         // clean task data
         task = this.cleanTaskData(task)
      },
      cleanTaskData(service) {
         delete service._isNew
         if (service.timeEntrys) {
            service.timeEntrys.forEach(entry => {
               delete entry._hasChanges
            })
         }
         if (service.services) {
            service.services.forEach(service => {
               delete service._hasChanges
            })
         }
         return service
      },
      onKeyUp(event) {
         if (event.ctrlKey && event.key === 's') {
            event.preventDefault()
            this.onDialogClose()
         }
      },
      onConvert(type, editService) {

         let service = editService
         this.editService = null

         delete service.type
         delete service.settings
         delete service.services

         if (type == 'fixedPriceTask') {
            service = getFixedPriceTaskDataObject(service)
            this.updateOnMounted = true
         } else if (type == 'task') {
            service = getTaskDataObject(service)
            this.updateOnMounted = true
         }

         setTimeout(() => {
            this.editService = service
         }, 100)



      },
      onMounted() {

         if (this.updateOnMounted && this.$refs.editService?.update ? true : false) {
            this.$refs.editService.update()
            this.updateOnMounted = false
         }
      }
   },
   computed: {

   },
   watch: {
      editService(val) {
         if (val) {
            document.addEventListener('keydown', this.onKeyUp)
         } else {
            document.removeEventListener('keydown', this.onKeyUp)
         }
      }
   }
}
</script>

<style scoped>
.ServicesEditDialog {}
</style>