<template>
   <div class="NextContactDone">
      <CheckBox :value="record.nextContactDone"
         @input="onCheckBoxClick" />
   </div>
</template>

<script>
import CheckBox from '@components/Form/CheckBox.vue';
export default {
   name: 'NextContactDone',
   components: { CheckBox },
   props: {
      record: { required: true },
      column: { required: true },
   },
   data() {
      return {}
   },
   methods: {
      onCheckBoxClick(value) {
         this.$emit('nextContactDone', { value, _id: this.record._id, contactId: this.record.contactId })
      },
   },
   computed: {},
}
</script>

<style scoped>
.NextContactDone {
   display: flex;
   align-items: center;
   justify-content: center;
}
</style>