<template>
   <div class="BillingLists">
      <BillingPreview v-if="showPreview"
         :fullScreen="true" />
      <template v-else>
         <Tabs class="large"
            :tabs="tabs"
            :activeTab="activeTab"
            @nav="activeTab = $event" />
         <BillingList apiUrl="billing/"
            v-if="activeTab?._id == 1" />
         <BillingDeletedList v-if="activeTab?._id == 3" />
      </template>

   </div>
</template>

<script>
import Tabs from '@components/Navigation/Tabs.vue';
import BillingList from './BillingList.vue';
import BillingDeletedList from './BillingDeletedList.vue';
import BillingPreview from './BillingPreview.vue';
export default {
   name: 'BillingLists',
   components: { Tabs, BillingList, BillingDeletedList, BillingPreview },
   props: {},
   data() {
      return {
         tabs: [
            { _id: 1, text: 'Rechnungsliste', },
            { _id: 2, text: 'Mahnliste', },
            { _id: 3, text: 'Gelöscht', },
         ],
         activeTab: {},
      }
   },
   methods: {},
   computed: {
      showPreview() {
         return this.$route.path == '/billing/preview'
      }
   },
}
</script>

<style scoped >
.BillingLists {
   display: flex;
   flex-direction: column;
   row-gap: 35px;
}
</style>