import { defineStore } from "pinia";
import Vue from 'vue'
import { watch, toRefs } from 'vue'
import $pinia from "src/pinia"

import { _set, _get } from "@components/Portal/utils"


let autoSave = false;

const clientSettingsP = defineStore({
   id: "ClientSettings",
   state() {
      return {

      }
   },
   getters: {
      localStorageKey() {
         return 'clientSettings.' + portalState.userConfig.userId
      }
   },
   actions: {
      loadSettings(reset) {
         autoSave = false
         if (reset) this.$reset();
         // get data
         let data = JSON.parse(localStorage.getItem(this.localStorageKey)) || {}
         // set 
         for (const key in data) {
            if (!Object.hasOwnProperty.call(data, key)) continue;
            Vue.set(this.$state, key, data[key])
         }
         autoSave = true
      },
      get(path) {
         return _get(this.$state, path)
      },
      set(path, value) {
         _set(this.$state, path, value)
      },
      appSettingsGet(path) {
         // prefixes path with currentApp.name
         return this.get(`${portalState.currentApp.name}.${path}`)
      },
      appSettingsSet(path, value) {
         // prefixes path with currentApp.name
         this.set(`${portalState.currentApp.name}.${path}`, value)
      }
   }
});

export default clientSettingsP
const clientSettings = clientSettingsP($pinia)

// portalState
import portalStateP from "@components/Portal/portalState";
const portalState = portalStateP($pinia);

// watch whole state
watch(clientSettings.$state, (state) => {
   localStorage.setItem(clientSettings.localStorageKey, JSON.stringify(state))
}, { deep: true })

// watch authenticated
watch(() => portalState.$state.authenticated, (value, oldValue) => {
   clientSettings.loadSettings();

   let menuState = clientSettings.get('menuExpanded')
   if (typeof menuState == 'boolean') portalState.menu.expanded = menuState

})

clientSettings.loadSettings()





