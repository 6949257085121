<template>
   <div class="Status"
      :class="{ readonly, showList }"
      @click.stop="listToggle">
      <div class="activeStatus">
         <div v-if="activeItem"
            class="status"
            :style="{ backgroundColor: activeItem.backgroundColor, color: activeItem.color }">
            <div v-if="displayText"
               class="displayText">{{ displayText }} </div>
            <div v-else
               class="text">{{ activeItem.name }} <div v-if="afterText"
                  class="afterText">{{ afterText }}</div>
            </div>

         </div>
      </div>
      <div v-if="showList"
         class="list">
         <div class="triangle"><span></span></div>
         <div class="row"
            v-for="(item, index) in itemList"
            :key="index._id">
            <div class="status"
               :style="{ backgroundColor: item.backgroundColor, color: item.color }"
               @click.stop="onListItemClick(item)">
               <div class="text">{{ item.name }}</div>
            </div>
         </div>
      </div>

   </div>
</template>

<script>
import SlideDown from '@components/Containers/SlideDown.vue'
export default {
   name: 'Status',
   components: {
      SlideDown
   },
   props: {
      value: { required: true },
      readonly: { required: false, default: false },
      filter: { required: false },
      exclude: { required: false },
      displayText: { required: false },
      afterText: { required: false },
   },
   data() {
      return {
         items: [
            { _id: 'new', name: 'Neu', backgroundColor: '#FF7574', color: 'white' },
            // { _id: 'notStarted', name: 'Not started', backgroundColor: '#32302c', color: 'white' },
            // { _id: 'wait', name: 'Abwarten', backgroundColor: '#F4F6F8', color: 'black' }, //
            { _id: 'wait', name: 'Abwarten', backgroundColor: '#DDDDDD', color: 'black' }, // grey
            {
               _id: 'wip', name: 'In Arbeit', backgroundColor: '#66CCFF', color: 'white' // white blue
            },
            //{ _id: 'billed', name: 'Abgerechnet', backgroundColor: '#589CF9', color: 'white' }, // dark blue
            { _id: 'done', name: 'Fertig', backgroundColor: '#469E9C', color: 'white' },

            { _id: 'billed', name: 'Abgerechnet', backgroundColor: '#442a1e', color: 'white' },
            { _id: 'paid', name: 'Bezahlt', backgroundColor: '#442a1e', color: 'white' }
         ],
         showList: false
      }
   },
   methods: {
      onListItemClick(item) {
         if (this.readonly) return
         this.$emit('input', item._id)
         this.$emit('click', item._id)
         setTimeout(() => {
            this.showList = false
         }, 100)
      },
      listToggle() {
         if (this.readonly) return
         setTimeout(() => {
            this.showList = !this.showList
         }, 100)
      },
      outsideClickFn(e) {
         if (this.$el && this.$el.contains(e.target)) return
         this.showList = false
      },
   },
   computed: {
      itemList() {
         let items = [...this.items]
         if (this.filter) {
            items = items.filter(filterItem => this.exclude.includes(filterItem._id))
         }
         if (this.exclude) {
            items = items.filter(filterItem => !this.exclude.includes(filterItem._id))
         }
         if (!this.value) return items
         return items.filter(item => item._id !== this.value)
      },
      activeItem() {
         return this.items.find(item => item._id === this.value)
      },
      activeItemText() {
         if (this.displayText) return this.displayText
         return this.activeItem.name
      }
   },
   mounted() {
      this.$portal.$on('documentTouchClick', this.outsideClickFn)
   },
   beforeDestroy() {
      this.$portal.$off('documentTouchClick', this.outsideClickFn)
   }
}
</script>

<style scoped>
.Status {
   position: relative;
   height: 100%;
   width: 100%;
   min-width: 130px;
   cursor: default;
}

.Status.showList .status {
   box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.activeStatus {
   display: flex;
   align-items: center;
   height: 100%;
   width: 100%;

}

.list {
   position: absolute;
   left: 110%;
   z-index: 5;
   display: flex;
   flex-direction: column;
   background-color: rgba(255, 255, 255, 1);
   transform: translateY(-50%);
   top: 50%;
   box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.list .status {
   height: 38px;
}


.status {
   display: inline-flex;
   align-items: center;
   flex-shrink: 1;
   min-width: 0px;
   max-width: 100%;
   width: 100%;
   height: 100%;
   padding-left: 7px;
   padding-right: 9px;
   font-size: 14px;
   margin: 0px;
   user-select: none;
}

.Status:not(.readonly) .status {
   cursor: pointer;
}

.text {
   width: 100%;
   margin-left: 5px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   position: relative;
}

.text .afterText {
   position: absolute;
   right: 0;
   top: 0;
   height: 100%;
}

.displayText {
   margin-right: 5px;
   text-align: end;
   width: 100%;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   position: relative;
}


.list>.triangle {
   position: absolute;
   width: 20px;
   height: 12px;
   overflow: hidden;

   top: calc(50% - 6px);
   left: -16px;
   transform: rotate(-90deg);
}

.list>.triangle>span {
   width: 16px;
   height: 16px;
   position: absolute;
   left: 0;
   bottom: 0;
   box-shadow: -1px -1px 0px 0px rgb(0 0 0 / 10%);
   z-index: 1;
   background-color: white;
}

.list>.triangle>span {
   transform: rotate(45deg) translate3d(2px, 1px, 0);
   transform-origin: 0 16px;
}
</style>