<template>
	<div class="MenuMore"
		:class="{ show, orientationBelow: orientation == 'below', orientationRight: orientation == 'right' }">
		<div class="menuBtn"
			@click="show = !show">
			<Icon icon="iconMore" />
		</div>
		<div class="list boxShadowLight">
			<div class="triangle"><span></span></div>
			<div class="slot"
				@click="menuItemClick">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '@icons/Icon.vue'
import { MoreVertical } from '@icons/appFabric/icons'

export default {
	name: 'MenuMore',
	components: { Icon },
	props: {
		orientation: {
			type: String,
			default: 'below',
		},
	},
	data() {
		return {
			show: false,
		}
	},
	static: {
		iconMore: MoreVertical,
	},
	methods: {
		outsideClick(e) {
			if (this.$el && this.$el.contains(e.target)) return
			this.show = false
			this.$emit('outsideClick', e)
		},
		close() {
			this.show = false
		},
		menuItemClick() {
			// bubbled click event from slot click
			setTimeout(() => {
				this.show = false
			}, 10)
		},
	},
	computed: {
	},
	mounted() {
		this.$portal.$on('documentTouchClick', this.outsideClick)
	},
	beforeDestroy() {
		this.$portal.$off('documentTouchClick', this.outsideClick)
	},
}
</script>

<style scoped>
.MenuMore {
	position: relative;
}

.slot {
	display: flex;
	flex-direction: column;
}

.menuBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	width: 24px;
	cursor: pointer;
	border-radius: 2px;
}

.MenuMore.small .menuBtn {
	width: 18px;
	height: 18px;
	font-size: 14px;
}

.menuBtn:hover {
	background-color: #edf7ff;
	color: rgb(4, 107, 186);
}

.list {
	position: absolute;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	background-color: white;
	padding: 0;
	z-index: 10;
	margin-top: 0;
	opacity: 0;
	display: none;
	transition: all 0.3s ease;
	border-radius: 6px;
}

.MenuMore.orientationBelow .list {
	top: 110%;
	left: 0;
	transform: translate3d(calc(-50% + 8px), 8px, 0);
	/*add ancor size*/
}

.MenuMore.orientationBelow.show .list {
	opacity: 1;
	display: block;
	transition: all 0.3s ease;
	border-radius: 6px;
}

.MenuMore.orientationBelow .list {
	top: 110%;
	left: 0;
	transform: translate3d(calc(-50% + 8px), 8px, 0);
	/*add ancor size*/
}

.MenuMore.orientationBelow.show .list {
	opacity: 1;
	visibility: visible;
	transition: all 0.3s ease;
	transform: translate3d(calc(-50% + 8px), 12px, 0);
	/*add ancor size*/
}

/*orientation right*/
.MenuMore.orientationRight .list {
	top: 0;
	left: 110%;
	transform: translate3d(8px, calc(-50% + 13px), 0);
	/*add ancor size*/
}

.MenuMore.orientationRight.small .list {
	transform: translate3d(4px, calc(-50% + 11px), 0);
}

.MenuMore.orientationRight.show .list {
	opacity: 1;
	display: block;
	transition: all 0.3s ease;
	transform: translate3d(12px, calc(-50% + 13px), 0);
	/*add ancor size*/
}

.MenuMore.orientationRight.small.show .list {
	transform: translate3d(8px, calc(-50% + 11px), 0);
}

.list>.triangle {
	position: absolute;
	width: 20px;
	height: 12px;
	overflow: hidden;
}

.MenuMore.orientationBelow .list>.triangle {
	left: calc(50% - 12px);
	top: -12px;
	/*add ancor size*/
}

.MenuMore.orientationRight .list>.triangle {
	top: calc(50% - 6px);
	left: -16px;
	transform: rotate(-90deg);
}

.list>.triangle>span {
	width: 16px;
	height: 16px;
	position: absolute;
	left: 0;
	bottom: 0;
	box-shadow: -1px -1px 0px 0px rgb(0 0 0 / 10%);
	z-index: 1;
	background-color: white;
}

.list>.triangle>span {
	transform: rotate(45deg) translate3d(2px, 1px, 0);
	transform-origin: 0 16px;
}


.slot>* {
	width: 100%;
	padding: 6px 12px;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	cursor: pointer;
	display: flex;
	white-space: nowrap;
	border-radius: 6px;
}

.slot>*:hover {
	background-color: #edf4fb;
}
</style>
