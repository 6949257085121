<template>
   <div class="WorkingDay">
      <h5>Arbeitstag</h5>
      <div v-if="timeEntrys.length == 0"
         class="info">Click auf den Tag in der Arbeitswoche um ihn anzuzeigen</div>
      <template v-else>
         <div v-if="employeeName"
            class="employeeName">{{ employeeName }}, {{ dateDisplay(date) }}</div>
         <div v-else-if="date"
            class="date">{{ dateDisplay(date) }}</div>
         <div class="grid">
            <div class="header">
               <div class="from">Von</div>
               <div class="to">Bis</div>
               <div class="duration">Dauer</div>
               <div class="customer">Kunde</div>
               <div class="project">Projekt</div>
               <div class="task">Task</div>
               <div class="text">Text</div>
            </div>
            <div v-for="(entry, index) in timeEntrys"
               :key="index"
               class="gridRow"
               :class="gridRowClass(entry)">
               <div class="from">{{ entry.from }}</div>
               <div class="to">{{ entry.to }}</div>
               <div class="duration">{{ durationDisplay(entry.minutes) }}</div>
               <div class="customer">{{ entry.customerName }}</div>
               <div class="project">{{ entry.projectName }}</div>
               <div class="task">{{ entry.name }}</div>
               <div class="text">{{ entry.text }}</div>
            </div>
         </div>
      </template>

   </div>
</template>

<script>
import api from '@components/Portal/api'
import { Day } from "@SyoLab/date-time"
import { minutes } from 'src/utils/utils'
export default {
   name: 'WorkingDay',
   components: {},
   props: {
      apiUrl: { required: true }
   },
   data() {
      return {
         timeEntrys: [],
         employee_id: null,
         employeeName: null,
         date: null,
      }
   },
   methods: {
      async getData() {
         if (!this.employee_id || !this.date) return
         let res = await api.post(`${this.apiUrl}/workingDay`, {
            date: this.date,
            employee_id: this.employee_id,
         })
         this.timeEntrys = res
      },
      show(employee_id, date, employee) {
         this.timeEntrys = []
         this.employee_id = employee_id
         this.date = date
         this.employeeName = employee || null
         this.getData()
      },
      update() {
         this.getData()
      },
      dateDisplay(date) {
         return new Day(date).toFormat('ccc, dd.MM.yyyy')
      },
      gridRowClass(entry) {
         if (entry.from && !entry.to) {
            return { wip: true }
         }
         return {
            error: entry.error
         }
      },
      durationDisplay(value) {
         if (value == 0) return ''
         return minutes.toFormatHH_MM(value)
      }
   },
   computed: {},
}
</script>

<style scoped>
.WorkingDay {}

.info {
   color: #c7c5c5;
}

.employeeName {
   margin-bottom: 10px;
}

.grid {
   display: grid;
   grid-template-columns: [from] 45px [to] 45px [minutes] 45px [customer] 200px [project] 200px [task] 200px [text] 260px;

}

.grid .header {
   display: contents;
}

.grid .header>div {
   font-size: 12px;
   font-weight: 500;
   color: rgba(0, 0, 0, 0.7);
   padding-top: 3px;
   padding-bottom: 10px;
}

.gridRow {
   display: contents;
}

.gridRow.error>div {
   background-color: #f8d7da;
}

.gridRow.wip>div {
   background-color: #8FD8D6;
}

.gridRow .customer,
.gridRow .project,
.gridRow .text,
.gridRow .task {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
</style>