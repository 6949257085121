<template>
   <div class="ServiceGridBillIcon">
      <div v-if="showPdfBtn"
         class="btn hover"
         @click="onPdfShow">
         <Icon icon="iconBill" />
      </div>
      <div v-else-if="service.billNo"
         class="billedIcon">
         <Icon icon="iconBill" />
      </div>
   </div>
</template>

<script>
import Icon from "@icons/Icon.vue"
import { Bill, } from "@icons/appFabric/icons"
import { popupOpen } from "./utils"
export default {
   name: 'ServiceGridBillIcon',
   components: { Icon },
   props: {
      config: { required: true },
      service: { required: true },
   },
   data() {
      return {}
   },
   static: {
      iconBill: Bill,
   },
   methods: {
      onPdfShow() {
         if (!this.config.billingPdfUrl) {
            console.error('pdfUrl is not set')
            return
         }
         popupOpen(window, `${this.config.billingPdfUrl}/${this.service.pdf}`, 'bill', { height: 1200, width: 1200 })
      },
   },
   computed: {
      showPdfBtn() {
         if (!this.service.billNo) return false
         if (this.config.mode == 'myTasks') return false
         if (this.config.showBilling === false) return false
         return this.service.pdf ? true : false
      },
      showBillIcon() {
         if (this.showPdfBtn) return false
         return this.service.billNo ? true : false
      }
   },
}
</script>

<style scoped>
.ServiceGridBillIcon {}
</style>