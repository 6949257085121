<template>
	<transition name="AnimateSizeChange"
		mode="out-in"
		:css="false"
		@enter="enter"
		@after-enter="afterEnter"
		@enter-cancelled="enterCancelled"
		@before-leave="beforeLeave"
		@leave="leave"
		@leave-cancelled="leaveCancelled">
		<slot></slot>
	</transition>
</template>
<script>
export default {
	name: 'AnimateSizeChange',
	props: {
		duration: { required: false, default: 400 },
	},
	data() {
		return {
			parent: null,
			parentHeight: null,
			parentWidth: null,
		}
	},
	methods: {
		enter: function (el, done) {
			console.log('enter')
			// render the element and get dimensions
			let height = this.parent.offsetHeight
			let width = this.parent.offsetWidth

			el.style.opacity = 0.3

			// set startDimensions
			this.parent.style.height = this.parentHeight + 'px'
			this.parent.style.width = this.parentWidth + 'px'

			// set transition
			this.parent.style.transitionProperty = 'height, width'
			this.parent.style.transitionDuration = parseInt(this.duration / 2) + 'ms'
			this.parent.style.overflow = 'hidden'

			setTimeout(() => {
				// targetDimensions
				this.parent.style.height = height + 'px'
				this.parent.style.width = width + 'px'
				el.style.transitionProperty = 'opacity'
				el.style.transitionDuration = parseInt(this.duration / 2) + 'ms'
				el.style.opacity = 1
			}, 1)

			// set
			setTimeout(() => {
				done()
			}, parseInt(this.duration / 2))
		},
		afterEnter: function (el) {
			// cleanup
			this.parent.style.removeProperty('height')
			this.parent.style.removeProperty('width')
			this.parent.style.removeProperty('transition-property')
			this.parent.style.removeProperty('transition-duration')
			this.parent.style.removeProperty('overflow')
			el.style.removeProperty('opacity')
			el.style.removeProperty('transition-property')
			el.style.removeProperty('transition-duration')
		},
		enterCancelled: function (el) {
			// cleanup
			this.parent.style.removeProperty('height')
			this.parent.style.removeProperty('width')
			this.parent.style.removeProperty('transition-property')
			this.parent.style.removeProperty('transition-duration')
			this.parent.style.removeProperty('overflow')
			el.style.removeProperty('opacity')
			el.style.removeProperty('transition-property')
			el.style.removeProperty('transition-duration')
		},

		beforeLeave: function (el, done) {
			// save current size of parent Element
			this.parent = this.$el.parentElement
			this.parentWidth = this.parent.offsetWidth
			this.parentHeight = this.parent.offsetHeight
			// set transition
			el.style.transitionProperty = 'opacity'
			el.style.transitionDuration = parseInt(this.duration / 2) + 'ms'
		},

		leave: function (el, done) {
			el.style.opacity = 0.3
			setTimeout(() => {
				done()
			}, parseInt(this.duration / 2))
		},
		leaveCancelled(el) {
			el.style.removeProperty('opacity')
			el.style.removeProperty('transition-property')
			el.style.removeProperty('transition-duration')
		},
	},
}
</script>
