<template>
   <div class="ServicesGrid">
      <template v-for="(service, index) in servicesGrid">
         <CustomerServiceGridRow v-if="gridShowCustomer && service.type == 'customer'"
            :service="service"
            :row="index"
            :key="service._id"
            :config="config"
            :selectable="selectable"
            :selectedCustomer_id="selectedCustomer_id"
            :selectedServices="selectedServices"
            @select="onCustomerSelect" />
         <AddServiceGridRow v-if="service.type == 'addService'"
            :addServiceList="config.addServiceList"
            :service="service"
            :row="index"
            :key="service._id"
            :config="config"
            @addService="$emit('projectAddService', $event)"
            @addServiceType="$emit('projectAddServiceType', $event)"
            @deleteProject="onServiceDelete(service)" />
         <div v-if="service.type == 'endProject'"
            class="endProject"
            :style="gridRowStyle(index)"
            :key="service._id"> &nbsp;</div>
         <ProjectServiceGridRow v-if="service.type == 'project'"
            :key="service._id"
            :ref="service._id"
            :service="service"
            :row="index"
            :config="config"
            :selectable="selectable"
            :selectedCustomer_id="selectedCustomer_id"
            :selectedServices="selectedServices"
            @click="onEdit(service)"
            @select="onProjectSelect" />
         <TaskServiceGridRow v-if="service.type == 'task'"
            :key="service._id"
            :ref="service._id"
            :service="service"
            :row="index"
            :config="config"
            :selectable="selectable"
            :selectedCustomer_id="selectedCustomer_id"
            :selectedServices="selectedServices"
            :toggleItems="toggleItems"
            :taskTotalCHF="taskTotalCHF"
            @click="onEdit(service)"
            @select="onServiceSelect"
            @move="onServiceMove(service, $event)"
            @delete="onServiceDelete(service)"
            @assignedToUpdate="onAssignedToUpdate(service, $event)"
            @statusUpdate="onStatusUpdate(service, $event)"
            @serviceUpdate="onServiceUpdate(service, $event)" />
         <FixedPriceTaskServiceGridRow v-if="service.type == 'fixedPriceTask'"
            :key="service._id"
            :ref="service._id"
            :service="service"
            :row="index"
            :config="config"
            :selectable="selectable"
            :selectedCustomer_id="selectedCustomer_id"
            :selectedServices="selectedServices"
            :taskTotalCHF="taskTotalCHF"
            :toggleItems="toggleItems"
            @click="onEdit(service)"
            @select="onServiceSelect"
            @move="onServiceMove(service, $event)"
            @delete="onServiceDelete(service)"
            @assignedToUpdate="onAssignedToUpdate(service, $event)"
            @statusUpdate="onStatusUpdate(service, $event)"
            @serviceUpdate="onServiceUpdate(service, $event)" />
         <ServiceGridRow v-if="service.type == 'service'"
            :key="service._id"
            :ref="service._id"
            :service="service"
            :row="index"
            :config="config"
            :selectable="selectable"
            :selectedCustomer_id="selectedCustomer_id"
            :selectedServices="selectedServices"
            :taskTotalCHF="taskTotalCHF"
            @click="onEdit(service)"
            @select="onServiceSelect"
            @move="onServiceMove(service, $event)"
            @delete="onServiceDelete(service)"
            @statusUpdate="onStatusUpdate(service, $event)"
            @serviceUpdate="onServiceUpdate(service, $event)" />
         <BchSubscriptionGridRow v-if="service.type == 'bchSubscription'"
            :key="service._id"
            :ref="service._id"
            :service="service"
            :row="index"
            :config="config"
            :selectable="selectable"
            :selectedCustomer_id="selectedCustomer_id"
            :selectedServices="selectedServices"
            @bchReload="$emit('bchReload', $event)"
            @click="onEdit(service)"
            @select="onServiceSelect"
            @statusUpdate="onStatusUpdate(service, $event)"
            @serviceUpdate="onServiceUpdate(service, $event)" />
         <BchProductServiceGridRow v-if="service.type == 'bchProduct'"
            :key="service._id"
            :ref="service._id"
            :service="service"
            :row="index"
            :config="config"
            :selectable="selectable"
            :selectedCustomer_id="selectedCustomer_id"
            :selectedServices="selectedServices"
            @bchReload="$emit('bchReload', $event)"
            @click="onEdit(service)"
            @select="onServiceSelect"
            @statusUpdate="onStatusUpdate(service, $event)"
            @serviceUpdate="onServiceUpdate(service, $event)" />
      </template>
   </div>
</template>

<script>
import CustomerServiceGridRow from './components/CustomerServiceGridRow.vue';
import ProjectServiceGridRow from './project/ProjectServiceGridRow.vue';
import TaskServiceGridRow from './task/TaskServiceGridRow.vue';
import FixedPriceTaskServiceGridRow from './fixedPriceTask/FixedPriceTaskServiceGridRow.vue';
import ServiceGridRow from './service/ServiceGridRow.vue';
import BchSubscriptionGridRow from './bch/BchSubscriptionGridRow.vue';
import BchProductServiceGridRow from './bchProduct/BchProductServiceGridRow.vue';

import AddServiceGridRow from './components/AddServiceGridRow.vue';

import { ObjectId, shortId, array, clone } from '@SyoLab/utils'
import { Timestamp } from "@SyoLab/date-time"
import { toDbServiceObject, serviceSort } from './components/utils'
import api from '@components/Portal/api';
import { set } from 'vue';

export default {
   name: 'ServicesGrid',
   components: { CustomerServiceGridRow, ProjectServiceGridRow, TaskServiceGridRow, BchSubscriptionGridRow, AddServiceGridRow, FixedPriceTaskServiceGridRow, ServiceGridRow, BchProductServiceGridRow },
   props: {
      config: { required: true },
      services: { required: true },
      filters: { required: false, },
      selectable: { required: true },
      selectedCustomer_id: { required: true },
      selectedServices: { required: true },
      showCustomer: { required: false, default: true },
      showProjects: { required: false, default: true },
      toggleItems: { required: false },
      taskTotalCHF: { required: false, default: false },
   },
   data() {
      return {
         servicesGrid: []
      }
   },
   methods: {
      onEdit(service) {
         this.$emit('edit', service)
      },
      onProjectSelect(project, event) {
         if (!event) {
            this.$emit('select', { selectedServices: [], selectedCustomer_id: null })
            return
         }
         let services = [project]
         services = services.concat(project._services
            .filter(item => {
               if (!this.$refs[item._id]) return false
               return this.$refs[item._id][0].isSelectable
            }))
         this.$emit('select', { selectedServices: services.map(s => s._id), selectedCustomer_id: project.customer_id })
      },
      onCustomerSelect(customer, event) {
         if (!event) {
            this.$emit('select', { selectedServices: [], selectedCustomer_id: null })
            return
         }
         let services = this.services.filter(s => {
            if (s.customer_id != customer.customer_id) return false
            if (s.type == 'customer') return false
            if (s.type == 'project') return false
            return true
         })
         this.$emit('select', { selectedServices: services.map(s => s._id), selectedCustomer_id: customer.customer_id })
      },
      onServiceSelect(service, event) {
         if (event && !this.selectedServices.includes(service._id)) {
            // check if project is already selected
            if (service._project && !this.selectedServices.includes(service._project._id)) {
               this.selectedServices.push(service._project._id)
            }
            this.$emit('select', { selectedServices: [...this.selectedServices, service._id], selectedCustomer_id: service.customer_id })
         } else if (!event) {
            let selectedServices = this.selectedServices.filter(s => s != service._id)
            if (this.selectedServices.length == 0) {
               this.$emit('select', { selectedServices: [], selectedCustomer_id: null })
            } else {
               this.$emit('select', { selectedServices, selectedCustomer_id: service.customer_id })
            }
         }
      },
      onAssignedToUpdate(service, assignedTo) {
         this.$emit('update', toDbServiceObject(service))
      },
      onStatusUpdate(service, status) {
         service.status = status
         this.$emit('update', toDbServiceObject(service))
      },
      onServiceUpdate(service, status) {
         this.$emit('update', toDbServiceObject(service))
      },
      gridRowStyle(index) {
         return {
            gridRow: index + 1,
            gridColumn: '1 / -1'
         }
      },
      gridShowCustomer() {
         if (!this.showCustomer) return false
         if (this.customer_id) return false
         return true
      },
      onServiceMove(service, direction) {
         let updates = []
         // clone project services
         let services = [...service._project._services]
         let idx = services.findIndex(s => s._id == service._id) // get index of current service
         let timeStamp = Timestamp()
         services.forEach((s, i) => {
            let service = this.services.find(service => service._id == s._id)
            if (direction == 'prev') {
               if (i == idx - 1) {
                  service.order = i + 1 // move next
                  service.timeStamp = timeStamp
                  updates.push(service)
               }
               else if (i == idx) {
                  service.order = i - 1 // move previous
                  service.timeStamp = timeStamp
                  updates.push(service)
               }
               else {
                  if (service.order != i) {
                     service.order = i
                     service.timeStamp = timeStamp
                     updates.push(service)
                  }
               }
            } else if (direction == 'next') {
               if (i == idx + 1) {
                  service.order = i - 1 // move previous
                  service.timeStamp = timeStamp
                  updates.push(service)
               }
               else if (i == idx) {
                  service.order = i + 1 // move next
                  service.timeStamp = timeStamp
                  updates.push(service)
               }
               else {
                  if (service.order != i) {
                     service.order = i
                     service.timeStamp = timeStamp
                     updates.push(service)
                  }
               }
            }
         })

         updates = updates.map(toDbServiceObject)

         if (updates.length > 0) {
            api.put(`${this.config.apiUrl}/services`, updates)
         }

      },
      onServiceDelete(service) {
         let idx = this.services.findIndex(s => {
            if (service.type == 'addService') {
               // search project instead
               return s.project_id == service.project_id && s.type == 'project'
            }
            return s._id == service._id
         })

         if (idx > -1) {
            this.$emit('delete', this.services[idx])
         }
      },
      updateGridRecords() {

         let servicesFiltered = this.services.filter(s => {
            if (!s.customer_id) return false
            if (!this.showProjects && s.type == 'project') return false
            if (!Array.isArray(this.filters) || this.filters.length == 0) return true
            return this.filters.every(f => {
               if (f.type == 'showBilled' && f.value == true && s.billNo) return false
               if (f.type == 'assignedTo') {
                  if (!s.assignedTo || !Array.isArray(s.assignedTo)) return false
                  if (!s.assignedTo.find(e => e._id == f.value)) return false
               }
               if (f.type == 'businessDomain') {
                  if (s.businessDomain != f.value) return false
               }
               if (f.type == 'customer') {
                  if (s.customer_id != f.value) return false
               }

               return true
            })

         })
         servicesFiltered = servicesFiltered.map(service => {
            return clone(service)
         })

         let servicesGrouped = array.groupBy(servicesFiltered, 'customer_id')
         let gridRecords = []
         for (const customer_id in servicesGrouped) {
            let services = servicesGrouped[customer_id]
            // sort services
            services = serviceSort(services)
            let noProjectServices = services.filter(s => !s.project_id)
            let projectServices = services.filter(s => s.project_id)

            let customerName = services[0].customerName
            // add customer row
            if (this.showCustomer) {
               gridRecords.push({
                  _id: `customer_${customer_id}`,
                  type: 'customer',
                  customer_id,
                  customerName
               })
            }

            // group by project
            let projectServicesGrouped = array.groupBy(projectServices, 'project_id')
            for (const project_id in projectServicesGrouped) {
               let services = projectServicesGrouped[project_id]
               // project:
               let projectIdx = services.findIndex(s => s.type == 'project') // get project index
               let project = services.splice(projectIdx, 1)[0] // remove project from services
               project._services = services // add services reference to project
               gridRecords.push(project) // add project row


               // services: add all services of project
               services.forEach(service => {
                  // add project reference to service
                  service._project = project
                  gridRecords.push(service)
               });


               // addProjectService row
               if (this.showProjects) {
                  gridRecords.push({
                     _id: `addService_${project.customer_id}_${project.project_id}`,
                     type: 'addService',
                     _project: project,
                     customer_id: project.customer_id,
                     customerName: project.customerName,
                     project_id: project.project_id,
                     projectName: project.projectName,
                     businessDomain: project.businessDomain,
                     accountNo: project.accountNo,
                     serviceType: project.serviceType,
                     color: project.color,
                     settings: project.settings,

                  })

                  if (this.config.mode != 'billed')
                     // endProject row
                     gridRecords.push({
                        _id: `endProject_${project.customer_id}_${project.project_id}`,
                        type: 'endProject',
                        customer_id: project.customer_id,
                        customerName: project.customerName,
                        project_id: project.project_id,
                        projectName: project.projectName,
                        businessDomain: project.businessDomain,
                        accountNo: project.accountNo,
                        serviceType: project.serviceType,
                        color: project.color,
                     })
               }

            }

            // add all Services that do not have Projects at the end of customer 
            noProjectServices.forEach(service => {
               gridRecords.push(service)
            });
         }

         this.servicesGrid = gridRecords
      },
   },
   computed: {
   },
   watch: {
      services: {
         handler() {
            this.updateGridRecords()
         },
         immediate: true,
         deep: true
      },
      filters() {
         this.updateGridRecords()
      }
   },
}
</script>

<style scoped>
.ServicesGrid {
   display: grid;
   grid-template-columns: [borderLeft] 5px [left] 380px [middle] 340px [right] 130px [action] 45px;
   row-gap: 3px;
}

.endProject {
   grid-column: 1 / -1;
   padding-left: 10px;
}
</style>