import { clone } from "@SyoLab/utils"

export function onFixedPriceTaskUpdate(service, config, reason) {

   if (reason == 'timeEntry') {
      service.status = 'wip'
   }

   // update service totalMinutes
   service.totalMinutes = service.timeEntrys.reduce((total, service) => {
      if (service.error) return total
      if (service.notBillable) return total
      return total + service.minutes
   }, 0)

   // update service totalValue
   service.value = service.services.reduce((total, service) => {
      let value = service.value || 0
      return total + value
   }, 0)
}

// INFO: update on server in bchOrder.js also
export function getFixedPriceTaskDataObject(data = {}, settings2 = {}) {

   data = clone(data)
   data.settings = { ...data.settings, ...settings2, }

   return {
      _id: null,
      type: 'fixedPriceTask',
      name: null,
      project_id: null,
      projectName: null,
      customer_id: null,
      notBillable: false,
      customerName: null,
      serviceType: null,
      color: null,
      accountNo: null,
      businessDomain: null,
      defaultWorkType: null,
      // estimated
      estimatedMinutes: null,
      // actual totals
      totalMinutes: 0,
      totalMinutesNonBillable: 0,
      value: 0,
      valueNonBillable: 0,
      // status
      status: null,
      assignedTo: [],
      listUntil: null, // list after billing until this date
      // serviceData
      services: [],
      timeEntrys: [],
      config: {},
      settings: {},
      createdBy: null,
      createdTimeStamp: null,
      userId: null,
      timeStamp: null,
      order: null, // sorting order
      // billing
      billingText: null,
      billNo: null,
      billingTimestamp: null,
      billedBy: null,
      ...data
   }
}


// INFO: update on server in bchOrder.js also
export function getBchSubscriptionDataObject(data = {}, settings2 = {}) {
   data = clone(data)
   data.settings = { ...data.settings, ...settings2, }

   return {
      _id: null,
      type: 'fixedPriceTask',
      name: null,
      project_id: null,
      projectName: null,
      customer_id: null,
      customerName: null,
      serviceType: 'bchSubscriptions',
      color: '#DCBD49',
      accountNo: '3420',
      businessDomain: 'bch',
      // bch
      bchOrder: null,
      // subscription
      startDate: null,
      endDate: null,
      // actual totals
      value: 0,
      valueNonBillable: 0,
      // status
      status: 'done',
      // serviceData
      services: [],
      config: {},
      settings: {},
      createdBy: null,
      createdTimeStamp: null,
      userId: null,
      timeStamp: null,
      order: null, // sorting order
      // billing
      billingType: 'subscription',
      billingText: null,
      billNo: null,
      billingTimestamp: null,
      billedBy: null,
      ...data
   }
}