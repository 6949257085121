import utils from "@SyoLab/utils"
import { WebAppBuilderFragment, } from "@icons/appFabric/icons"
//const defaultApp = () => import(/* webpackChunkName: "DefaultApp" */"./Default.vue")
import component from "./CustomerServices.vue"
const appName = 'customerServices'
const basePath = "/customerServices"
export default {
   name: appName,
   auth: true, //auth: if authenticated must have app in userApps
   menuShow: false, //menuShow overrides everything
   menuPosition: 4, //sorting menu position of app
   component: component,
   // appConfig from base -> available in Component.appConfig
   appConfig: {
      foo: 'foo'
   },
   // routes are used to match all menuItem routes
   // the are referenced to the app
   routes: [
      {
         name: "customerServices",
         path: "/customerServices*"
      }
   ],
   menu: [
      {
         //"appName": appName, -> not needed appName is referenced on import
         "text": "Leistungen",
         "icon": WebAppBuilderFragment,
         "class": "defClass root",
         "route": '/customerServices', //route path
         "action": undefined, //action emitted to app
         "enabled": true, // enabled / disable menu item (can be used as function with appConfig)
         // status
         "active": false, // is active menu item
         "show": true, // show / hide, toggles visibility on click

      }
   ]
}