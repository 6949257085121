<template>
	<div class="Dropdown"
		:class="classes"
		:style="style">
		<label v-if="settings.style != 'datatable'"
			for>{{ settings.label }}</label>
		<div class="input"
			@click="onInputClick">
			<div ref="slotBefore"
				v-if="$slots.before"
				class="before">
				<slot class="before"
					name="before"></slot>
			</div>
			<div class="autogrow"
				ref="autogrow">
				<input ref="input"
					tabindex="0"
					size="1"
					autocomplete="new-password"
					:readonly="isReadonly"
					:value="displayText"
					:placeholder="settings.placeholder"
					@input="onInput"
					@focus="onFocus"
					@keyup.enter.stop="onKeyupEnter"
					@keyup.down.stop="onInputArrowDown"
					@keyup.esc.stop="onInputEsc"
					@blur="onInputBlur"
					@mousedown="onMouseDown" />
			</div>
			<div v-if="!showClearIcon && showTriangleIcon"
				class="triangle">
				<div class="icon"></div>
			</div>
			<Icon v-if="showClearIcon"
				class="close"
				icon="iconClose"
				@click.stop="onClear" />
			<div v-if="$slots.after"
				ref="afterSlot"
				class="after">
				<slot name="after"></slot>
			</div>
		</div>
		<div class="anchor"
			ref="anchorEl">
			<Popover v-if="open"
				ref="popover"
				:ref-el="$refs.input"
				:anchor-el="settings.anchorEl || $refs.anchorEl"
				:class="'dropdownList ' + settings.align"
				:template-styles="{
					width: popoverWidth,
					height: popoverHeight,
				}"
				:offset="popoverOffset"
				@outsideClick="onOutsideClick">
				<Scrollbar ref="Scrollbar"
					class="popoverScrollbar"
					style="max-height: 100%"
					@overscroll="onOverscroll">
					<ul>
						<li v-for="(listItem, index) in dropDownList"
							ref="listItem"
							:key="index"
							tabindex="0"
							:class="{ focus: activeListItem == index }"
							@click.stop="onLiClick(listItem)"
							@keyup.down="onLiFocusNext(index + 1)"
							@keyup.up="onLiFocusNext(index - 1)"
							@keyup.enter="onLiEnter(listItem)"
							@keyup.esc="onLiEsc">
							{{ listItem.value }}
						</li>
					</ul>
				</Scrollbar>
			</Popover>
		</div>
		<div v-if="!settings.datatable"
			:class="{ notification: true, error: error || validationError || settings.notificationIsError }">
			{{ notificationText }}
		</div>
	</div>
</template>

<script type="text/javascript">
import scrollbar from '@components/Tools/vue-scrollbar'
import popover from '@components/Containers/Popover'
import Icon from '@icons/Icon.vue'
import { ChromeClose } from '@icons/appFabric/icons'
import utils from '@SyoLab/utils'
export default {
	name: 'Dropdown',
	components: {
		Scrollbar: scrollbar,
		Popover: popover,
		Icon,
	},
	props: {
		label: {
			type: String,
			required: false,
			default: null,
		},
		placeholder: {
			type: String,
			required: false,
			default: null,
		},
		value: {
			type: [String, Number, Object, Boolean],
			required: false,
			default: undefined,
		},
		listItems: {
			type: [Array, Object],
			required: false,
			default: function () {
				return []
			},
		},
		options: {
			type: Object,
			required: false,
			default: () => {
				return {}
			},
		},
		anchorEl: {
			type: [Object, String, HTMLDivElement],
			required: false,
			default: null,
			note: 'Provide a Dom El or querystring',
		},
		readonly: {
			type: [Boolean, String],
			required: false,
			default: () => {
				return false
			},
		},
		notification: {
			type: String,
			required: false,
			default: null,
		},
		notificationIsError: {
			type: Boolean,
			required: false,
			default: false,
		},
		emptyListItem: {
			type: Boolean,
			required: false,
			default: false,
		},
		autocomplete: {
			type: Function,
			required: false,
		},
		width: { required: false },
	},
	data() {
		return {
			hasFocus: false,
			open: false,
			autocompleteText: '',
			error: false,
			validationError: false,
			activeListItem: null,
			selectedListItem: null,
			selectedOriginalItem: null,
			autocompleteListItems: [],
		}
	},
	static: {
		iconClose: ChromeClose,
	},
	methods: {
		onOverscroll(e) {
			e.stopPropagation()
			e.preventDefault()
		},
		mapToInternalListItem(item) {
			if (typeof item == 'object' && item != null) {
				// simple Object: { 1: 'value'}
				if (this.isSimpleObject(item)) {
					let key = Object.keys(item)[0]
					return {
						key: key,
						value: item[key],
						original: item,
					}
				}
				return {
					key: item[this.settings.listItemKey],
					value: item[this.settings.listItemValueKey],
					original: item,
				}
			}

			return { key: item, value: item, original: item }
		},
		onAutocomplete: utils.throttleAsync(async function () {
			let res = await this.autocomplete(this.autocompleteText)
			if (!Array.isArray(res)) {
				console.warn('Autocomplete function must return an array')
			}

			this.autocompleteListItems = res || []
		}),
		async autocompleteInit() {

			if (typeof this.autocomplete == 'function') {
				let res = await this.autocomplete(this.value)
				if (!Array.isArray(res)) {
					console.warn('Autocomplete function must return an array')
				}

				this.autocompleteListItems = res || []
			}
		},
		//input event handler
		onInput(e) {
			this.autocompleteText = e.target.value
			this.hasFocus = true

			if (this.settings.autocomplete) {
				if (typeof this.autocomplete == 'function') {
					this.onAutocomplete()
				}
				this.$emit('autocomplete', this.autocompleteText)
			}

			if (this.isInputListItem) {
				if (this.settings.inputListItemValidateOn == 'input') {
					this.inputListItemValidateValue()
				}
			} else {
				this.open = true
			}
		},
		onInputArrowDown() {
			if (this.open) {
				this.onLiFocusNext(0)
				return
			}
			this.activeListItem = null
			this.open = true
		},
		onInputEsc: function (e) {
			//e.stopPropagation()
			this.blur()
			this.$emit('esc')
		},
		onInputBlur(e) {
			if (this.settings.inputListItem) {
				if (this.settings.inputListItemValidateOn == 'blur' && this.inputListItemValidateValue()) {
					this.$emit('input', this.autocompleteText)
				}
			}
		},

		onClear() {
			this.autocompleteText = ''
			this.onItemSelect(null)
		},
		onInputClick() {
			setTimeout(() => {
				// prevents false outsideClick detection

				//do not open and return on readonly
				if (this.settings.readonly) {
					this.hasFocus = false
					return
				}

				//reset activeListItem
				if (this.open === false) {
					this.activeListItem = null
					this.hasFocus = true
				}

				// if inputListItem
				if (this.isInputListItem) {
					//for arrow - click:

					if (this.open == false) {
						this.open = true
						return
					}

					this.$nextTick(() => {
						this.$refs.input.focus()
					})

					return
				}

				if (this.settings.autocomplete) {
					if (this.open === false) {
						this.autocompleteText = ''
						this.$nextTick(() => {
							this.$refs.input.focus()
						})
					}

					this.open = !this.open
					this.onToggle()
					return
				}

				this.open = !this.open
				this.onToggle()
			}, 1)
		},
		onMouseDown(e) {
			if (this.settings.inputListItem) e.preventDefault()
		},
		//li event handler
		onLiClick(listItem) {
			this.onItemSelect(listItem)
		},
		onLiEnter(listItem) {
			this.onItemSelect(listItem, true)
		},
		onLiEsc() {
			this.open = false
			this.$refs.input.focus()
		},
		inputListItemValidateValue() {
			// no validator configured => return true
			if (typeof this.settings.inputListItemValidator != 'function') {
				this.validationError = false
				return true
			}
			// if validate on configured
			if (this.settings.inputListItemValidateOn == 'input' || this.settings.inputListItemValidateOn == 'blur') {
				let isValid = this.settings.inputListItemValidator(this.autocompleteText)
				if (isValid === true) {
					this.validationError = false
					if (this.settings.inputListItemValidateOn == 'input') {
						this.$emit('input', this.autocompleteText)
					}
					return true
				} else {
					this.validationError = isValid
					return false
				}
			}
			// return true
			this.validationError = false
			return true
		},
		onItemSelect(listItem, isEnter) {

			this.selectedListItem = listItem
			this.selectedOriginalItem = listItem ? listItem.original : null
			this.validationError = false

			// emit (set) value and close dropdown
			if (this.settings.returnListItem) {
				if (this.settings.inputListItem) {
					console.error('Settings inputListItem and returnListItem can not be set at the same time')
					return
				}

				this.$emit('input', this.selectedListItem ? this.selectedListItem.original : null)
				this.$emit('change', this.selectedListItem ? this.selectedListItem.original : null)
				if (isEnter) {
					this.onEnter(this.selectedListItem ? this.selectedListItem.original : null)
				} else if (this.settings.focusNext) {
					this.onFocusNext()
				}
				this.blur()
			} else if (this.isInputListItem) {
				if (this.inputListItemValidateValue()) {
					this.$emit('input', this.autocompleteText || null)
					this.$emit('change', this.autocompleteText || null)
					if (isEnter) {
						this.onEnter(this.autocompleteText || null)
					} else if (this.settings.focusNext) {
						this.onFocusNext()
					}
				}
				this.open = false
				this.$refs.input.focus()
			} else {
				// default / legacy
				let item = {
					...this.selectedListItem,
				}
				delete item.original
				this.$emit('input', item.key)
				this.$emit('change', item.key)
				if (isEnter) {
					this.onEnter(item.key)
				} else if (this.settings.focusNext) {
					this.onFocusNext()
				}
				this.blur()
			}
		},
		onToggle() {
			if (!this.open) {
				this.blur()
			}
		},
		// simple Object == { 1: 'value'}
		isSimpleObject(item) {
			return Object.keys(item).length == 1
		},
		onLiFocusNext(id) {
			//return if no listItems
			if (this.dropDownList.length == 0) {
				this.activeListItem = null
			}
			//set / increment listItem Index
			if (typeof id == 'number') {
				this.activeListItem = id
			}
			//return to start if end is reaches
			if (this.dropDownList.length - 1 < this.activeListItem) {
				this.activeListItem = 0
			}
			//return to input if activeListItem < 0
			if (this.activeListItem < 0) {
				this.activeListItem = null
				this.$refs.input.focus()
				return
			}

			this.$refs['listItem'][this.activeListItem].focus()
		},
		//general event handler
		onOutsideClick(event) {
			if (this.$el.contains(event.target)) return
			if (this.open) {
				this.open = false
				if (!this.$el.contains(event.target)) {
					this.hasFocus = false
				}
			}
			if (!this.isInputListItem) this.blur()
		},
		//returns listItem by key
		getValueByKey(key) {
			let found = this.dropDownListData.find(element => {
				return element.key == key
			})
			return found ? found.value : undefined
		},
		getKeyByValue(value) {
			let found = this.dropDownListData.find(element => {
				return element.value == value
			})
			return found ? found.key : undefined
		},
		focus() {
			// if items are present from last search filter to element shown
			if (this.autocompleteText) {
				this.autocompleteListItems = this.autocompleteListItems.filter(item => {
					let value = item[this.settings.listItemKey]
					return value.trim().toLowerCase().includes(this.autocompleteText.trim().toLowerCase())
				})
			} else {
				this.autocompleteListItems = []
			}

			this.autocompleteText = ''
			if (!this.open) {
				this.onInputClick()
				this.$emit('focus')
				return
			}
			this.$refs.input.focus()
			this.hasFocus = true
			this.$emit('focus')
		},
		onFocus(event) {
			if (this.settings.readonly) return

			this.autocompleteText = ''
			this.hasFocus = true
			this.$emit('focus')
		},
		onBlur: function () {
			// input blur fn
			this.$refs.input.blur()
		},
		blur() {
			// closes and blurs the element
			this.open = false
			this.hasFocus = false
			if (this.settings.returnListItem) {
				if (this.settings.inputListItem) {
					console.error('Settings inputListItem and returnListItem can not be set at the same time')
					return
				}
				let value = this.selectedListItem ? this.selectedListItem.original : null
				this.$refs.input.blur()
				this.$emit('blur', this.$el, value)
			} else {
				// default / legacy
				if (this.isInputListItem && this.inputListItemValidateValue()) {
					this.open = false
					this.$refs.input.blur()
					this.$emit('blur', this.$el, this.autocompleteText || null)
					//this.$emit("input", this.);
					return
				}
				let item = {
					...this.selectedListItem,
				}
				delete item.original
				this.$refs.input.blur()
				this.$emit('blur', this.$el, item.key)
			}
		},
		onKeyupEnter() {

			// if only one item is shown dropdown => assume user wants to select this one
			if (this.settings.autocomplete && this.dropDownList.length == 1) {
				this.onItemSelect(this.dropDownList[0], true) // on enter flag is set
				// onItemSelect calls onEnter and blur 
				return
			}
			if (this.settings.autocompleteClearable) {
				this.onItemSelect(null, true) // on enter flag is set
				// onItemSelect calls onEnter and blur 
				return
			}

			this.$emit('enter',)
			this.blur()
		},
		onEnter(emitValue) {
			this.$emit('enter', emitValue)
			if (this.settings.focusNext) {
				this.onFocusNext()
			}
		},
		onFocusNext() {
			let focusableQuery =
				'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), textarea:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
			let focusable = Array.from(document.querySelectorAll(focusableQuery));

			// filter out li from this component
			focusable = focusable.filter(el => {
				if (el == this.$refs.input) return true
				return !this.$el.contains(el)
			})
			// filter out tabindex 0
			let tabindex0 = focusable.filter(el => {
				return el.tabIndex == 0
			})
			// filter and sort by tabindex
			let tabindexSet = focusable.filter(el => {
				return el.tabIndex > 0
			}).sort((a, b) => {
				return a.tabIndex - b.tabIndex
			})
			// concat and find next
			focusable = tabindex0.concat(tabindexSet)
			let index = focusable.indexOf(this.$refs.input)
			let next = focusable.length > index + 1 ? focusable[index + 1] : focusable[0]
			if (next) {
				next.focus()
			}

		},
		onValueChange(value) {

			if (!value) {
				// empty ListItem allows null values
				if (this.settings.emptyListItem || this.settings.clearable) {
					if (!this.selectedListItem) return
				}
				// no listitems allow  !value
				if (this.listItems.length == 0 && !this.selectedListItem) return
			}

			if (typeof value == 'undefined') {
				if (!this.isInputListItem) {
					this.selectedListItem = null
					this.selectedOriginalItem = null
					this.autocompleteListItems = []
				}

				// is allowed to set null or undefined
				if (this.settings.emptyListItem || this.settings.clearable) {
					this.error = null // cancel out error
					return
				}
				// set error for element not found
				if (this.settings.validateOnValueChange && !this.settings.inputListItem) {
					this.error = this.settings.errorNotSet
				}

				return
			}

			this.setSelectedListItem(value)
		},
		setSelectedListItem(value) {
			let listItem

			if (this.listItemsType && this.settings.returnListItem) {
				let l = this.mapToInternalListItem(value)
				listItem = this.dropDownListData.find(item => {
					return item.key == l.key
				})
			} else {
				listItem = this.dropDownListData.find(item => {
					if (value === false) {
						return item.key == 'false'
					}
					return item.key == value
				})
			}

			// listItem not found && inputListItem mode
			if (!listItem && this.settings.inputListItem) {
				this.selectedListItem = this.inputListItem
				this.autocompleteText = value
				this.inputListItemValidateValue()
				return
			} else {
				// reset validationError
				this.validationError = false
			}
			//prevent error if in autocomplete mode
			if (!value && this.settings.autocomplete && !listItem) {
				this.error = null
				this.selectedListItem = null
				this.selectedOriginalItem = null
				return
			}
			if (this.settings.autocomplete) {
				this.error = null
			} else {
				this.error = listItem ? null : this.settings.errorNotFound
			}
			this.selectedListItem = listItem
			this.selectedOriginalItem = listItem ? listItem.original : null
		},
		setAutoWidthText(value) {
			//this.$refs.autogrow.dataset.value = value
		},
		findListItemInternal(value) {
			if (Array.isArray(this.listItemsInternal)) {
				return this.listItemsInternal.find(item => {
					return item[this.settings.listItemKey] == value
				})
			}
		},
	},
	computed: {
		/**
		 * filter listData for display
		 */
		dropDownList: function () {
			if (this.isInputListItem) {
				return this.dropDownListData
			}
			if (this.settings.autocomplete && this.autocompleteText && typeof this.autocomplete != 'function') {
				return this.dropDownListData.filter(element => {
					//filter by autocomplete text
					if (element.value.trim().toLowerCase().includes(this.autocompleteText.trim().toLowerCase())) return true
					if (this.settings.autocompleteKeys) {
						if (!element.key) return true
						if (element.key.trim().toLowerCase().includes(this.autocompleteText.trim().toLowerCase())) return true
					}
					return false
				})
			}
			return this.dropDownListData
		},
		listItemsHasMore: function () {
			return this.dropDownList.length < this.dropDownListData.length
		},
		listItemsInternal() {
			if (typeof this.autocomplete == 'function' && this.autocompleteListItems.length > 0) {
				return this.autocompleteListItems
			}

			return this.settings.listItems
		},
		/**
		 * normalize provided data for use in dropdown
		 */
		dropDownListData: function () {
			// listItems == Array
			if (Array.isArray(this.listItemsInternal) && this.listItemsInternal.length > 0) {
				let listItems = this.listItemsInternal.map(item => {
					return this.mapToInternalListItem(item)
				})

				if (this.settings.inputListItem) listItems.push(this.inputListItem) // add behind
				if (this.settings.emptyListItem)
					listItems = [{ key: null, value: this.settings.emptyListItemText }].concat(listItems)
				return listItems
			}

			if (typeof this.listItemsInternal == 'object') {
				let listItems = Object.keys(this.listItemsInternal).map(key => {
					return { key: key, value: this.listItemsInternal[key] }
				})
				if (this.settings.inputListItem) listItems.push(this.inputListItem)
				if (this.settings.emptyListItem)
					listItems = [{ key: null, value: this.settings.emptyListItemText }].concat(listItems)
				return listItems
			}
			return []
		},
		inputListItem() {
			return { key: '$input', value: this.settings.inputListItemText || '' }
		},
		listItemsType() {
			if (Array.isArray(this.listItemsInternal) && this.listItemsInternal.length > 0) {
				return 'array.' + typeof this.listItemsInternal[0]
			}
			if (typeof this.listItemsInternal == 'object') return 'object'
			return null
		},
		/**
		 * what to display at the input
		 */
		displayText: function () {
			// open and typing in autocomplete mode
			if (this.open && this.settings.autocomplete) {
				return this.autocompleteText
			}
			if (this.isInputListItem) {
				return this.autocompleteText || ''
			}
			if (this.selectedListItem) {
				return this.selectedListItem.value
			}
			if (!this.value) {
				return this.settings.emptyListItem ? this.settings.emptyListItemText : ''
			}
			if (this.settings.returnListItem) {
				return this.value[this.settings.listItemValueKey]
			}
			// we can also query listItemsInternal to get displayText from set value
			let listItem = this.findListItemInternal(this.value)
			if (listItem) {
				return listItem[this.settings.listItemValueKey]
			}
			return this.value
		},
		notificationText: function () {
			if (this.error) return this.error
			if (this.validationError) return this.validationError
			if (this.settings.notification) return this.settings.notification
			return ''
		},
		isReadonly: function () {
			if (this.settings.readonly) return true
			if (this.isInputListItem) return false
			if (this.settings.autocomplete == false) return true
			if (!this.open) return true
			return false
		},
		isInputListItem() {
			return this.selectedListItem ? this.selectedListItem.key == this.inputListItem.key : false
		},
		/**
		 * merged settings
		 */
		settings: function () {
			let settings = {
				anchorEl: this.anchorEl,
				readonly: this.readonly,
				// autocomplete
				autocomplete: false,
				autocompleteKeys: false, // match against listItem.key also, not only value
				autocompleteClearable: false, // can be cleared by pressing enter
				autocompleteFnOnInit: true, // update autocompleteListItems with this.autocomplete on init

				activeColor: '#619cca',

				errorNotFound: 'list element not found',
				errorNotSet: 'no list item set',
				label: this.label,
				align: 'left',
				maxListItems: 10,
				listItemHeight: 36,

				// inputlistItem lets user select a listentry that switches to input mode (input any value)
				inputListItem: false, // true
				inputListItemValidator: false, // validator for inputListItem
				inputListItemValidateOn: 'blur', // blur | input

				notification: this.notification,
				notificationIsError: this.notificationIsError,

				placeholder: this.placeholder,
				listItems: this.listItems,
				listItemKey: 'key',
				listItemValueKey: 'value',
				returnListItem: false,
				style: undefined,
				datatable: false,
				triangle: true, // if false: never show triangle
				clearIcon: false, // always shows clear icon

				clearable: false, // value can be cleared be pressing x
				emptyListItem: false, // add an emptyListItem to list (needs emptyListItem)
				emptyListItemText: '',
				nullIsValue: false, // treat null as value and search in listItems
				// value == undefined => no error display
				validateOnValueChange: false, // allow :value to be null / undefined independent of emptyListItem
				focusNext: false, // focus next element on enter or select
				width: this.width || 185,
				...this.options,
			}
			if (this.readonly) settings.readonly = true
			if (this.emptyListItem) settings.emptyListItem = this.emptyListItem
			return settings
		},
		classes: function () {
			return {
				focus: this.hasFocus,
				open: this.open,
				autocomplete: this.settings.autocomplete,
				readonly: this.settings.readonly,
				[this.settings.align]: true,
				datatable: this.settings.datatable,
			}
		},
		popoverHeight: function () {
			if (this.settings.maxListItems && this.scrollbarVertical) {
				return this.settings.maxListItems * this.settings.listItemHeight + 'px'
			}
			return null
		},
		popoverWidth() {
			return `${this.$refs.autogrow.offsetWidth}px`
		},
		popoverOffset() {
			let obj = {}
			if (this.$el.classList.contains('table')) obj.left = -3
			if (this.$el.classList.contains('userbadge')) obj.top = 6
			if (this.settings.popoverOffsetTop) obj.top = this.settings.popoverOffsetTop
			if (this.settings.popoverOffsetLeft) obj.left = this.settings.popoverOffsetLeft
			if (this.$el.classList.contains('rounded')) {
				obj.top += 3
				obj.left = -3
			}
			return obj
		},
		scrollbarVertical: function () {
			if (this.dropDownList.length > this.settings.maxListItems) return true
			return false
		},
		showTriangleIcon() {
			if (this.settings.readonly) return false
			if (this.settings.triangle === false) return false
			if (this.settings.style == 'datatable') return false
			if (this.settings.clearable && this.value) return false
			if (this.settings.clearIcon) return false
			// if (this.settings.autocomplete) {
			// 	return this.settings.emptyListItem;
			// }
			return true
		},
		showClearIcon() {
			if (this.settings.readonly) return false
			if (this.settings.clearIcon) return true // overrides everything
			if (this.settings.clearable && this.value) return true
			if (this.settings.autocomplete && this.settings.autocompleteClearable) {
				if (this.open) return true
				return this.autocompleteText
			}
			return false
		},
		style() {
			let style = {
				['--border-color']: this.settings.activeColor,
			}
			if (this.settings.width) {
				style.width = this.settings.width
			}
			return style
		},
	},
	watch: {
		value: function (value) {
			this.onValueChange(value)
		},
		dropDownListData: {
			deep: false,
			handler: function () {
				// if listItems get updated after mounted, we need to update selectedListItem after
				if (this.selectedListItem && this.value) {
					this.setSelectedListItem(this.value)
				}
			},
		},
		displayText(value) {
			this.setAutoWidthText(value)
		},
	},
	created() {
		if (this.settings.autocomplete) {
			if (typeof this.autocomplete == 'function' && this.settings.autocompleteFnOnInit) {
				this.autocompleteInit()
			}
		}
	},
	mounted: function () {
		this.onValueChange(this.value)
	},
}
</script>

<style scoped>
.Dropdown {
	display: inline-flex !important;
	flex-direction: column !important;
	align-items: flex-start !important;
	position: relative !important;
	gap: 0 !important;
	font-size: 1rem;
	width: 185px;
}

.Dropdown.rounded {
	height: 36px;
	border: 1px solid rgb(217, 217, 217);
	border-radius: 18px;
	padding: 0 15px
}

.Dropdown.rounded .autogrow {
	margin-top: 0;
}

.Dropdown.flex {
	width: unset;
	min-width: 120px;
}

.Dropdown.datatable,
.Dropdown.table,
.Dropdown.slim,
.Dropdown.userbadge,
.Dropdown.rounded {
	justify-content: center;
}

.Dropdown.slim .input::before,
.Dropdown.slim .input::after,
.Dropdown.slim .notification,
.Dropdown.userbadge .input::before,
.Dropdown.userbadge .input::after,
.Dropdown.userbadge .notification,
.Dropdown.datatable .input::before,
.Dropdown.datatable .input::after,
.Dropdown.table .input::before,
.Dropdown.table .input::after,
.Dropdown.table .notification,
.Dropdown.rounded .input::before,
.Dropdown.rounded .input::after,
.Dropdown.rounded .notification {
	display: none;
}

.Dropdown.datatable input,
.Dropdown.slim input,
.Dropdown.userbadge input {
	padding: 0 5px 0 5px;
	font-size: inherit;
}

.Dropdown.noNotification .notification {
	display: none;
}

.Dropdown.table input {
	padding: 0;
	font-size: inherit;
}

.Dropdown.noLabel label {
	display: none;
}

input {
	outline: none;
	border: 0;
	font-size: 1rem;
	line-height: 20px;
	padding: 8px 0px 7px 0px;
	cursor: pointer;
	transition: width 0.5s ease;
	flex: 1 1 auto;
	position: relative;
	min-width: 0px;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	background-color: transparent;
}

.autogrow {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	width: 100%;
	position: relative;
	margin-bottom: -1px;
	margin-top: 4px;
}

.autogrow::after {
	width: auto;
	min-width: 30px;
	font: inherit;
	font-size: inherit;
	padding: 0;
	margin: 0;
	resize: none;
	background: none;
	appearance: none;
	border: none;
	content: attr(data-value) ' ';
	visibility: hidden;
	white-space: nowrap;
	height: 1px;
}

.Dropdown.left input {
	text-align: left;
}

.dropdownList.left li {
	text-align: left;
}

.Dropdown.center input {
	text-align: center;
}

.dropdownList.center li {
	text-align: center;
}

.Dropdown.right input {
	text-align: right;
}

.dropdownList.right li {
	text-align: right;
}

.Dropdown.autocomplete.open input {
	cursor: text;
}

.input {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
	padding: 0;
	width: 100%;
	border-color: var(--border-color);
}

.input::before {
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
	border-style: solid;
	border-width: 1px 0 0 0;
	border-color: inherit;
	z-index: 1;
}

.Dropdown.readonly .input {
	border-color: rgba(0, 0, 0, 0.42);
}

.input::after {
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
	transform: scaleX(0);
	border-style: solid;
	border-width: 1px 0 1px 0;
	border-color: inherit;
	z-index: 2;
}

.Dropdown.focus .input::after {
	transform: scaleX(1);
}

.Dropdown.readonly input,
.Dropdown.readonly .triangle,
.Dropdown.readonly .input {
	cursor: default;
}

.Dropdown.readonly .triangle {
	border-top-color: #b1b1b1;
}

label {
	font-size: 12px;
	max-width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
	top: 6px;
	white-space: nowrap;
	pointer-events: none;
	color: rgba(0, 0, 0, 0.54);
	padding: 0 5px 0 0;
}

.before {
	margin-right: 8px;
}

.Dropdown.open .triangle {
	transform: rotate(-180deg);
}

.triangle {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 15px;
	height: 100%;
}

.triangle>.icon {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 5px 0 5px;
	border-color: rgba(0, 0, 0, 0.54) transparent transparent transparent;
	cursor: pointer;
	transition: transform 0.5s ease;
}

.popover {
	overflow: hidden;
}

.notification {
	font-size: 12px;
	line-height: 12px;
	margin-top: 5px;
	height: 12px;
	padding: 0 5px 0 0;
}

.notification.error {
	color: red;
}

.dropdownList {
	position: absolute;
	display: inline-block;
	border-radius: 2px;
	will-change: transform;
	padding: 0;
	margin: 0;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
	z-index: 100;
}

.dropdownList ul {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden auto;
	contain: content;
	margin: 0;
	padding: 0;
}

.dropdownList ul li {
	list-style: none;
	font-size: 15px;
	font-weight: 400;
	height: 36px;
	margin: 0px;
	padding: 0px 5px;
	text-decoration: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	line-height: 36px;
	cursor: pointer;
	min-width: 100%;
	max-width: 100%;
	outline: none;
}

.dropdownList ul li:hover,
.dropdownList ul li:focus {
	background-color: rgba(0, 0, 0, 0.04);
}

.scrollbar {
	position: absolute;
	top: 0;
	right: 0;
	width: 10px;
	height: 100%;
	background-color: aqua;
}

.Icon.close {
	font-size: 12px;
	cursor: pointer;
	margin: 0 3px;
}

.anchor {
	position: relative;
}
</style>
