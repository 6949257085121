<template>
  <div class="checkbox CheckBox form"
    :class="{ checked: isChecked, readonly: readonly }">
    <label class="chkbx"
      @click.stop="onClick">
      <div class="box"
        :style="boxStyle">
        <Icon :icon="iconCheck" />
      </div>
      <div class="text"
        v-if="$scopedSlots.default">
        <slot></slot>
      </div>
    </label>
  </div>
</template>

<script>
import Icon from "@icons/Icon.vue";
import { SkypeCheck } from "@icons/appFabric/icons";
export default {
  name: "CheckBox",
  components: { Icon },
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      required: false,
    },
    checked: {
      required: false,
      default: false,
    },
    true: {
      required: false,
      default: true,
    },
    false: {
      required: false,
      default: false,
    },
    options: {
      type: Object,
      required: false,
    },
  },
  data: function () {
    return {
      iconCheck: SkypeCheck,
    };
  },
  methods: {
    onClick() {
      if (this.readonly) {
        // click event is alway emitted, input on change only
        this.$emit("click", this.isChecked ? this.settings.true : this.settings.false);
        return;
      }

      if (this.isChecked) {
        //set false
        //uncheck function:
        if (this.value == this.settings.true) {
          this.$emit("update:value", this.settings.false);
          this.$emit("input", this.settings.false);
          this.$emit("click", this.settings.false);
        }
      } else {
        //set true
        this.$emit("update:value", this.settings.true);
        this.$emit("input", this.settings.true);
        this.$emit("click", this.settings.true);
      }
    },
  },
  computed: {
    settings() {
      return {
        readonly: this.readonly,
        checked: this.checked ? true : false,
        true: this.true,
        false: this.false == "undefined" ? undefined : this.false,

        colorReadOnlyBorder: "rgba(0, 0, 0, 0.42)",
        colorReadOnlyUnchecked: "none",
        colorReadOnlyChecked: "rgba(0, 0, 0, 0.42)",

        colorBorder: "var(--actionColorDark)",
        colorArrow: "white",
        colorUnchecked: "none",
        colorChecked: "var(--actionColorDark)",

        ...this.options,
      };
    },
    isChecked() {
      return this.value == this.settings.true ? true : false;
    },
    boxStyle() {
      if (this.settings.readonly) {
        return {
          borderColor: this.settings.colorReadOnlyBorder,
          backgroundColor: this.isChecked
            ? this.settings.colorReadOnlyChecked
            : this.settings.colorReadOnlyUnChecked,
        };
      }
      return {
        borderColor: this.settings.colorBorder,
        backgroundColor: this.isChecked ? this.settings.colorChecked : this.settings.colorUnChecked,
        color: this.settings.colorArrow,
      };
    },
    labelStyle() {
      return {};
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: inline-flex;
}

.checkbox label {
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  text-align: left;
  user-select: none;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  text-decoration: none;
  color: unset;
  max-width: unset;

}

.checkbox .box {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  box-sizing: border-box;
  transition-property: background, border, border-color;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.23, 1);
  margin-right: 4px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 2px;
  overflow: hidden;
  font-size: 14px;
  margin-right: 7px;
}

.Icon {
  display: none;
}

.checkbox.small .box {
  margin-left: 3px;
}

/* small */
.checkbox.small .box {
  height: 14px;
  width: 14px;
  font-size: 11px;
}

.checkbox.checked .box .Icon {
  display: flex;
}

.checkbox.readonly.checked .box .Icon {
  display: flex;
}

.checkbox.readonly label {
  cursor: inherit;
}

.checkbox.readonly:not(.checked) label:hover .box .Icon {
  display: none;
}

.text {
  line-height: 100%;
}</style>