var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"UserSwitch",class:{ proxy: _vm.proxyInfo },on:{"click":_vm.onShowDialog}},[_c('div',{staticClass:"toolbarIcon"},[_c('Icon',{attrs:{"icon":"iconSwitchUser"}})],1),(_vm.showDialog)?_c('Dialog',{staticClass:"blue",attrs:{"header":_vm.$t('dialogTitle'),"transferTo":"modal","top":"10vh"},on:{"close":function($event){_vm.showDialog = false}}},[_c('div',{staticClass:"dlgBody"},[_c('Dropdown',{ref:"Dropdown",staticClass:"userId",attrs:{"label":"User","placeholder":"Suche","options":{
					autocomplete: true,
					listItems: _vm.userList,
					listItemKey: 'persNo',
					listItemValueKey: 'name',
					returnListItem: true,
					autocompleteKeys: true,
				},"autocomplete":_vm.onAutocomplete},on:{"enter":_vm.onUserSwitch,"hook:mounted":_vm.onDropdownMounted},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('Button',{staticClass:"blue",on:{"click":_vm.onUserSwitch}},[_vm._v(_vm._s(_vm.$t('userSwitch')))]),_c('Button',{staticClass:"red",on:{"click":_vm.onUserSwitchBack}},[_vm._v(_vm._s(_vm.$t('userSwitchBack')))])],1)]):_vm._e(),(_vm.proxyInfo)?_c('div',{staticClass:"proxyInfo"},[_c('div',{staticClass:"userId"},[_vm._v(_vm._s(_vm.proxyInfo))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }