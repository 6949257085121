<template>
   <div class="BchOrder">
      <div class="header"
         @click="onHeaderClick">
         <div class="name">{{ order.bezeichnung }}</div>
         <div v-if="uploadBtn"
            class="uploadBtn"
            @click.stop>
            <Button class="blue"
               @click="$emit('upload')">Upload</Button>
         </div>
         <div class="chf">CHF</div>
         <div class="price">{{ order.preis }}</div>
      </div>
      <SlideDown :show="showDetails">
         <div class="created">
            <div class="text">Erfasst: </div>
            <div class="date">{{ toDate(order.erstellt_am) }}</div>
            <div class="orderBy">{{ order.sysuser_name }}</div>
            <div class="option">({{ order.gewaehlte_option }})</div>
         </div>
         <div class="details">
            <BchService v-for="service in order.services"
               :key="service.id"
               :service="service" />
         </div>
      </SlideDown>
   </div>
</template>

<script>
import { Day } from '@SyoLab/date-time'
import SlideDown from '@components/Containers/SlideDown.vue'
import BchService from './BchService.vue'
import Button from '@components/Form/Button.vue'
export default {
   name: 'BchOrder',
   components: {
      SlideDown,
      BchService,
      Button
   },
   props: {
      order: { required: true },
      uploadBtn: { required: false },
      showCollapsed: { required: false, default: false }
   },
   data() {
      return {
         showDetails: false
      }
   },
   methods: {
      onHeaderClick() {
         this.showDetails = !this.showDetails
      },
      toDate(date) {
         return new Day(date).toEuDate()
      }
   },
   computed: {},
   created() {
      if (!this.showCollapsed) {
         this.showDetails = true
      }
   }
}
</script>

<style scoped>
.BchOrder {
   display: flex;
   flex-direction: column;
   min-width: 400px;
}

.header {
   display: flex;
   align-items: center;
   column-gap: 5px;
   cursor: pointer;
}

.header .name {
   flex: 1;
   font-weight: 500;
   font-size: 20px;
   color: #474747;
}

.header .price {
   width: 60px;
   text-align: right;
}

.details {
   display: flex;
   flex-direction: column;
   row-gap: 15px;
}

.created {
   display: flex;
   column-gap: 10px;
   align-items: center;
   font-size: 14px;
   padding-bottom: 11px;
   padding-top: 1px;
}

.uploadBtn {
   display: flex;
   align-items: center;
}

.uploadBtn .Button {
   font-size: 12px;
   height: 20px;
   min-width: 60px;
   padding: 0;
}
</style>