import { array, clone } from "@SyoLab/utils"
import Vue from 'vue'

export function getStatusBgColor(status) {

   switch (status) {
      case 'new':
         return '#f5e0e9'
      case 'notStarted':
         return '#e3e2e0'
      case 'wait':
         return '#fdecc8'
      case 'wip':
         return '#d3e5ef'
      case 'done':
         return '#dbeddb'
      case 'billed':
         return '#eee0da'
      case 'paid':
         return '#eee0da'
      default:
         return 'grey'
   }

}

export function getProjectStatus(services) {

   // add project.status, start checking from the strictest status
   switch (true) {
      case !services || services.length == 0:
         return 'new'
      case services.every(s => s.status == 'paid'):
         return 'paid'
      case services.every(s => s.status == 'billed'):
         return 'billed'
      case services.every(s => s.status == 'done'):
         return 'done'
      // from here check for first occurence of status
      case services.some(s => s.status == 'wip'):
         return 'wip'
      case services.some(s => s.status == 'wait'):
         return 'wait'
      case services.some(s => s.status == 'notStarted'):
         return 'notStarted'
      case services.some(s => s.status == 'new'):
         return 'new'
   }
}





export function toDbServiceObject(data) {
   return Object.fromEntries(
      Object.entries(data)
         .filter(([key, value]) => {
            return key == '_id' || !key.startsWith('_')
         }).map(items => {
            // clone array
            if (Array.isArray(items[1])) {
               items[1] = clone(items[1])
            }
            return [...items]
         }))
}

export function popupOpen(window, url, name, { height, width } = {}) {

   let centerLeft = window.screenLeft + (window.innerWidth / 2);
   let centerTop = window.screenTop + (window.innerHeight / 2);
   let left = centerLeft - (width / 2);
   let top = centerTop - (height / 2);
   // let params = `scrollbars=no,status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height},left=${left},top=${top}`;
   let params = `popup=true,width=${width},height=${height},left=${left},top=${top}`;
   if (!url.startsWith('/') && !url.startsWith('http')) url = '/' + url
   return window.open(`${window.location.origin}${url}`, name, params);
}

export function serviceSort(services) {
   // group by project_id
   let servicesGrouped = array.groupBy(services, 'project_id')
   for (const project_id in servicesGrouped) {
      servicesGrouped[project_id].sort((a, b) => {
         // project first
         if (a.type == 'project' && b.type != 'project') return -1
         if (a.type != 'project' && b.type == 'project') return 1

         // billNo first
         if (a.billNo && !b.billNo) return -1
         if (!a.billNo && b.billNo) return 1

         // the sort by order
         if (a.order < b.order) return -1
         if (a.order > b.order) return 1
         // then sort by timeStamp
         if (a.createdTimeStamp && !b.createdTimeStamp) return -1
         if (!a.createdTimeStamp && b.createdTimeStamp) return 1
         if (a.createdTimeStamp < b.createdTimeStamp) return -1
         if (a.createdTimeStamp > b.createdTimeStamp) return 1
         return 0
      })
   }
   let ret = []

   Object.keys(servicesGrouped).sort((a, b) => {
      if (a == 'null' && b) return 1
      if (a && b == 'null') return -1
      return 1
   }).forEach(project_id => {
      ret.push(...servicesGrouped[project_id])
   })
   return ret
}

export function editServiceUpdate(service, editService) {

   for (const key in service) {
      // array entrys
      if (Array.isArray(service[key])) {
         // sync array
         let record_ids = new Set([...service[key].map(e => e._id), ...editService[key].map(e => e._id)])
         record_ids.forEach(_id => {
            let serviceRecord = service[key].find(e => e._id == _id)
            let editServiceRecord = editService[key].find(e => e._id == _id)
            // add to editService if serviceRecord exists and editServiceRecord does not exist
            if (serviceRecord && !editServiceRecord) {
               editService[key].push(serviceRecord)
               // remove from editService if record does not exist in service, leave if any changes were made by user
            } else if (!serviceRecord && editServiceRecord && !editServiceRecord._hasChanges) {
               let idx = editService[key].findIndex(e => e._id == _id)
               editService[key].splice(idx, 1)
            } else if (serviceRecord && editServiceRecord && !editServiceRecord._hasChanges) {
               // sync record
               for (const recordKey in serviceRecord) {
                  if (recordKey.startsWith('_')) continue
                  if (serviceRecord[recordKey] != editServiceRecord[recordKey]) {
                     Vue.set(editServiceRecord, recordKey, serviceRecord[recordKey])
                  }
               }
            }
         })
      } else if (editService[key] !== service[key]) {
         Vue.set(editService, key, service[key])
      }
   }
}

export function serviceHasChanges(service, existingService) {
   if (!service || !existingService) return true
   return objectHasChanges(service, existingService)
}

function objectHasChanges(a, b) {
   let compareKeys = getCompareKeys(a, b)
   return compareKeys.some(key => {
      if (Array.isArray(a[key])) {
         return JSON.stringify(a[key]) != JSON.stringify(b[key])
      }
      if (typeof a[key] == 'object' && a[key] != null) {
         return objectHasChanges(a[key], b[key])
      }
      return a[key] != b[key]
   })
}

function getCompareKeys(service, existingService) {
   let keys = [...new Set([...Object.keys(service), ...Object.keys(existingService)])]
   // filter non compare keys
   return keys.filter(key => {
      if (key.startsWith('_')) return false
      if (key == 'timeStamp') return false
      return true
   })
}
