import Vue from 'vue'

const doubleRequestAnimationFrame = (callback) => {
   requestAnimationFrame(() => {
      requestAnimationFrame(callback)
   })
}
//how to use:
//https://github.com/twickstrom/vue-force-next-tick
export function displayUpdate(callback) {
   if (callback && typeof callback === 'function') {
      doubleRequestAnimationFrame(callback)
   } else {
      return new Promise(resolve => {
         doubleRequestAnimationFrame(resolve)
      })
   }
}


var timers = {}

export function delay(name, cb, delay) {
   //cancel existing timer
   if (timers[name]) clearTimeout(timers[name])
   //start new timer
   timers[name] = setTimeout(() => {
      cb();
      delete timers[name]
   }, delay)
}

export function delayClear(name) {
   //cancel existing timer
   if (timers[name]) clearTimeout(timers[name])
}


/**
 * lodash _set function for vue
 * @param {*} obj 
 * @param {string} path 
 * @param {*} value 
 * @returns object
 */
export function _set(obj, path, value) {
   if (!obj) throw new Error("input obj is required")

   function getNextKey(part, ref) {
      //array:
      if (isArrayPart(part)) {
         let index = part.match(/\[(.*)\]/)[1]
         //get next index
         if (index == "?") {
            return Array.isArray(ref) ? ref.length : 0
         }
         return index
      }
      //object:
      return part
   }

   function isArrayPart(part) {
      return part.match(/\[(.*)\]/) ? true : false
   }

   function isObject(test) {
      if (typeof test == 'object') {
         return test != null ? test.constructor.name === "Object" : false
      }
      return false

   }

   function getPathParts(path) {
      if (typeof path != 'string' || path.length < 2) return null
      // remove leading dot and split path by "." and "[]" (..= escaped)
      //return path.replace(/^\.?/, "").split(/(?<!\.)\.(?!\.)|(?=\[.)|(?<=\])/)
      //ie refactor
      let ret = []
      path.split(/(\[.*\])/).forEach(item => {
         //remove leading .
         if (item[0] == '.') item = item.substr(1)
         let parts = item.split('.')
         ret = ret.concat(parts)
      })
      return ret.filter(item => item !== '')
   }

   function getChildObjectType(part) {
      return isArrayPart(part) ? "array" : "object"
   }
   function isDefined(ref, objectType) {
      if (isObject(ref) && objectType == "object") return true
      if (Array.isArray(ref) && objectType == "array") return true
      return false
   }

   // remove leading dot and split path by "." and "[]"
   let pathParts = getPathParts(path)
   let ref = obj;

   pathParts.reduce((ref, part, i, parts) => {
      let key = getNextKey(part, ref)

      //assign value
      if (i == parts.length - 1) {
         //remove escaped dots in key
         key = key.replace("..", ".")
         Vue.set(ref, key, value)
         return
      }

      let childObjectType = getChildObjectType(parts[i + 1])
      //reference child object
      if (isDefined(ref[key], childObjectType)) {
         ref = ref[key]
         return ref
      }

      //create ArrayObject
      if (childObjectType == 'array') {
         Vue.set(ref, key, [])
         return ref = ref[key]
      }
      //create Object
      if (childObjectType == "object") {
         Vue.set(ref, key, {})
         return ref = ref[key]
      }

   }, ref)

   return obj
}


/**
 * gets values from object by path
 * 
 * _get({a:{b:"foo"}}, "a.b") => "foo"
 * 
 * Array notation either .0 or [0]
 * @param {obj, array} obj 
 * @param {string} path 
 */
export function _get(obj, path) {
   //remove first . and split path by "." and "[]"
   let pathParts = getPathParts(path)
   if (!pathParts || pathParts == "") return obj
   let ref = obj

   function getNextKey(part) {
      return part.match(/\[(.*)\]/) ? part.match(/\[(.*)\]/)[1] : part
   }

   function getPathParts(path) {
      if (typeof path != 'string' || path.length < 2) return null
      // remove leading dot and split path by "." and "[]" (..= escaped)
      //return path.replace(/^\.?/, "").split(/(?<!\.)\.(?!\.)|(?=\[.)|(?<=\])/)
      //ie refactor
      let ret = []
      path.split(/(\[.*\])/).forEach(item => {
         //remove leading .
         if (item[0] == '.') item = item.substr(1)
         let parts = item.split('.')
         ret = ret.concat(parts)
      })
      return ret.filter(item => item !== '')
   }

   return pathParts.reduce((ref, part, i, parts) => {
      let key = getNextKey(part)
      return ref ? ref[key] : undefined
   }, ref)
}



