<template>
   <div class="AllContacts">
      <div class="filter">
         <Dropdown width="450px"
            placeholder="Kunde"
            ref="customerSearch"
            :options="{
               autocomplete: true,
               listItemKey: '_id',
               listItemValueKey: 'nameCity',
               returnListItem: false,
               autocompleteKeys: true,
               triangle: false,
               autocompleteClearable: true,
            }"
            :autocomplete="onCustomerSearch"
            @input="onCompanySelect"
            @autocomplete="searchString = $event" />
         <DatePicker placeholder="Von"
            v-model="dateFrom"
            :options="{ format: 'toIsoDate', clearable: true }"
            @input="getData" />
         <DatePicker placeholder="Bis"
            v-model="dateTo"
            :options="{ format: 'toIsoDate', clearable: true }"
            @input="getData" />
      </div>
      <div class="filter">

      </div>
      <Grid :gridColumns="gridColumns"
         :data="gridRecords"
         @recordClick="onRecordClick">
         <template #before>
            <Dropdown class="noNotification"
               placeholder="Mitarbeiter"
               width="200px"
               :listItems="contactsList"
               :options="{ listItemKey: 'userId', listItemValueKey: 'userName', emptyListItem: true, clearable: true }"
               v-model="filterByUserId" />
         </template>
      </Grid>

   </div>
</template>

<script>
import api from '@components/Portal/api'
import Grid from '../dataGrid/Grid.vue'
import Dropdown from '@components/Form/Dropdown.vue';
import DatePicker from '@components/Form/DatePicker.vue';
import { Day } from '@SyoLab/date-time'
export default {
   name: 'AllContacts',
   components: { Grid, Dropdown, DatePicker },
   props: {},
   data() {
      return {
         filterByUserId: null,
         records: [],
         dateFrom: null,
         dateTo: null,
         customer_id: null,
         // params to display addButton 
         searchString: null,
         searchResult: [],
         isSearching: false,
      }
   },
   methods: {
      async getData() {

         if (!this.customer_id && !this.dateFrom) {
            this.records = []
            return
         }
         let records = await api.post('contacts/customerCommunication', {
            customer_id: this.customer_id,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
         })
         this.records = records
      },
      onRecordClick(record) {
         this.$router.push(`/customer/${record.customer_id}`)
      },
      async onCustomerSearch(searchString) {
         if (!searchString) {
            return []
         }
         this.isSearching = true
         let res = await api.get(`contacts/customerSearch/${searchString}`)
         this.searchResult = res
         this.isSearching = false
         return res
      },
      onCompanySelect(selectedItem) {
         this.searchString = null
         this.searchResult = []
         this.customer_id = selectedItem
         this.getData()
      },
   },
   computed: {
      gridColumns() {
         return [
            { name: 'customerName', grid: 'name', text: "Kunde", width: 'max-content', emit: 'recordClick' },
            { name: 'contactName', grid: 'contactName', text: "Kontakt", width: 'max-content', emit: 'recordClick' },
            {
               name: 'nextContactDate', grid: 'nextContactDate', text: "Nächster Kontakt", width: 'max-content', emit: 'recordClick', value(record, column) {
                  return record.nextContactDate ? new Day(record.nextContactDate).toEuDate() : null
               }
            },

            { name: 'userName', grid: 'userName', text: "Mitarbeiter", width: 'max-content', emit: 'recordClick' },
            {
               name: 'date', grid: 'date', text: "Datum", width: 'max-content', emit: 'recordClick', value(record, column) {
                  return record.date ? new Day(record.date).toEuDate() : null
               }
            },
            { name: 'comment', grid: 'comment', text: "Kommentar", width: '600px', emit: 'recordClick', style: { "white-space": "unset" } },
         ]
      },
      gridRecords() {
         if (this.showAll && !this.filterByUserId) return this.records
         let d = new Day().endOf('week').date
         return this.records.filter(record => {
            if (!this.showAll && record.nextContactDate > d) return false
            if (this.filterByUserId && record.userId != this.filterByUserId) return false
            return true
         })
      },
      contactsList() {
         let userMap = {}
         this.records.forEach(record => {
            if (!userMap[record.userId]) {
               userMap[record.userId] = {
                  userId: record.userId,
                  userName: record.userName,
               }
            }
         })
         return Object.values(userMap)
      }
   },
   created() {
      this.getData()
   },
}
</script>

<style scoped>
.AllContacts {}

.filter {
   display: flex;
   column-gap: 35px;
   align-items: center;
}
</style>