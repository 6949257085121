


import ErrorLoggerWorker from 'worker-loader!./logger/errorLogger.js';
const errWorker = new ErrorLoggerWorker();

import Bowser from "bowser"

import { Timestamp } from "@SyoLab/date-time"

export function errorLogger(error) {

   const browser = Bowser.parse(window.navigator.userAgent);
   // add meta data to error message
   const meta = {
      browserName: browser.browser.name,
      browserVersion: browser.browser.version,
      browserType: browser.platform.type,
      browserOs: `${browser.os.name} ${browser.os.versionName}`,
      timeStamp: Timestamp()
   }

   // post tot background worker
   errWorker.postMessage({ type: 'error', data: { ...meta, ...error } });
}


