<template>
   <div class="BchService">
      <div class="header">{{ service.bezeichnung }}</div>
      <div class="fromTo">
         <div class="text">Gültigkeit:</div>
         <div class="value">{{ toDate(service.gueltig_vom) }} - {{ toDate(service.gueltig_bis) }}</div>
      </div>
      <div v-if="service.modul_field"
         class="config">
         <div class="row">
            <div class="text">Konfiguration</div>
            <div class="value">{{ service.modul_konfig }}</div>
         </div>
         <div class="helpText"> {{ service.modul_field_helptext }} </div>
      </div>

   </div>
</template>

<script>
import { Day } from '@SyoLab/date-time'
export default {
   name: 'BchService',
   components: {},
   props: {
      service: { required: true }
   },
   data() {
      return {}
   },
   methods: {
      toDate(date) {
         return new Day(date).toEuDate()
      }
   },
   computed: {},
}
</script>

<style scoped>
.BchService {
   display: flex;
   flex-direction: column;
   padding: 10px;
   background-color: whitesmoke;
}

.fromTo {
   display: flex;
}

.fromTo .text,
.config .text {
   width: 130px;
   font-weight: 500;
   font-size: 14px;
   color: #656464;
}

.config {
   display: flex;
   flex-direction: column;
}

.config .helpText {
   padding-top: 2px;
   font-size: 11px;
}

.header {
   font-weight: 300;
   font-size: 20px;
   padding-bottom: 3px;
}
</style>