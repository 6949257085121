<template>
   <div class="BillingDeletedList">
      <BillingDeletedGrid :bills="bills" />
   </div>
</template>

<script>
import api from '@components/Portal/api';
import BillingDeletedGrid from './grid/BillingDeletedGrid.vue';
export default {
   name: 'BillingDeletedList',
   components: { BillingDeletedGrid },
   props: {},
   data() {
      return {
         bills: [],
      }
   },
   methods: {
      async getData() {
         let bills = await api.post('billing/list/bills/deleted')
         this.bills = bills
      },
   },
   computed: {},
   created() {
      this.getData()
   }
}
</script>

<style scoped >
.BillingDeletedList {}
</style>