<template>
   <div class="StatisticsEmployees">
      <div class="action">
         <div class="prev active"
            @click="onPrev">
            <Icon icon="iconPrev" />
         </div>
         <div class="next"
            :class="{ active: nextIsActive }"
            @click="onNext">
            <Icon icon="iconNext" />
         </div>
      </div>
      <div class="grid"
         :style="gridStyle">
         <div class="header">
            <div v-if="!employee_id"
               class="employee">
               <div v-if="mode == 'month'"
                  class="month">{{ activeMonth }}</div>
            </div>
            <div v-for="item in gridColumns"
               :class="itemClass(item)"
               :style="itemStyle(item)"
               :key="item.id"
               @click="onColumnClick(item)">
               <span>{{ item.header1 }}</span>
               <span class="dateFormatted">{{ item.header2 }}</span>
            </div>
            <div class="total">Total</div>
         </div>
         <div v-for="record in records"
            class="gridRow"
            :key="record.employee_id">
            <div v-if="!employee_id"
               class="employee">{{ record.employee }}</div>
            <div v-for="item in gridColumns"
               :class="itemClass(item)"
               :style="itemStyle(item)"
               :key="item.id"
               @click="onColumnClick(item, record)">{{ record[item.date] }}</div>
            <div class="total">{{ record.total }}</div>
         </div>
      </div>
   </div>
</template>

<script>
import { Day } from '@SyoLab/date-time'
import api from '@components/Portal/api'
import Icon from "@icons/Icon.vue"
import { ChevronLeft, ChevronRight } from "@icons/appFabric/icons"
export default {
   name: 'WorkingDays',
   components: { Icon },
   props: {
      apiUrl: { required: true },
      mode: { required: false, default: 'week' },
      employee_id: { required: false },
   },
   data() {
      let startDate = new Day().minus({ days: 6 }).date
      if (this.mode == 'month') startDate = new Day().startOf('month').date
      let endDate = new Day().date
      if (this.mode == 'month') endDate = new Day().endOf('month').date
      return {
         endDate,
         startDate,
         records: [],
      }
   },
   static: {
      iconPrev: ChevronLeft,
      iconNext: ChevronRight,
   },
   methods: {
      async getData() {

         let res = await api.post(`${this.apiUrl}/employeeStatistics`, {
            startDate: this.startDate,
            endDate: this.endDate,
            employee_id: this.employee_id,
         })

         this.records = res
      },
      itemStyle(item) {
         return {
            gridColumn: item.id,
         }
      },
      itemClass(item) {
         return {
            day: true,
            weekEnd: item.weekDay == 6 || item.weekDay == 7,
            kw: item.header1 == 'KW',
         }
      },
      onPrev() {
         if (this.mode == 'month') {
            this.endDate = new Day(this.endDate).minus({ months: 1 }).endOf('month').date
            this.startDate = new Day(this.endDate).startOf('month').date
         } else {
            this.endDate = new Day(this.endDate).minus({ days: 7 }).endOf('week').date
            this.startDate = new Day(this.endDate).minus({ days: 6 }).date
         }

         this.getData()
      },
      onNext() {
         if (!this.nextIsActive) return
         if (this.mode == 'month') {
            this.endDate = new Day(this.endDate).plus({ months: 1 }).endOf('month').date
            this.startDate = new Day(this.endDate).startOf('month').date
         } else {
            let endDate = new Day(this.endDate).plus({ days: 7 }).date
            if (endDate > new Day().date) endDate = new Day().date
            this.endDate = endDate
            this.startDate = new Day(this.endDate).minus({ days: 6 }).date
         }


         this.getData()
      },
      onColumnClick(item, record) {
         if (!item.date || item.header1 == 'KW') return
         if (record) {
            this.$emit('workingDay', record.employee_id, item.date, record.employee)
            return
         }
         if (this.employee_id) {
            this.$emit('workingDay', this.employee_id, item.date, record.employee)
            return
         }
      },
   },
   computed: {
      gridColumns() {
         let columns = []
         let d = new Day(this.startDate)
         while (d.date <= this.endDate) {
            columns.push({
               id: `d${d.date}`,
               date: d.date,
               weekDay: d.weekDay,
               header1: d.toFormat('ccc'),
               header2: this.mode == 'month' ? d.toFormat('dd') : d.toFormat('dd.MM'),
            })

            if (d.weekDay == 7) {
               columns.push({
                  id: `w${d.week}`,
                  date: d.week,
                  header1: 'KW',
                  header2: d.weekNo,
               })
            }
            d = d.plus({ days: 1 })
         }

         return columns
      },
      gridStyle() {
         let width = '65px'
         if (this.mode == 'month') width = '40px'
         let gridTemplateColumns = this.employee_id ? '' : '[employee] max-content '
         gridTemplateColumns += this.gridColumns.map(item => `[${item.id}] ${width}`).join(' ')
         gridTemplateColumns += ' [total] max-content'
         return {
            gridTemplateColumns
         }
      },
      nextIsActive() {
         if (this.mode == 'month') {
            return this.endDate < new Day().endOf('month').date
         }
         return this.endDate < new Day().date
      },
      activeMonth() {
         if (this.mode != 'month') return null
         return new Day(this.startDate).toFormat('MMMM yyyy')
      },
   },
   created() {
      this.getData()
   },
}
</script>

<style scoped>
.StatisticsEmployees {
   display: flex;
   flex-direction: column;
}

.action {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   column-gap: 25px;
   font-size: 14px;
   padding-bottom: 20px;
   padding-right: 20px;
   position: relative;
}

.action>div {
   color: #939292;
}

.action>div.active {
   cursor: pointer;
   color: black;
}

.month {
   height: 100%;
   display: flex;
   align-items: center;
   padding-top: 3px;
   font-size: 16px;
   color: rgb(1, 1, 1, 0.7);
}


.grid {
   display: grid;
}

.grid .day {
   display: flex;
   flex-direction: column;
   align-items: center;
   cursor: pointer;
}

.grid .day.weekEnd {
   background-color: whitesmoke;
}

.grid .day.kw {
   font-size: 12px;
   font-weight: 500;
   color: #2727c9;
   display: flex;
   justify-content: center;
   background-color: whitesmoke;
}

.grid .total {
   color: #2727c9;
   background-color: #edf4fb;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   padding: 0 5px;
}

.grid .header .total {
   padding-top: 3px;
   display: flex;
   align-items: center;
}

.header .day {
   font-size: 12px;
   font-weight: 500;
   color: rgba(0, 0, 0, 0.7);
   padding-top: 3px;
}

.header>div {
   padding-bottom: 10px;
}

.grid .header {
   display: contents;
}

.grid .gridRow {
   display: contents;
}

.grid .employee {
   grid-column: employee;
   padding-right: 10px;
   min-width: 95px
}
</style>