<template>
   <div class="Education">
      Bildung
   </div>
</template>

<script>
export default {
   name: 'Education',
   components: {},
   props: {},
   data() {
      return {}
   },
   methods: {},
   computed: {},
}
</script>

<style scoped >
.Education {}
</style>