<template>
   <div class="AssignedTo">
      <InputItems ref="employeeList"
         :items="service.assignedTo"
         :readonly="readonly"
         height="26px"
         fontSize="14px"
         addItemIconFontSize="11px"
         bgColor="#EDF4FB"
         addItemWidth="150px"
         addItemGap="15px"
         addItemBtnBorderColor="#EDF4FB"
         itemMinWidth="100px"
         itemBgColor="#EDF4FB"
         :options="{ listItemKey: '_id', listItemValueKey: 'fullName' }"
         :onAsyncSearch="onEmployeeSearch"
         @select="onAssignedToSelect"
         @delete="onAssignedToDelete" />
   </div>
</template>

<script>
import InputItems from '../components/InputItems.vue';
export default {
   name: 'AssignedTo',
   components: { InputItems },
   props: {
      config: { required: true },
      service: { required: true },
      readonly: { required: false, default: false }
   },
   data() {
      return {}
   },
   methods: {
      async onEmployeeSearch(searchString) {
         if (!searchString) return this.config.employees
         return this.config.employees.filter(item => item.fullName.toLowerCase().includes(searchString.toLowerCase()))
      },
      onAssignedToSelect(selectedItem) {
         if (this.service.assignedTo.find(item => item._id == selectedItem._id)) return
         this.service.assignedTo.push(selectedItem)
         this.$emit('update', this.service.assignedTo)
      },
      onAssignedToDelete(deletedItem) {
         this.service.assignedTo = this.service.assignedTo.filter(item => item._id != deletedItem._id)
         this.$emit('update')
      },
   },
   computed: {},
}
</script>

<style scoped>
.AssignedTo {
   display: flex;

}
</style>