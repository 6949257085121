<template>
   <div class="ServicesFilters">
      <Dropdown class="businessDomainFilter"
         placeholder="Geschäftsbereich"
         :options="businessDomainDropdownOptions"
         :value="businessDomainDropdownValue"
         @input="onBusinessDomainInput" />
      <Dropdown v-if="showAssignedToFilter"
         class="assignedToFilter"
         placeholder="Zugewiesen an"
         :options="assignedToDropdownOptions"
         :value="assignedToDropdownValue"
         @input="onAssignToInput" />
      <Dropdown v-if="showCustomerFilter"
         class="customerFilter"
         placeholder="Kunde"
         :options="customerDropdownOptions"
         :value="customerDropdownValue"
         @input="onCustomerInput" />
   </div>
</template>

<script>
import Dropdown from '@components/Form/Dropdown.vue'
import Switcher from "@components/Form/Switcher.vue"
export default {
   name: 'ServicesFilters',
   components: { Dropdown, Switcher },
   props: {
      config: { required: true },
      filters: { required: true },
      services: { required: true }
   },
   data() {
      return {}
   },

   methods: {
      onBusinessDomainInput(value) {
         let item = this.filters.find(filter => filter.type == 'businessDomain')
         if (item && value) {
            item.value = value
         } else if (item && !value) {
            let idx = this.filters.findIndex(filter => filter.type == 'businessDomain')
            this.filters.splice(idx, 1)
         } else if (!item && value) {
            this.filters.push({ type: 'businessDomain', value: value })
         }
      },
      onAssignToInput(value) {
         let item = this.filters.find(filter => filter.type == 'assignedTo')
         if (item && value) {
            item.value = value
         } else if (item && !value) {
            let idx = this.filters.findIndex(filter => filter.type == 'assignedTo')
            this.filters.splice(idx, 1)
         } else if (!item && value) {
            this.filters.push({ type: 'assignedTo', value: value })
         }
      },
      onCustomerInput(value) {
         let item = this.filters.find(filter => filter.type == 'customer')
         if (item && value) {
            item.value = value
         } else if (item && !value) {
            let idx = this.filters.findIndex(filter => filter.type == 'customer')
            this.filters.splice(idx, 1)
         } else if (!item && value) {
            this.filters.push({ type: 'customer', value: value })
         }
      },
   },
   computed: {
      businessDomainDropdownOptions() {
         return {
            listItems: this.config.businessDomains,
            clearable: true,
            emptyListItem: true
         }
      },
      businessDomainDropdownValue() {
         return this.filters.find(filter => filter.type == 'businessDomain')?.value
      },
      assignedToDropdownOptions() {
         return {
            listItems: this.config.employees,
            listItemKey: '_id',
            listItemValueKey: 'fullName',
            clearable: true,
            emptyListItem: true
         }
      },
      assignedToDropdownValue() {
         return this.filters.find(filter => filter.type == 'assignedTo')?.value
      },
      customerList() {
         let customers = []
         this.services.forEach(service => {
            if (!customers.find(c => c.customer_id == service.customer_id)) {
               customers.push({ customer_id: service.customer_id, customerName: service.customerName })
            }
         })
         return customers
      },
      customerDropdownOptions() {
         return {
            autocomplete: true,
            listItems: this.customerList,
            listItemKey: 'customer_id',
            listItemValueKey: 'customerName',
            clearable: true,
            emptyListItem: true
         }
      },
      customerDropdownValue() {
         return this.filters.find(filter => filter.type == 'customer')?.value
      },
      showAssignedToFilter() {
         if (this.config.mode == 'myTasks') return false
         return this.config.mode != 'billed'
      },
      showCustomerFilter() {
         return this.customerList.length > 1
      }
   },
}
</script>

<style scoped>
.ServicesFilters {
   display: flex;
   align-items: center;
   column-gap: 30px;
   background-color: whitesmoke;
   padding: 3px 15px;
}

.Dropdown.businessDomainFilter {
   width: 150px;
}


.iconBill {
   font-size: 20px;
}

.customerFilter {
   width: 280px;
}
</style>