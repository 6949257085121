<template>
   <div class="ServicesBillingBtn">
      <SplitButton v-if="showBillingBtn"
         class="blue"
         :items="splitButtonItems"
         @click="onBillingStep">{{ billingStepText }}</SplitButton>
   </div>
</template>

<script>
import SplitButton from '@components/Form/SplitButton.vue';
import { popupOpen } from "./components/utils"
import api from '@components/Portal/api'

export default {
   name: 'ServicesBillingBtn',
   components: { SplitButton },
   props: {
      config: { required: true },
      services: { required: true },
      selectedCustomer_id: { required: true },
      selectedServices: { required: true },
      showBillingBtn: { required: false, },
   },
   data() {
      return {
         billingStep: null,
         billingPreviewWindow: null,
         displayBillingInfo: null,
      }
   },
   methods: {
      onBillingStep(e) {
         console.log('onBillingStep', e)
         if (e == 'cancel') {
            this.billingStep = null
            this.$emit('select', { selectedServices: [], selectedCustomer_id: null })
            return
         }
         if (!this.billingStep) {
            this.billingStep = 'select'
            return
         }
         if (this.selectable && this.selectedServices.length == 0) {
            // reset
            this.billingStep = null
            this.$emit('select', { selectedServices: [], selectedCustomer_id: null })
         }
         if (this.selectable && this.selectedServices.length > 0) {
            this.billingPreviewPopup()
            this.billingStep = 'preview'
            return
         }
         if (this.billingStep == 'preview') {
            this.billingPreviewPopup()
            return
         }

      },
      billingPreviewPopup() {

         if (this.billingPreviewWindow) {
            // if window is already open, focus and update data
            this.billingPreviewWindow.focus()
            this.billingPreviewPopupSendData(this.billingPreviewWindow)
            return
         }
         this.billingPreviewWindow = popupOpen(window, `billing/preview`, 'billingPreview', { height: 1250, width: 1250 })
      },
      billingPreviewPopupSendData(window) {
         window.postMessage({ type: 'billingPreview', selectedServices: this.selectedServices }, window.location.origin)
      },
      onMessage(event) {
         if (event.origin !== window.location.origin) return
         if (event.data?.type == 'billingPreview') {
            let data = event.data.data
            if (data == 'update') {
               console.log('billingPreview, update')
               // send services to popup
               this.billingPreviewPopupSendData(event.source)
               // let services = this.services.filter(s => this.selectedServices.includes(s._id))
               // event.source.postMessage({ type: 'billingPreview', data: services }, window.location.origin)
               event.source.onbeforeunload = () => {
                  this.billingPreviewWindow = null
                  this.billingStep = null
                  this.$emit('select', { selectedServices: [], selectedCustomer_id: null })
               }
            }
         }
         if (event.data?.type == 'serviceUpdates') {
            this.updateData(event.data.data)
         }

      },
      async updateData(services) {
         let res = await api.post(`${this.config.apiUrl}/services`, {
            _id: services.map(s => s._id)
         })

         for (const service of res) {
            let idx = this.services.findIndex(s => s._id == service._id)
            if (idx > -1) {
               this.$set(this.services, idx, service)
            } else {
               this.services.push(service)
            }
         }
      },
   },
   computed: {
      splitButtonItems() {
         if (!this.billingStep) return []
         return [
            { text: 'Abbrechen', click: 'cancel' },
         ]
      },
      billingStepText() {

         if (this.selectable) {
            return this.selectedServices.length > 0 ? 'Rechnungsvorschau' : 'Leistungen auswählen'
         }
         return 'Abrechnen'
      },
      selectable() {
         if (this.billingStep == 'select') return true
         return false
      },
   },
   watch: {
      billingStep() {
         if (this.billingStep == 'select') {
            this.$emit('selectable', true)
         } else {
            this.$emit('selectable', false)
         }
      },
   },
   mounted() {
      window.addEventListener('message', this.onMessage)

   },
   beforeDestroy() {
      window.removeEventListener('message', this.onMessage)
   }
}
</script>

<style scoped>
.ServicesBillingBtn {}
</style>