<template>
   <div class="BillingDeletedGrid">
      <div class="grid">
         <div class="gridRow header">
            <div class="customerName">Kunde</div>
            <div class="date">Datum</div>
            <div class="amount">Betrag</div>
            <div class="billedBy">Verrechnet durch</div>
            <div class="billNo">Rechnungsnummer</div>
            <div class="deletedBy">Gelöscht durch</div>
            <div class="deletedTimestamp">Gelöscht am</div>
            <div class="reason">Begründung</div>
         </div>
         <div v-if="bills.length == 0"
            class="noData"> Keine Daten</div>
         <div class="gridRow record"
            v-for="bill in bills"
            :key="bill.billNo">
            <div class="customerName">{{ bill.customerName }}</div>
            <div class="date">{{ toEuDate(bill.date) }}</div>
            <div class="amount">{{ toAmount(bill.value) }}</div>
            <div class="billedBy">{{ bill.billedBy }}</div>
            <div class="billNo">{{ bill.billNo }}</div>
            <div class="deletedBy">{{ bill.deletedBy }}</div>
            <div class="deletedTimestamp">{{ toEuDate(bill.deletedTimestamp) }}</div>
            <div class="reason">{{ bill.reason }}</div>
         </div>

      </div>
   </div>
</template>

<script>
import { Day } from "@SyoLab/date-time"
import Icon from "@icons/Icon.vue"
import { OpenInNewWindow, Delete } from "@icons/appFabric/icons"
import MenuMore from '@components/Navigation/MenuMore.vue'

export default {
   name: 'BillingDeletedGrid',
   components: { Icon, MenuMore },
   props: {
      bills: { required: true },
   },
   data() {
      return {
      }
   },
   static: {
      iconOpen: OpenInNewWindow,
      iconDelete: Delete,
   },
   methods: {
      toEuDate(date) {
         return new Day(date).toEuDate()
      },
      toAmount(value) {
         return Number(value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
   },
   computed: {},
}
</script>

<style scoped >
.BillingDeletedGrid {}

.grid {
   display: grid;
   grid-template-columns: [customerName] 270px [date] 100px [amount] 115px [billedBy] 195px [billNo] max-content [deletedBy] 195px [deletedTimestamp] 100px [reason] 155px;
}

.header>div {
   padding: 5px 10px 13px 10px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   display: flex;
   cursor: pointer;
   font-size: 12px;
   font-weight: 500;
   color: rgba(0, 0, 0, 0.7);
}

.record>div {
   padding: 7px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   display: flex;
   align-items: center;
}

.gridRow {
   display: contents;
}

.gridRow .customerName {
   grid-column: customerName;
}

.gridRow .date {
   grid-column: date;
}

.gridRow .amount {
   grid-column: amount;
   justify-content: flex-end;
}

.gridRow .billedBy {
   grid-column: billedBy;
}

.gridRow .billNo {
   grid-column: billNo;
}

.gridRow .deletedBy {
   grid-column: deletedBy;
}

.gridRow .deletedTimestamp {
   grid-column: deletedTimestamp;

}

.gridRow .reason {
   grid-column: reason;
}

.noData {
   grid-column: 1 / -1;
   padding: 15px;
   font-size: 12px;
   color: rgba(0, 0, 0, 0.7);
   text-align: center;
}
</style>