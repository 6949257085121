<template>
   <div id="App"
      class="CustCommMgmt">
      <Tabs :tabs="tabs"
         :activeTab="activeTab"
         :routePathIndex="1"
         @nav="activeTab = $event" />
      <transition name="fadeInOut"
         mode="out-in">
         <NextContacts v-if="activeTab._id == 1" />
         <AllContacts v-if="activeTab._id == 3" />
         <ContactsStatistics v-if="activeTab._id == 2" />
      </transition>

   </div>
</template>

<script>

import Dropdown from '@components/Form/Dropdown.vue';
import Tabs from '@components/Navigation/Tabs.vue';
import ContactsStatistics from './statistics/ContactsStatistics.vue'
import NextContacts from './nextContacts/NextContacts.vue'
import AllContacts from './allContacts/AllContacts.vue'
import api from '@components/Portal/api'
export default {
   name: 'Contacts',
   components: { NextContacts, Dropdown, Tabs, ContactsStatistics, AllContacts },
   props: {},
   data() {
      return {
         activeTab: { _id: 1, text: 'Nächste Kontakte', path: 'nextContacts' },
      }
   },
   methods: {
      async getConfig() {
         // let config = await api.get(`contacts/servicesConfig`)
         // this.employees = config.employees.map(e => {
         //    e.fullName = `${e.firstName} ${e.lastName}`
         //    return e
         // })
      }

   },
   computed: {
      tabs() {
         return [
            { _id: 1, text: 'Nächste Kontakte', path: 'nextContacts' },
            { _id: 3, text: 'Alle Kontakte', path: 'allContacts' },
            { _id: 2, text: 'Statistik', path: 'statistics' },
         ]
      },
   },
   created() {
      let config = sessionStorage.getItem("Contacts")
      if (config) {
         config = JSON.parse(config)
         this.activeTab = config.activeTab
      }
      this.getConfig()
   },
   beforeDestroy() {
      sessionStorage.setItem("Contacts", JSON.stringify({
         activeTab: this.activeTab,
      }))
   },

}
</script>

<style scoped>
.CustCommMgmt {
   display: flex;
   flex-direction: column;
   row-gap: 25px;
}
</style>