<template>
   <div class="BillingList">
      <BillingGrid :bills="bills"
         pdfUrl="/api/billing/bill/pdf/"
         @deleteBill="showDeleteDialog = $event" />
      <Dialog v-if="showDeleteDialog"
         top="250px"
         transferTo="modal"
         header="Rechnung löschen"
         @close="showDeleteDialog = false"
         @outsideClick="showDeleteDialog = false">
         <div class="dlg">
            <div class="warning">
               Möchtest du die Rechnung
               <span>{{ showDeleteDialog.customerName }} {{ showDeleteDialog.billNo }}</span>
               löschen?
            </div>
            <div class="info">Mit einem Click auf Löschen werden:</div>
            <ul class="apleona">
               <li>die Rechnung gelöscht</li>
               <li>die verrechneten Leistungen auf "Fertig" zurückgesetzt</li>
            </ul>
            <div class="action">
               <Button @click="showDeleteDialog = false">Abbrechen</Button>
               <Button class="red"
                  @click="onDeleteBill()">Löschen</Button>
            </div>
         </div>
      </Dialog>
   </div>
</template>

<script>
import BillingGrid from './grid/BillingGrid.vue';
import api from '@components/Portal/api';
import Dialog from '@components/Dialog/Dialog.vue';
import Button from '@components/Form/Button.vue';
export default {
   name: 'BillingList',
   components: { BillingGrid, Dialog, Button },
   props: {
      customer_id: { required: false },
      apiUrl: { required: false }
   },
   data() {
      return {
         bills: [],
         showDeleteDialog: false,
      }
   },
   methods: {
      async getData() {
         if (this.customer_id === null) {
            // services in customer app
            this.bills = []
            return
         }
         if (this.customer_id) {
            // services in customer app
            this.bills = await api.post(`${this.apiUrl}list/bills`, { customer_id: this.customer_id })
            return
         }

         // services app
         this.bills = await api.post(`${this.apiUrl}list/bills`, {})
      },
      onDeleteBill() {
         let bill = this.showDeleteDialog
         if (!bill) return
         let idx = this.bills.findIndex(b => b._id === bill._id)
         this.bills.splice(idx, 1)
         api.delete('billing/bill', bill)
         this.showDeleteDialog = false
      }
   },
   computed: {
   },
   watch: {
      customer_id() {
         this.getData()
      }
   },
   created() {
      this.getData()
   }
}
</script>

<style scoped>
.BillingList {}

.dlg {
   display: flex;
   flex-direction: column;
   row-gap: 15px;
   width: 400px;
   padding-top: 15px;
}

.dlg .action {
   display: flex;
   justify-content: space-around;
   padding: 15px 0;
}

.dlg .warning {
   display: flex;
   flex-direction: column;
   row-gap: 5px;
}

.warning>span {
   color: red
}
</style>