import eventBus from "../Portal/eventBus";

function Tooltip(Vue, el, binding, vnode, oldVnode) {

	this.initialize = function () {

		this.align = {
			x: 'center',
			y: 'top'
		}

		// prevent initalisation twice
		if (el.$_tooltip) {
			console.log("aready initialized")
			return
		}
		el.$_tooltip = this

		if (binding.modifiers) {
			let index = 1;
			for (const key in binding.modifiers) {
				if (index == 1) this.align.y = key;
				if (index == 2) this.align.x = key;
				index += 1;
			}
		}

		el.addEventListener('mouseenter', this.onMousenter.bind(this))
		el.addEventListener('mouseleave', this.onMousleave.bind(this))


		return this
	}

	this.onMousenter = function () {
		eventBus.emit(`tooltip.${binding.arg}`, {
			tooltipInstance: binding.arg,
			message: binding.value,
			refEl: el,
			show: true,
			align: this.align
		})

	}

	this.onMousleave = function () {
		eventBus.emit(`tooltip.${binding.arg}`, {
			tooltipId: binding.arg,
			show: false
		})
	}


	this.update = function () { }

	this.destroy = function () {
		el.removeEventListener('mouseenter', this.onMousenter);
		el.removeEventListener('mouseleave', this.onMousleave);
	}
}

export default {
	install(Vue) {
		Vue.directive("tooltip", {

			bind(el, binding, vnode) {
				(new Tooltip(Vue, el, binding, vnode)).initialize();
			},
			// inserted(el, binding, vndoe) {},
			//updated(el, binding, vnode) { },
			//componentUpdated(el, binding, vnode, oldVnode) { },
			unbind(el, binding, vnode, oldVnode) {
				el.$_tooltip ? el.$_tooltip.destroy() : null;
			}

		});
	}
}

