<template>
   <div class="CustomerData">
      <div class="row customerShortName grey">
         {{ customer.shortName }}
      </div>
      <div class="row customerName">
         {{ displayName }}
      </div>
      <div v-if="customer.name2"
         class="row customerName2">
         {{ customer.name2 }}
      </div>

      <div class="edit"
         @click="$emit('edit')">
         <Icon icon="iconEdit" />
      </div>
      <div class="addressRow">
         <div class="left">
            <div class="customerAddress">
               <div class="row street"> {{ customer.street }}</div>
               <div v-if="customer.street2"
                  class="row street"> {{ customer.street2 }}</div>
               <div class="row location">
                  <div class="zip">{{ customer.zip }}</div>
                  <div class="city">{{ customer.city }}</div>
               </div>
               <div class="row">
                  <div class="country">{{ customer.country }}</div>
               </div>
            </div>
         </div>
         <div class="right">
            <a :href="`https://${customer.homepage}`"
               target="_blank"
               class="homepage">{{ customer.homepage }}</a>
            <a :href="`mailto:${customer.email}`"
               class="email">{{ customer.email }}</a>
            <a :href="`tel:${customer.phone}`"
               class="phone">{{ customer.phone }}</a>
         </div>
      </div>
      <div class="bchRow grey">
         <div class="left">
            <div class="bchId">Kd No</div>
            <template v-if="customer.bchId">
               <div class="bchId">Bch ID</div>
               <div class="bchStatus">Bch Status</div>
               <a :href="`https://admin.publibox.ch/verwaltung/anbieter/${customer.bchId}/bestellungen.html`"
                  target="_blank">publibox</a>
            </template>
         </div>
         <div class="right">
            <div class="bchId">{{ customer.id || '-' }}</div>
            <template v-if="customer.bchId">
               <div class="bchId">{{ customer.bchId }}</div>
               <div class="bchStatus">{{ bchStatus }}</div>
            </template>
         </div>
      </div>
   </div>
</template>

<script>
import Icon from "@icons/Icon.vue"
import { Group, Edit, Add, CaretRightSolid8 } from "@icons/appFabric/icons"
import CustomerDataDialog from './CustomerDataDialog.vue'
export default {
   name: 'CustomerData',
   components: { Icon, CustomerDataDialog },
   props: {
      customer: { required: true }
   },
   data() {
      return {
         editCustomer: false,
      }
   },
   static: {
      iconEdit: Edit
   },
   methods: {
   },
   computed: {
      displayName() {
         let name = this.customer.name
         if (this.customer.firstName) {
            name = `${this.customer.firstName} ${this.customer.name}`
         }
         return name
      },
      bchStatus() {
         return this.customer.bchStatus ? 'aktiv' : 'inaktiv'
      }
   },
}
</script>

<style scoped>
.CustomerData {
   position: relative;
   margin-top: -29px;
}

.customerName {
   font-size: 26px;
   font-weight: 400;
   line-height: 1.6;
   letter-spacing: 0.0075em;
   margin-bottom: 0.35em;
}

.customerShortName {
   font-size: 14px;
}

.grey {
   color: #a5a5a5;
}

.addressRow {
   display: flex;
   column-gap: 50px;
   margin-top: 20px;
}

.customerAddress .location {
   column-gap: 5px;
}

.row.columns {
   column-gap: 50px;
}


.edit {
   position: absolute;
   top: 7px;
   right: 7px;
   display: none;
   padding: 5px;
   border-radius: 3px;
   cursor: pointer;

}

.bchRow {
   display: flex;
   column-gap: 20px;
   margin-top: 20px;
   font-size: 14px;

}

.edit:hover {
   background-color: var(--activeHover);
}

.CustomerData:hover .edit {
   display: block;
}

.right {
   display: flex;
   flex-direction: column;
   min-width: 0;
}

a {
   color: #007bff;
   text-decoration: none;
   word-wrap: break-word;
}
</style>