<template>
   <div class="GrowShrink"
      :class="{ open: isOpen }">
      <slot></slot>
   </div>
</template>

<script>
export default {
   name: "GrowShrink",
   props: {
      show: {
         type: Boolean,
         required: false,
         default: false,
      },
      duration: {
         type: Number,
         required: false,
         default: 500,
      },
      width: { required: false, default: true },
      height: { required: false, default: true },
   },
   data: function () {
      return {
         isOpen: true,
      };
   },
   methods: {
      slideUp(duration = 500) {
         if (duration == null) duration = this.config.duration;
         this.$emit("startSlideUp", duration);
         let target = this.$el;
         if (duration == 0) {
            target.style.display = "none";
            this.$emit("endSlideUp");
            return;
         }
         // define start point
         let height = target.offsetHeight;
         target.style.height = height + "px";
         let width = target.offsetWidth;
         target.style.width = width + "px";

         // start animation to 0
         setTimeout(() => {
            target.style.transitionProperty = "height, width, margin, padding";
            target.style.transitionDuration = duration + "ms";
            target.style.boxSizing = "border-box";
            target.style.overflow = "hidden";
            target.style.height = 0;
            target.style.width = this.startWidth ? this.startWidth + 'px' : 0;
            target.style.paddingTop = 0;
            target.style.paddingLeft = 0;
            target.style.paddingRight = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginLeft = 0;
            target.style.marginRight = 0;
            target.style.marginBottom = 0;
            window.setTimeout(() => {
               target.style.display = "none";
               target.style.removeProperty("height");
               target.style.removeProperty("width");
               target.style.removeProperty("padding-top");
               target.style.removeProperty("padding-left");
               target.style.removeProperty("padding-right");
               target.style.removeProperty("padding-bottom");
               target.style.removeProperty("margin-top");
               target.style.removeProperty("margin-left");
               target.style.removeProperty("margin-right");
               target.style.removeProperty("margin-bottom");
               target.style.removeProperty("overflow");
               target.style.removeProperty("transition-duration");
               target.style.removeProperty("transition-property");
               this.isOpen = false;
               this.$emit("endSlideUp");
            }, duration);
         }, 2);
      },
      slideDown(duration = 500) {
         if (duration == null) duration = this.config.duration;
         this.$emit("startSlideDown", duration);

         let target = this.$el;
         target.style.removeProperty("display");
         let display = window.getComputedStyle(target).display;
         // console.log('display', window.getComputedStyle(target))

         if (display === "none") display = "block";
         if (duration == 0) {
            this.$emit("endSlideDown");
            this.isOpen = true;
            return;
         }

         target.style.display = display;

         let height = target.offsetHeight;
         let width = target.offsetWidth;

         // set to 0
         target.style.overflow = "hidden";
         target.style.height = 0;
         target.style.width = this.startWidth ? this.startWidth + 'px' : 0;
         target.style.paddingTop = 0;
         target.style.paddingLeft = 0;
         target.style.paddingRight = 0;
         target.style.paddingBottom = 0;
         target.style.marginTop = 0;
         target.style.marginLeft = 0;
         target.style.marginRight = 0;
         target.style.marginBottom = 0;

         target.offsetHeight;

         // set end position
         target.style.boxSizing = "border-box";
         target.style.transitionProperty = "height, width, margin, padding";
         target.style.transitionDuration = duration + "ms";
         target.style.height = height + "px";
         target.style.width = width + "px";

         target.style.removeProperty("padding-top");
         target.style.removeProperty("padding-left");
         target.style.removeProperty("padding-right");
         target.style.removeProperty("padding-bottom");
         target.style.removeProperty("margin-top");
         target.style.removeProperty("margin-left");
         target.style.removeProperty("margin-right");
         target.style.removeProperty("margin-bottom");

         window.setTimeout(() => {
            target.style.removeProperty("height");
            target.style.removeProperty("width");
            target.style.removeProperty("overflow");
            target.style.removeProperty("transition-duration");
            target.style.removeProperty("transition-property");
            this.isOpen = true;
            this.$emit("endSlideDown");
         }, duration);
      },
      slideToggle: function (duration = 500) {
         let target = this.$el;
         if (window.getComputedStyle(target).display === "none") {
            return this.slideDown(duration);
         } else {
            return this.slideUp(duration);
         }
      },
      outsideClickFn(e) {
         if (this.show && this.isOpen) {
            if (this.$el && this.$el.contains(e.target)) {
               return;
            }
            this.$emit("outsideClick", e);
         }
      },
   },
   computed: {
      config: function () {
         return {
            duration: this.duration,
         };
      },
   },

   watch: {
      show: function (newVal) {
         if (newVal === true && this.isOpen == false) {
            let startWidth = this.$el.style.minWidth
            if (startWidth == '100%') {
               this.startWidth = this.$el.parentElement.clientWidth
               let paddingLeft = window.getComputedStyle(this.$el.parentElement).paddingLeft
               let paddingRight = window.getComputedStyle(this.$el.parentElement).paddingRight
               this.startWidth -= parseInt(paddingLeft) || 0
               this.startWidth -= parseInt(paddingRight) || 0
            } else if (startWidth) {
               this.startWidth = parseInt(startWidth)
               let paddingLeft = window.getComputedStyle(this.$el.parentElement).paddingLeft
               let paddingRight = window.getComputedStyle(this.$el.parentElement).paddingRight
               this.startWidth -= parseInt(paddingLeft) || 0
               this.startWidth -= parseInt(paddingRight) || 0
            } else {
               this.startWidth = 0
            }

            this.slideDown(this.config.duration);
         }
         if (newVal === false && this.isOpen == true) this.slideUp(this.config.duration);
      },
   },

   mounted() {
      //if property show is set: set initial display
      if (this.show == false) {
         this.$el.style.display = "none";
         this.isOpen = false;
      }
      //add event listener
      setTimeout(() => {
         this.$root.$on("documentTouchClick", this.outsideClickFn);
      }, 1);
   },
   beforeDestroy() {
      this.$root.$off("documentTouchClick", this.outsideClickFn);
   },
};
</script>

