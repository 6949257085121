var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"SplitButton",class:{
		primary: _vm.isPrimary,
		loading: _vm.settings.loading,
		masterDisabled: !_vm.masterButtonIsEnabled,
		listDisabled: !_vm.listButtonIsEnabled,
		show: _vm.listShow,
		hasArrowDown: _vm.items.length > 0,
	}},[(_vm.settings.loading)?_c('Icon',{staticClass:"spinner",attrs:{"icon":_vm.iconSync,"spin":""}}):_vm._e(),_c('div',{staticClass:"label",on:{"click":_vm.onClick}},[_c('span',[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.settings.text))]})],2)]),(_vm.items.length)?_c('div',{staticClass:"arrow",on:{"click":_vm.onListToggle}},[_c('div',{staticClass:"wrap"},[_c('Icon',{attrs:{"icon":_vm.iconArrowDown}})],1)]):_vm._e(),_c('div',{staticClass:"list"},_vm._l((_vm.items),function(item){return _c('div',{key:item.click,staticClass:"listItem",style:(_vm.itemStyle(item)),on:{"click":function($event){return _vm.onListItemClick(item.click)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }