<template>
   <div class="BchSubscriptionEdit">
      <template v-if="!showSettings">
         <div class="task">
            <div class="title">{{ service.customerName }}</div>
            <div class="header">
               <div class="name">
                  <InputBasic class="name"
                     :readonly="isReadonly"
                     ref="serviceName"
                     v-model="service.name"
                     @enter="onEditEnd" />
               </div>
               <div class="status">
                  <Status :readonly="isReadonly"
                     :exclude="['billed', 'paid']"
                     v-model="service.status" />
               </div>
               <div class="btnSettings"
                  @click="onShowSettings(true)">
                  <Icon icon="iconSettings" />
               </div>
               <div v-if="billShow && service.pdf"
                  class="btnBill"
                  @click="onPdfShow(service)">
                  <Icon icon="iconBill" />
               </div>
            </div>
         </div>
         <div class="bchOrder">
            <div class="title">Bestellung {{ service.bchOrder?.id }}</div>
            <div class="row">
               <BchOrder :order="service.bchOrder" />
            </div>
         </div>
         <BillingSlideDown v-if="billingShow"
            :show="billShow">
            <BchSubscriptionBilling :service="service"
               :readonly="isReadonly"
               mode="edit"
               :config="config" />
         </BillingSlideDown>
         <div v-if="!isReadonly"
            class="action">
            <Button class="blue"
               @click="onEditEnd">Schliessen</Button>
         </div>
      </template>
      <template v-else>
         <ServiceSettings :config="config"
            :readonly="isReadonlySettings"
            :service="service"
            @close="onShowSettings(false)" />
      </template>
   </div>
</template>

<script>
import BchSubscriptionBilling from './BchSubscriptionBilling.vue';
import BchOrder from './/bchOrder/BchOrder.vue';
import api from '@components/Portal/api.js'

import Icon from "@icons/Icon.vue"
import { Bill, Refresh, Settings } from "@icons/appFabric/icons"
import InputBasic from '@components/Form/InputBasic.vue';
import Button from '@components/Form/Button.vue'
import Status from '../components/Status.vue';
import BillingSlideDown from '../components/BillingSlideDown.vue';
import { popupOpen } from "../components/utils"
import ServiceSettings from '../components/ServiceSettings.vue';

import { getBchSubscriptionDataObject } from "../bchProduct/bchProduct"


export default {
   name: 'BchSubscriptionEdit',
   components: {
      BchSubscriptionBilling,
      BchOrder,
      InputBasic,
      Button,
      Status,
      BillingSlideDown,
      Icon,
      ServiceSettings
   },
   props: {
      subscriptionData: { required: true, type: Object },
      config: { required: true, type: Object },
      readonly: { required: false },
      selectable: { required: false, default: false },
   },
   data() {
      return {
         showSettings: false,
         service: {}
      }
   },
   static: {
      iconBill: Bill,
      iconReload: Refresh,
      iconSettings: Settings
   },
   methods: {
      onEditEnd() {
         console.log('onEditEnd', this.service, this.service.project_id != this.subscriptionData.project_id)
         if (this.service.project_id != this.subscriptionData.project_id) {
            this.$emit('update', this.service)
            return
         }
         this.$emit('close', this.service)
      },
      onPdfShow(service) {
         if (!this.config.billingPdfUrl) {
            console.error('pdfUrl is not set')
            return
         }
         popupOpen(window, `${this.configbillingPdfUrl}/${service.pdf}`, 'bill', { height: 1200, width: 1200 })
      },
      onShowSettings(show) {
         setTimeout(() => {
            this.showSettings = show
         }, 1)
      },
   },
   computed: {
      settings() {
         return this.service.settings
      },
      isReadonly() {
         if (this.readonly) return true
         if (this.service.status == 'billed') return true
         if (this.service.status == 'payed') return true
         // bchOrder is readonly
         return true
      },
      isReadonlySettings() {
         if (this.readonly) return true
         if (this.service.status == 'billed') return true
         if (this.service.status == 'payed') return true
         // bchOrder is readonly
         return false
      },
      billShow() {
         if (this.service.status == 'billed') return true
         if (this.service.billNo) return true
         if (this.selectable) return true
         return false
      },
      billingShow() {
         return this.config.showBilling ? true : false

      },
   },
   created() {
      this.service = getBchSubscriptionDataObject(this.subscriptionData)
   },
   mounted() {
   }
}
</script>

<style scoped>
.BchSubscriptionEdit {
   display: flex;
   flex-direction: column;
   row-gap: 25px;
   padding-top: 30px;
   width: 900px;
}

.task {
   display: flex;
   flex-direction: column;
   row-gap: 15px;
}

.BchSubscriptionBilling {
   border: 1px solid #d3d2d2;
   border-radius: 9px;
   padding: 15px;
}



.status {
   display: flex;
   align-items: center;
}

.header {
   display: flex;
   column-gap: 35px;

}

.header>.name {
   width: 550px;
   height: 40px;
   padding-left: 2px;
   background-color: #e9e9e9;
   display: flex;
   align-items: center;
   border-radius: 3px;
}

.header>.name .Input {
   width: 100%;
   height: 100%;
}

.bchOrder .title {
   margin-bottom: 10px;
}

.title {
   font-size: 22px;
   font-weight: 300;
}

.btnBill {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 28px;
   border-radius: 3px;
   background-color: #e9e9e9;
   cursor: pointer;
}

.action {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
}

.btnSettings {
   display: flex;
   align-items: center;
   padding: 10px;
   cursor: pointer;
   font-size: 20px;
   background-color: #e9e9e9;
}

.btnSettings:hover {
   background-color: var(--activeHover)
}
</style>