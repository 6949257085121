import { clone } from "@SyoLab/utils"

export function getServiceDataObject(data = {}, settings2 = {}) {
   data = clone(data)
   data.settings = { ...data.settings, ...settings2, }

   return {
      _id: null,
      type: 'service',
      name: null,
      project_id: null,
      projectName: null,
      customer_id: null,
      customerName: null,
      notBillable: false,
      serviceType: null,
      color: null,
      accountNo: null,
      businessDomain: null,
      defaultWorkType: null,
      // estimated
      // estimatedMinutes: null,
      // actual totals
      // totalMinutes: 0,
      // totalMinutesNonBillable: 0,
      value: 0,
      valueNonBillable: 0,
      // status
      status: null,
      assignedTo: [],
      // serviceData
      services: [],
      config: {},
      settings: {},
      createdBy: null,
      createdTimeStamp: null,
      userId: null,
      timeStamp: null,
      order: null, // sorting order
      // billing
      billingText: null,
      billNo: null,
      billingTimestamp: null,
      billedBy: null,
      ...data
   }
}