<template>
   <div class="TimeEntrys">
      <!-- <div class="title">Arbeitszeiten</div> -->
      <div class="grid">

         <div class="notBillable header"></div>
         <div class="date header">Datum</div>
         <div class="from header">Beginn</div>
         <div class="to header">Ende</div>
         <div class="minutes header">Dauer</div>
         <div class="employee header">Mitarbeiter</div>
         <div class="workType header">Arbeitstyp</div>
         <div class="text header">Text</div>
         <div class="action header"></div>
         <div v-for="(record, index) in timeEntrys"
            :key="index"
            class="gridRow">
            <div class="notBillable">
               <div v-if="record.error"
                  class="blocked">
                  <Icon icon="iconBlocked" />
               </div>
               <CheckBox v-else-if="!task.notBillable"
                  class="small"
                  :readonly="readonly"
                  :value="record.notBillable ? false : true"
                  @input="onInput(record, 'notBillable', $event)" />
            </div>
            <div class="date">
               <DatePicker class="basic"
                  :readonly="readonly"
                  :value="record.date"
                  :options="datePickerOptions"
                  @input="onInput(record, 'date', $event)" />
            </div>
            <div class="from">
               <InputTime class="slim"
                  :readonly="readonly"
                  :value="record.from"
                  @input="onInput(record, 'from', $event)" />
            </div>
            <div class="to">
               <InputTime class="slim"
                  :readonly="readonly"
                  :value="record.to"
                  @input="onInput(record, 'to', $event)" />
            </div>
            <div class="minutes">
               <InputTime class="slim"
                  :duration="true"
                  :value="toFormatHH_MM(record.minutes)"
                  @input="onInput(record, 'duration', $event)" />
            </div>
            <div class="employee">
               <Dropdown class="slim employee"
                  :readonly="readonly"
                  :value="record.employee_id"
                  :options="employeeDropdownOptions"
                  @input="onInput(record, 'employee_id', $event)" />
            </div>
            <div class="workType">
               <Dropdown class="slim workType"
                  :readonly="readonly"
                  :value="record.workType"
                  :options="workTypeDropdownOptions"
                  @input="onInput(record, 'workType', $event)" />
            </div>

            <div class="text">
               <Textarea v-model="record.text"
                  :readonly="readonly"
                  @blur="onTextChange(record)" />
            </div>
            <div class="action">
               <MenuMore v-if="!readonly"
                  orientation="right">
                  <div class="menuItem"
                     @click="onTimeEntryDelete(record)">
                     <Icon :icon="$static.iconDelete" />
                     <!-- <span>Löschen</span> -->
                  </div>
               </MenuMore>
            </div>
         </div>
         <div v-if="!readonly"
            class="gridRow">
            <div class="add ">
               <div class="btn add"
                  @click="onAddTask">
                  <Icon icon="iconAdd" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import DatePicker from '@components/Form/DatePicker.vue';
import InputTime from '@components/Form/InputTime.vue';
import InputBasic from '@components/Form/InputBasic.vue';
import Dropdown from '@components/Form/Dropdown.vue';
import CheckBox from '@components/Form/CheckBox.vue';
import Icon from "@icons/Icon.vue"
import { Delete, Add, Blocked2Solid } from "@icons/appFabric/icons"
import { minutes } from 'src/utils/utils'
import Textarea from '@components/Form/Textarea.vue';
import utils from "@SyoLab/utils"
import { Day } from "@SyoLab/date-time"
import MenuMore from "@components/Navigation/MenuMore.vue";
import { durationUpdate, recordUpdate, getNewTimeEntry, hourlyRateUpdate } from './timeEntrys'
export default {
   name: 'TimeEntrys',
   components: {
      DatePicker,
      InputTime,
      Icon,
      Dropdown,
      Textarea,
      CheckBox,
      InputBasic,
      MenuMore
   },
   props: {
      readonly: { required: false, default: false },
      config: { required: true, type: Object },
      task: { required: true, type: Object }
   },
   static: {
      iconDelete: Delete,
      iconAdd: Add,
      iconBlocked: Blocked2Solid
   },
   data() {
      return {
      }
   },
   methods: {
      onInput(record, key, value) {
         switch (key) {
            case 'date':
               record.date = value
               break;
            case 'from':
               record.from = value
               durationUpdate(record)
               break;
            case 'to':
               record.to = value
               durationUpdate(record)
               break;
            case 'duration':
               record.from = null;
               record.to = null;
               record.duration = value
               durationUpdate(record, true)
               break;
            case 'employee_id': {
               record.employee_id = value
               let employee = this.config.employees.find(employee => employee._id === value)
               record.employee = employee.name
               if (employee.workType) {
                  record.workType = employee.workType
               } else {
                  record.workType = this.defaultWorkType
               }
               hourlyRateUpdate(record, this.config, this.task.settings)
               break;
            }
            case 'workType': {
               record.workType = value
               hourlyRateUpdate(record, this.config, this.task.settings)
               break;
            }
            case 'notBillable': {
               record.notBillable = !value
               break;
            }
         }
         record._hasChanges = true
         recordUpdate(record)
         this.$emit('update')
      },
      onTextChange(record) {
         record._hasChanges = true
         recordUpdate(record)
         this.$emit('update')
      },
      onAddTask() {
         this.timeEntrys.push(getNewTimeEntry(this.task, this.config))
      },
      recordHasError(record) {
         return !record.employee_id || !record.minutes || !record.hourlyRate
      },
      onTimeEntryDelete(record) {
         if (this.readonly) return
         setTimeout(() => {
            let index = this.timeEntrys.findIndex(item => item._id === record._id)
            this.timeEntrys.splice(index, 1)
            this.$emit('update')
         }, 100)

      },
      toFormatHH_MM(value) {
         return value ? minutes.toFormatHH_MM(value) : null
      },
      onConfigUpdate() {
         this.timeEntrys.forEach(timeEntry => {
            hourlyRateUpdate(timeEntry, this.config, this.task.settings)
         })
      }
   },
   computed: {
      employeeList() {
         return this.config.employees.map(employee => {
            employee.name = `${employee.firstName} ${employee.lastName}`
            return employee
         })
      },
      employeeDropdownOptions() {
         return {
            listItems: this.employeeList,
            listItemKey: '_id',
            listItemValueKey: 'name',
            placeholder: 'Mitarbeiter'
         }
      },
      workTypeDropdownOptions() {
         return {
            listItems: this.config.workTypes,
            listItemKey: 'id',
            listItemValueKey: 'name',
         }
      },
      timeEntrys() {
         return this.task?.timeEntrys || []
      },
      datePickerOptions() {
         return {
            max: new Day().date,
            showDateIcon: false,
            displayFormat: 'toEuDate',
            format: 'toIsoDate',
            placeholder: 'Datum',
            orientation: 'top'
         }
      },
      defaultWorkType() {
         let serviceType = this.config.addServiceList.find(item => item.serviceType === this.task.serviceType)
         return serviceType?.defaultWorkType || null
      }
   },
}
</script>

<style scoped>
.TimeEntrys {
   display: inline-flex;
   flex-direction: column;
   background-color: var(--activeHover);
   border-radius: 9px;
   padding: 15px;
}

.title {
   font-size: 22px;
   font-weight: 300;
}

.gridRow {
   display: contents;
}


.grid {
   display: grid;
   grid-template-columns: [notBillable] 30px [date] max-content [from] 65px [to] 65px [duration] minmax(65px, max-content) [employee] max-content [workType] max-content [text] 250px [action] max-content
}

.grid .header {
   padding: 0 5px;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   font-size: 12px;
   font-weight: 500;
   color: rgba(0, 0, 0, 0.7);
   height: 38px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   padding-top: 0 !important;
   padding-bottom: 0 !important;

}

.gridRow>* {
   padding: 0 5px;
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   min-height: 38px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.grid .action {
   grid-column: action;
   padding-top: 5px;
}

.grid .notBillable {
   grid-column: notBillable;
   padding-top: 12px;
}

.notBillable .blocked {
   color: var(--apleona-rot);
}

.btn.delete {
   font-size: 11px;
   padding: 4px;
}

.grid .date {
   grid-column: date;
   padding-top: 9px;
}

.grid .text {
   grid-column: text;
   min-width: 155px;
   padding-top: 7px;
   padding-bottom: 3px;
}


.grid .text .Textarea {
   width: 100%;
}

.grid .from {
   grid-column: from;
   padding-top: 7px;
}

.grid .to {
   grid-column: to;
   padding-top: 7px;
}

.grid .employee {
   grid-column: employee;
   padding-top: 2px;
}

.grid .workType {
   grid-column: workType;
   padding-top: 2px;
}

.grid .employee .Dropdown {
   width: 135px;
}

.grid .minutes {
   grid-column: duration;
   padding-top: 7px;
}

.gridRow>.add {
   grid-column: 1/-1;
   border-bottom: none;
   font-size: 14px;
   padding-top: 7px;
}

.btn {
   border-radius: 3px;
   padding: 3px;
}

.btn:hover {
   background-color: var(--actionColorLight);
}

.MenuMore .menuItem {
   display: flex;
   column-gap: 7px;
}

.menuItem .iconDelete {
   font-size: 15px;
}
</style>