<template>
	<div v-cloak
		id="Portal"
		ref="Portal"
		:class="{ mounted: mounted, fullScreen }">
		<Toolbar :userConfig="userConfig"
			:portalConfig="portalConfig"
			:showNav="showMobileMenu">
			<template slot="nav">
				<MenuMobile :menuState="menuState"
					:appMenus="appMenus" />
			</template>
		</Toolbar>
		<Menu v-if="!showMobileMenu"
			:menuState="menuState"
			:appMenus="appMenus" />
		<div id="PortalApp"
			ref="App"
			v-if="client.browser.supported">
			<component ref="currentApp"
				:is="appComponent"
				:userApp="userApps[currentApp.name]"
				:appConfig="currentAppConfig"
				@mounted="currentApp.mounted = true" />
			<portal-target name="portal-app" />
			<portal-target name="modal"
				id="PortalAppModal"
				:class="{ active: portalModalActive }"
				@change="onPortalModalChange" />
			<portal-target name="modal2"
				id="PortalAppModal2"
				:class="{ active: portalModal2Active }"
				@change="onPortalModal2Change" />
			<component id="userLock"
				v-if="userLock"
				:is="lockComponent"
				:appConfig="userApps" />
			<Loader class="background top"
				v-if="loader" />
		</div>
		<BrowserNotSupported v-else
			:browser="client.browser"
			:browserSupport="portalConfig.browserSupport" />
		<!--Drawer-->
		<transition name="fadeInOut">
			<div v-if="drawerOpen"
				id="drawer"
				:class="drawerClass">
				<transition name="replaceWithFade">
					<!--Drawers-->
					<portalSettings v-if="drawers.settings"></portalSettings>
					<UserSettings v-if="drawers.user"></UserSettings>
					<div v-if="drawers.help">&nbsp;</div>
				</transition>
			</div>
		</transition>
		<!--notification-->
		<NotificationPanel id="notify" />
		<portal-target id="FullScreen"
			name="fullScreen"
			:class="{ active: fullScreen }"
			@change="onFullScreen" />
	</div>
</template>

<script>
import Vue from 'vue'

//directives
import Scrollbar from '../Tools/scrollerDirective'
Vue.use(Scrollbar)
import Tooltip from '../Tooltip/tooltipDirective'
Vue.use(Tooltip)

import * as portalHelpers from './portalHelpers'

import { displayUpdate } from './utils'
Vue.prototype.$displayUpdate = displayUpdate

// pinia
import $pinia from 'src/pinia'
import { mapActions, mapState, mapWritableState } from 'pinia'
import portalStateP from './portalState'
const portalState = portalStateP()

//css
import '../css/reset.css'
import '../css/normalize.css'
import '../css/global.css'

//modules
import Browser from 'bowser'

import eventBus from './eventBus'
import * as menuFn from './menuFn'

//config
import config from '@/config.js'

//components
import AppLoading from './ErrorPages/AppLoading.vue'
import AppLoadingError from './ErrorPages/AppLoadingError.vue'
import AppLoadingTimeout from './ErrorPages/AppLoadingTimeout.vue'
import BrowserNotSupported from './ErrorPages/BrowserNotSupported.vue'
import Toolbar from './Toolbar/Toolbar.vue'
import Menu from './Menu.vue'
import MenuMobile from './MenuMobile.vue'
import NotificationPanel from './NotificationPanel.vue'
import Loader from '@components/Tools/Loader.vue'
import utils from '@SyoLab/utils'
import fetch from './fetch'

import { errorLogger } from '@components/Portal/workers'

export default {
	name: 'Portal',
	components: {
		Toolbar,
		Menu,
		NotificationPanel,
		BrowserNotSupported,
		Loader,
		MenuMobile,
	},
	props: {
		options: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			//apps available in portal
			portalApps: {},
			drawers: {
				user: false,
				settings: false,
				help: false,
			},
			routeOnMount: null, // initial requested route on browser startup
			outsideClickWatches: [],
			menuState: {
				expanded: true,
				isAnimating: false,
			},
			portalWidth: 0,
			portalHeight: 0,
			fullScreen: false, // fullScreen Overlay (e.g. for MobileScanner)
			portalModalActive: false, // portalModal Portal-App Overlay (eg for modals)
			portalModal2Active: false, // portalModal Portal-App Overlay (eg for modals) second level
			appRoutes: [],
			// portalConfig
			portalConfig: {
				companyName: undefined,
				title: undefined,
				apps: [],
				lockApp: undefined,
				defaultApp: undefined,
				api: {},
				loadDelay: 200, // show Delay after
				loadTimeout: 5000, // show Timeout after
				browserSupport: {
					//list not supported browser
					rip: null,
					notSupported: null,
				},
				credentialApiSupport: {}, //list browser with credential API
				credentialAPI: false, // credentialAPI flag
				outsideClickMouseDownEl: null,
				// build version
				buildCheckInterval: null,
				build: null,
			},
			//build will be set by requested and compared against portalConfig.version
			serverBuild: {
				version: undefined,
				buildTime: undefined,
				build: undefined,
			},
			//browser client
			client: null,
			// app state
			isMobile: false,
			orientationType: 'landscape',
			orientationAngle: 0,
			// dev
			isDev: process.env.NODE_ENV == 'development'



		}
	},
	methods: {
		...mapActions(portalStateP, ['setLocale', 'authRenew']),
		async setCurrentApp(appName) {
			// console.log('setCurrentApp', appName)
			//set first or default if appName does not exist or is not set
			if (appName == null || appName == '' || appName == 'default') {
				// set route for default app
				console.log('set route for default app', this.portalApps[this.defaultApp].menu[0].route)
				this.$router.push(this.portalApps[this.defaultApp].menu[0].route)
				return
			}

			//return if no change
			if (this.currentApp.name == appName) {
				return
			}

			//app enabled / configured?
			if (!this.portalApps[appName]) {
				this.currentApp.error = true
				console.error('App not found', appName, this.portalApps)
				return
			}

			//initialize before loading
			this.currentApp.component = undefined
			this.currentApp.auth = undefined
			this.currentApp.loaded = false
			this.currentApp.delay = false
			this.currentApp.timeout = false
			this.currentApp.error = false

			//show load delay after config
			setTimeout(() => {
				this.currentApp.delay = true
			}, this.portalConfig.loadDelay)
			//show timeout if loading goes wrong
			setTimeout(() => {
				this.currentApp.timeout = true
			}, this.portalConfig.loadTimeout)

			//load app
			try {
				// set app.name
				this.currentApp.name = appName
				// check authentication is needed
				this.currentApp.auth = this.portalApps[appName].auth == false ? false : true
				// check if we need to authenticate
				if (this.currentApp.auth) {
					//throws error for not authenticated
					this.authenticated = await fetch.authorize()
					// check if current App is enabled
					if (this.authenticated && !this.userAppIsEnabled()) {
						this.currentApp.error = true
						return
					}
				}

				// if a paralell set App is ongoing (this.currentApp.name != appName)) => do not set component
				if (this.currentApp.name != appName) return
				// set app Component
				this.currentApp.component = this.portalApps[appName].component
			} catch (error) {
				console.error('app Loading Error', error)
				this.currentApp.error = true
				return
			}

			//app loaded
		},
		userAppIsEnabled(appName) {
			appName = appName || this.$route.meta.baseApp
			let enabled = false
			if (this.userApps[appName]) {
				if (typeof this.userApps[appName].enabled != 'undefined') {
					enabled = this.userApps[appName].enabled === true ? true : false
				} else {
					enabled = true
				}
			}
			if (!enabled) console.error('UserApp', appName, 'not enabled')
			return enabled
		},

		/**
		 * drawer events (close/toggle)
		 */
		onDrawer: function (event) {
			if (!event) return
			event = event.split('.')

			//close active drawer module
			if (event[0] == 'close') {
				for (const key in this.drawers) {
					this.drawers[key] = false
				}
			}
			//toggle a drawer module
			if (event[0] == 'toggle' && event[1]) {
				this.drawers[event[1]] = !this.drawers[event[1]]
				//if toggle to true -> set all others to fals
				if (this.drawers[event[1]]) {
					for (const key in this.drawers) {
						if (key != event[1]) this.drawers[key] = false
					}
				}
			}
		},
		loadConfig() {
			//import portal config
			for (const key in config.portal) {
				this.portalConfig[key] = config.portal[key]
			}
			// build version
			this.portalConfig.buildCheckInterval = config.buildCheckInterval
			this.portalConfig.build = config.version.build // set build version

			// development: if a config.userConfig is set => load it from config (no auth)
			if (config.userConfig) {
				for (const key in config.userConfig) {
					if (!Object.hasOwnProperty.call(config.userConfig, key)) continue
					this.userConfig[key] = config.userConfig[key]
				}
			}
			if (config.userApps) {
				for (const key in config.userApps) {
					if (!Object.hasOwnProperty.call(config.userApps, key)) continue
					this.userApps[key] = config.userApps[key]
				}
				this.ready = true
			}

			//detect browser and browser-support
			this.client = Browser.parse(window.navigator.userAgent)
			const browser = Browser.getParser(window.navigator.userAgent)
			let isRip, isNotSupported
			if (this.portalConfig.browserSupport.rip) {
				isRip = browser.satisfies(this.portalConfig.browserSupport.rip)
			}
			if (this.portalConfig.browserSupport.notSupported) {
				isNotSupported = browser.satisfies(this.portalConfig.browserSupport.notSupported)
			}
			this.client.browser.supported = isRip ? false : isNotSupported ? false : true

			// detect credentialAPI
			this.portalConfig.credentialAPI = browser.satisfies(this.portalConfig.credentialApiSupport)

			// locales
			this.$set(this, 'locales', config.portal.languages)
		},
		getAppRoutes() {
			this.portalApps
			//add catch all route ('defaultApp')
			let routes = [
				{
					name: 'default',
					path: '*', //404 catch all (default)
					meta: {
						appName: config.portal.defaultApp,
						baseApp: config.portal.defaultApp,
						notfoundRoute: true,
					},
				},
			]

			//extract all routes[] from app config
			for (const key in this.portalApps) {
				let app = this.portalApps[key]
				let appRoutes = portalHelpers.getAppRoutes(app)
				routes = routes.concat(appRoutes)
				//}
			}

			return routes
		},
		async buildCheck() {
			let res = await fetch.serverBuild()
			let hasChanges = false
			for (const key in res) {
				if (this.serverBuild[key] != res[key]) hasChanges = true
			}
			if (hasChanges) this.serverBuild = res
		},
		onMenuClick(menuItem) {
			if (!menuItem.route) return
			let targetPath = menuItem.route
			let currentPath = this.$route.path

			if (currentPath !== targetPath) {
				this.$router.push({ path: targetPath }, async () => {
					await this.$displayUpdate()
				})
			}
			// trigger currentApp function if there is a onMenuClick function
			if (this.$refs.currentApp && typeof this.$refs.currentApp.onMenuClick == 'function') {
				this.$refs.currentApp.onMenuClick(menuItem.value)
			}
		},
		errorLog(err) {

			if (process.env.NODE_ENV == 'development') {
				console.log('notify', err.errorMessage)
				eventBus.emit('notify.error', err.errorMessage)
			}

			// PROD: log error to console and server
			if (process.env.NODE_ENV != 'development') {
				try {
					errorLogger({
						version: this.serverBuild.version,
						userId: portalState.userConfig.userId,
						userName: `${portalState.userConfig.firstName} ${portalState.userConfig.lastName}`,
						userPersNo: portalState.userConfig.persNo,
						...err,
					})
				} catch (error) {
					console.error(error)
				}
			}

		},
		onVisibleChange(visible) {
			eventBus.emit('portal.visibleChange', document.visibilityState)
		},
		updateDefaultRoute() {
			let routes = this.$router.getRoutes()
			let defaultRoute = routes.find(route => route.name == 'default')
			let defaultApp = defaultRoute.meta.appName
			// userConfig has different defaultApp than portalConfig
			if (this.defaultApp != defaultApp) {
				// update defaultRoute
				defaultRoute.meta.appName = this.defaultApp
				defaultRoute.meta.baseApp = this.defaultApp
			}
		},
		onFullScreen(event) {
			if (typeof event != 'boolean') {
				console.error('onFullScreen: event is not boolean', event)
				return
			}
			this.fullScreen = event
		},
		onOrientationChange() {
			this.orientationType = 'landscape'
			this.orientationAngle = 0
			if (window?.screen?.orientation) {
				this.orientationType = window.screen.orientation.type.split('-')[0]
				this.orientationAngle = window.screen.orientation.angle
			}
		},
		async setIsMobile() {
			// orientation can only be locked on mobile
			try {
				let orientationType = window.screen.orientation.type.split('-')[0]
				await window.screen.orientation.lock(orientationType)
				await window.screen.orientation.unlock()

			} catch (error) {
				if (error.name == 'NotSupportedError') {
					try {
						if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
							this.isMobile = true
							return
						}
					} catch (error) {
						console.error('user agent check error:', error)
					}
					this.isMobile = false
					return
				}
				this.isMobile = true
			}

		},
		onPortalModalChange(event) {
			if (typeof event != 'boolean') {
				console.error('onPortalModalChange: event is not boolean', event)
				return
			}
			this.portalModalActive = event
		},
		onPortalModal2Change(event) {
			if (typeof event != 'boolean') {
				console.error('onPortalModalChange: event is not boolean', event)
				return
			}
			this.portalModal2Active = event
		},
		onDocumentTouchClick(e) {
			if (!e.target || !e.target.isConnected) return
			this.$emit('documentTouchClick', e, e.target)
		}
	},
	computed: {
		...mapWritableState(portalStateP, [
			'authenticated',
			'userConfig',
			'userApps',
			'currentApp',
			'locale',
			'locales',
			'loader',
			'mounted',
			'ready',
		]),
		appComponent() {
			//copmponent is not set (loaded)
			if (this.currentApp.component == null) {
				if (!this.ready) return null
				// => show AppLoadingTimeouts;
				if (this.currentApp.error) return AppLoadingError
				if (this.currentApp.timeout) return AppLoadingTimeout
				if (this.currentApp.delay) return AppLoading
				return undefined
			}
			//component ist loaded and set
			//do we need authentication?
			if (this.currentApp.auth) {
				//if app is authenticated or was mounted before => show
				if (this.authenticated || this.currentApp.mounted) {
					// lastCheck: has user this App enabled in Config?
					if (this.userAppIsEnabled() == false) return undefined
					return this.currentApp.component
				} else {
					return undefined
				}
			}
			//authentication is not required => show
			return this.currentApp.component
		},
		lockComponent() {
			if (config.portal.lockApp && this.portalApps[config.portal.lockApp]) {
				return this.portalApps[config.portal.lockApp].component
			}
			return null
		},
		userLock() {
			// on startup
			if (!this.ready) return false
			//if no current App => do not lock
			if (!this.currentApp.name) return true
			//if current App needs no authentication => do not lock
			if (this.currentApp.auth != true) return false
			//we need authentication:
			//if authentication was never tested
			//  => do not lock and wait until the request finishes in background
			//        (is triggered on loading)
			if (this.authenticated === undefined) return false
			//authentication was done => set state of authentication
			return !this.authenticated
		},
		appMenus() {
			let apps = []
			//extract all menu[] from app config
			//filter apps with menus to display
			for (const key in this.portalApps) {
				let app = this.portalApps[key]
				if (menuFn.appMenuShow(this, key)) {
					apps.push(app)
				}
			}

			//sort by menuPosition
			apps = utils.array.objectSort(apps, 'menuPosition')

			//get menus from apps
			let menus = []
			apps.forEach(app => {

				let appMenu = {
					appName: app.name,
					isAppRoute: true,
					show: true,
					active: this.currentApp.name == app.name ? true : false,
					menus: menuFn.menuImport(app.menu, app.name, this.userApps[app.name]),
				}
				menus.push(appMenu)
			})

			return menus
		},
		defaultApp() {
			return this.userConfig.defaultApp ? this.userConfig.defaultApp : this.portalConfig.defaultApp
		},
		drawerOpen: function () {
			//checks if any of the modules are true
			let ret = false
			for (const key in this.drawers) {
				if (this.drawers[key]) {
					ret = true
					break
				}
			}
			return ret
		},
		drawerClass: function () {
			let ret = ''
			for (const key in this.drawers) {
				if (this.drawers[key]) {
					ret = key
					break
				}
			}
			return ret
		},
		navPath() {
			return this.$route.path.split('/').filter(item => item)
		},
		currentRoute() {
			return this.$route
		},
		currentAppConfig() {
			// app (comes from app.Config, regardless of menu)
			let appName = this.currentRoute.meta.appName
			let app = this.portalApps[appName] || {}
			let appConfig = app.appConfig || {}
			// baseApp (comes from app.config where menuItem is)
			let baseAppName = this.currentRoute.meta.baseApp
			let baseApp = this.portalApps[baseAppName] || {}
			let baseAppConfig = baseApp.appConfig || {}
			// baseApp MenuItem
			let menuConfig = (this.currentRoute.meta.menuItem && this.currentRoute.meta.menuItem.appConfig) || {}

			return { ...appConfig, ...baseAppConfig, ...menuConfig }
		},
		showMobileMenu() {
			if (this.portalWidth < 1760) return true
			return false
		},
	},

	watch: {
		$route(to, from) {
			// console.log('route', to, from.path)
			// if application is starting up => try
			if (!this.ready) {
				if (config.portal.authenticateBeforeReady) {
					fetch.authRenew()
				}
				return
			}
			this.setCurrentApp(to.meta.appName)
		},
		serverBuild(value, oldValue) {
			//no serverBuild in dev-mode or broken connection
			if (!this.serverBuild.build) return
			if (!this.portalConfig.build) return

			if (this.serverBuild.build != this.portalConfig.build) {
				console.warn('new version', this.serverBuild.build, this.portalConfig.build)
				setTimeout(() => {
					if (this.serverBuild.build != this.portalConfig.build) {
						window.location.reload(true)
					}
				}, 3000)
			}
		},
		authenticated(value) {
			if (value) {
				//default route might habe changed -> reload
				console.log('authenticated')
				this.updateDefaultRoute()
				this.setCurrentApp(this.$route.meta.appName)
			}
		},
		locale: {
			// snyc to root
			immediate: true,
			handler: function (locale) {
				this.$set(this.$root, 'locale', locale)
			},
		},
		locales: {
			// snyc to root
			immediate: true,
			handler: function (locales) {
				this.$set(this.$root, 'locales', locales)
			},
		},
	},
	created() {
		//reference fetch Options
		fetch.setPortalRef(this)
		fetch.setPortalState(portalState)
		this.setLocale()
		//load config
		this.loadConfig()

		if (this.portalConfig.buildCheckInterval && !isNaN(this.portalConfig.buildCheckInterval)) {
			let buildCheckInterval = this.portalConfig.buildCheckInterval * 60 * 1000
			this.buildCheck()
			setInterval(() => {
				this.buildCheck()
			}, buildCheckInterval)
		}
		// error logger
		eventBus.on('logger.error', this.errorLog)

	},
	beforeMount() {
		eventBus.on('menu.click', this.onMenuClick)

		document.addEventListener('click', this.onDocumentTouchClick)
		document.addEventListener('visibilitychange', this.onVisibleChange)
		window.addEventListener("orientationchange", this.onOrientationChange);
		this.onOrientationChange()
		this.setIsMobile()
	},
	async mounted() {
		//async import apps config
		let apps = {}
		for (const app of config.portal.apps) {
			//eager: modules are included in the current chunk
			// => all configs are include in this chunk
			let appConfig = await import(/* webpackMode: "eager" */ `@/apps/${app}/app.js`)

			apps[app] = appConfig.default
			// extend appConfig
			portalHelpers.prepareConfig(apps[app])
		}

		this.portalApps = Object.assign({}, this.portalApps, apps)
		//set routes
		this.appRoutes = this.getAppRoutes()
		this.appRoutes.forEach(route => {
			this.$router.addRoute(route)
		})

		//set ready to start fade-in
		this.$nextTick(function () {
			this.mounted = true
			let appName = this.$route.meta.appName
			let appConfig = this.portalApps[appName]
			// apps that do not require config can be set on mount
			if (!this.currentApp.name && !appConfig.auth) {
				this.setCurrentApp(this.$route.meta.appName)
			}
		})
		new ResizeObserver(() => {
			// ractive app size
			this.portalWidth = this.$refs.App.clientWidth
			this.portalHeight = this.$refs.App.clientHeight
		}).observe(this.$el)
	},
	beforeDestroy() {
		this.$off()
		eventBus.off('menu.click', this.onMenuClick)
		eventBus.off('logger.error', this.errorLog)
		document.removeEventListener('pointerup', this.onDocumentTouchClick)
		document.removeEventListener('visibilitychange', this.onVisibleChange)
		window.removeEventListener("orientationchange", this.onOrientationChange);
	},
}
</script>

<style scoped>
#Portal {
	width: 100vw;
	min-width: 100vw;
	height: 100vh;
	max-height: 100vh;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: 64px 1fr;
	grid-template-areas:
		'toolbar toolbar'
		'menu app';
	opacity: 0;
	position: relative;
}

#Portal.mounted {
	opacity: 1;
	transition: opacity 300ms ease-in-out;
}

#Portal.fullScreen #PortalToolbar {
	z-index: 0
}

#FullScreen.active {
	z-index: 100;
	display: block;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
	background-color: white;
}

#PortalApp {
	min-width: 100%;
	max-width: 100%;
	min-height: 100%;
	max-height: 100%;
	position: relative;
	grid-area: app;
}

#PortalAppModal,
#PortalAppModal2 {
	position: absolute;
	display: none;
}

#PortalAppModal.active,
#PortalAppModal2.active {
	display: block;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 100;
}

#PortalAppModal2.active {
	z-index: 101;
}

#PortalToolbar {
	grid-area: toolbar;
	z-index: 6;
}

#PortalMenu {
	z-index: 100;
	grid-area: menu;
}

#userLock {
	z-index: 8;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
}

#drawer {
	position: fixed;
	background-color: white;
	box-shadow: -4px 0px 3px -3px rgba(0, 0, 0, 0.2);
	padding: 10px 20px 10px 20px;
	height: calc(100vh - 64px);
	right: 0;
	top: 64px;
	width: 320px;
	transition: width 0.5s ease-in-out;
	overflow: hidden;
}

#drawer.help {
	width: 800px;
}

#notify {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 100;
}

/* */

/* replaceWithFade*/
.replaceWithFade-enter {
	visibility: hidden;
	opacity: 0;
}

.replaceWithFade-enter-to {
	visibility: visible;
	opacity: 1;
}

.replaceWithFade-enter-active {
	transition: visibility 0s linear 0.3s, opacity 0.5s ease 0.3s;
}

.replaceWithFade-leave {
	opacity: 1;
}

.replaceWithFade-leave-to {
	opacity: 0;
}

.replaceWithFade-leave-active {
	transition: opacity 0.3s;
}
</style>
