<template>
	<div class="Loader loader" v-if="show">
		<div class="loader ring" v-if="type == 'ring'" :style="style"></div>
		<div class="loader wave" v-else :style="style">
			<div class="rect"></div>
			<div class="rect"></div>
			<div class="rect"></div>
			<div class="rect"></div>
			<div class="rect"></div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      required: false,
      default: "m",
    },
    delay: {
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      timeout: false,
    };
  },
  computed: {
    style() {
      if (this.size == "m") {
        return {
					...(this.type == "ring" ? { borderWidth: "3px" } : {}),
					width: "30px",
					height: "30px",
        };
      }
      if (this.size == "s") {
        return {
					...(this.type == "ring" ? { borderWidth: "2px" } : {}),
					width: "20px",
					height: "20px",
        };
      }
      return {
				...(this.type == "ring" ? { borderWidth: "3px" } : {}),
				width: "30px",
				height: "30px",
      };
    },
    show() {
      if (this.delay) return this.timeout;
      return true;
    },
  },
  mounted() {
    if (this.delay && !isNaN(this.delay)) {
      setTimeout(() => {
        this.timeout = true;
      }, Number(this.delay));
    }
  },
};
</script>

<style scoped>
	.Loader {
		display: flex;
	}
	.Loader.background {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		min-height: 200px;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.8);
		display: flex;
		justify-content: center;
		z-index: 10;
	}
	.Loader.center {
		align-items: center;
	}
	.Loader.top .loader {
		margin-top: 15%;
	}

	.loader.wave {
		display: flex;
		justify-content: space-between;
	}

	.loader.wave > .rect {
		background-color: var(--accentBlue);
		height: 100%;
		width: 15%;
		animation: ldr-wave 1.2s infinite ease-in-out;
	}

	.loader.wave > .rect:nth-child(1) {
		animation-delay: -1.2s;
	}
	.loader.wave > .rect:nth-child(2) {
		animation-delay: -1.1s;
	}
	.loader.wave > .rect:nth-child(3) {
		animation-delay: -1s;
	}
	.loader.wave > .rect:nth-child(4) {
		animation-delay: -0.9s;
	}
	.loader.wave > .rect:nth-child(5) {
		animation-delay: -0.8s;
	}

	@keyframes ldr-wave {
		0%,
		40%,
		100% {
			transform: scaleY(0.4);
		}
		20% {
			transform: scaleY(1);
		}
	}

	.loader.ring {
		margin: auto;
		border-color: #eaf0f6;
		border-style: solid;
		border-radius: 50%;
		border-top-color: var(--accentBlue);
		animation: spinner 2s linear infinite;
	}

	@keyframes spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>