<template>
   <div class="ProjectAdd">
      <Dropdown v-if="!customer_id"
         ref="customerSearch"
         class="customerSearch"
         label="Kunde"
         placeholder="Suche"
         :options="customerDropDownOptions"
         :autocomplete="onCustomerSearch"
         @input="onCustomerSelect"
         @autocomplete="searchString = $event">
         <template #before>
            <Icon :icon="$static.iconSearch" />
         </template>
      </Dropdown>
      <Input class="projectName"
         ref="projectName"
         label="Projektbezeichung"
         v-model="projectName"
         @enter="onAddProject" />
      <div class="row center">
         <Button :enabled="allowSave"
            class="blue"
            @click="onAddProject">Speichern</Button>
      </div>
   </div>
</template>

<script>
import Dropdown from '@components/Form/Dropdown.vue'
import Input from '@components/Form/Input.vue'

import Button from '@components/Form/Button.vue'
import Textarea from '@components/Form/Textarea.vue'
import Icon from "@icons/Icon.vue"
import { Search, Group, Add } from "@icons/appFabric/icons"
import api from "@components/Portal/api.js"

export default {
   name: 'ProjectAdd',
   components: {
      Dropdown,
      Icon,
      Input,
      Button,
      Textarea
   },
   props: {
      customer_id: { required: false },
      customerName: { required: false },
   },
   data() {
      return {
         searchString: null,
         selectedCustomer: null,
         projectName: null,
         billingText: null
      }
   },
   static: {
      iconSearch: Search,
   },
   methods: {
      async onCustomerSearch(searchString) {
         if (!searchString) return []
         return await api.get(`customer/customerSearch/${searchString}`)
      },
      onCustomerSelect(selectedItem) {
         if (!selectedItem) return
         this.selectedCustomer = selectedItem
         this.$refs.projectName.focus()
      },
      onAddProject() {
         if (!this.projectName) return
         this.$emit('addProject', {
            customer_id: this.customer_id || this.selectedCustomer._id,
            customerName: this.customerName || this.selectedCustomer.name,
            projectName: this.projectName,
            billingText: this.billingText,
            settings: {
               ...this.selectedCustomer?.serviceSettings || {},
            }
         })
      },
   },
   computed: {
      allowSave() {
         if (!this.customer_id && !this.selectedCustomer?._id) return false
         return this.projectName ? true : false
      },
      customerDropDownOptions() {
         return {
            autocomplete: true,
            listItemKey: '_id',
            listItemValueKey: 'name',
            returnListItem: true,
            autocompleteKeys: true,
            triangle: false
         }
      },
   },
   mounted() {
      if (!this.customer_id) {
         this.$refs.customerSearch.focus()
         return
      }
      this.$refs.projectName.focus()
   }
}
</script>

<style scoped>
.ProjectAdd {
   display: flex;
   flex-direction: column;
   row-gap: 35px;
   width: 350px;
   padding-top: 25px;
   padding-bottom: 20px;
}

.Dropdown,
.Input {
   width: 100%;
}

.row.center {
   justify-content: center;
}

.billingText {
   display: flex;
   flex-direction: column;
   row-gap: 10px;
   position: relative;
}

.billingText .Textarea {
   width: 100%;
   position: relative;
}

.billingText::after {
   content: ' ';
   position: absolute;
   bottom: -9px;
   left: 0;
   width: 100%;
   height: 1px;
   border-bottom: 1px solid #619cca;
}
</style>