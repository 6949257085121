<template>
   <div id="App"
      class="MyTasks">
      <div class="col left">
         <Services :assignedTo="assignedTo"
            :showBilling="false"
            mode="myTasks"
            apiUrl='myTasks'
            @update="onServiceUpdate" />
      </div>
      <div class="col right">
         <h5>Arbeitswoche</h5>
         <WorkingDays ref="WorkingDays"
            apiUrl='myTasks'
            :employee_id="assignedTo"
            @workingDay="onWorkingDay" />
         <WorkingDay ref="WorkingDay"
            apiUrl='myTasks' />
      </div>
   </div>
</template>

<script>
import portalStateP from '@components/Portal/portalState';
const portalState = portalStateP()


import Services from '../../components/services/Services.vue'
import WorkingDays from '../../components/workingDays/WorkingDays.vue';
import WorkingDay from '../../components/workingDays/WorkingDay.vue';
import { Day } from "@SyoLab/date-time"
export default {
   name: 'MyTasks',
   components: {
      Services,
      WorkingDays,
      WorkingDay
   },
   props: {},
   data() {
      return {}
   },
   methods: {
      onServiceUpdate() {
         if (this.$refs.WorkingDay) {
            this.$refs.WorkingDay.update()
         }
         if (this.$refs.WorkingDays) {
            this.$refs.WorkingDays.getData()
         }

      },
      onWorkingDay(employee_id, date) {
         this.$refs.WorkingDay.show(employee_id, date)
      }
   },
   computed: {
      assignedTo() {
         return portalState.userConfig.employee
      }
   },
   mounted() {
      if (this.$refs.WorkingDay) {
         this.$refs.WorkingDay.show(this.assignedTo, new Day().date)
      }
   }
}
</script>

<style scoped>
.MyTasks {
   display: flex;
   column-gap: 125px;
}

.col {
   display: flex;
   flex-direction: column;
}

.col.left {
   padding-top: 10px;
}

.col.right {
   padding-top: 5px;
}

.col.right h5 {
   margin-bottom: o
}

.WorkingDay {
   margin-top: 35px;
}
</style>