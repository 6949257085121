<template>
   <div class="CustomerServiceGridRow"
      :style="{ gridRow: row + 1 }">
      <div class="customerName"
         :style="{ gridRow: row + 1 }">
         <span @click="onNavigateToCustomer">{{ service.customerName }}</span>
      </div>
      <div class="action"
         :style="{ gridRow: row + 1 }"
         @click.stop>
         <CheckBox v-if="isSelectable"
            class="small"
            :value="isSelected"
            @input="$emit('select', service, $event)" />
      </div>
   </div>
</template>

<script>
import CheckBox from '@components/Form/CheckBox.vue';
export default {
   name: 'CustomerServiceGridRow',
   components: { CheckBox },
   props: {
      service: { required: true },
      row: { required: true },
      selectable: { required: false, default: false },
      selectedCustomer_id: { required: false, default: false },
      selectedServices: { required: false, default: false }
   },
   data() {
      return {}
   },
   methods: {
      onNavigateToCustomer() {
         this.$router.push(`/customer/${this.service.customer_id}`)
      }
   },
   computed: {
      isSelectable() {
         if (!this.selectable) return false
         if (this.selectedCustomer_id && this.selectedCustomer_id != this.service.customer_id) return false
         return true
      },
      isSelected() {
         return this.service.customer_id == this.selectedCustomer_id
      },
   },
}
</script>

<style scoped>
.CustomerServiceGridRow {
   display: grid;
   grid-column: 1/-1;
   grid-template-columns: subgrid;
   grid-template-rows: subgrid;

   margin-top: 10px;
}



.CustomerServiceGridRow>* {
   display: flex;
   align-items: center;
   padding: 1px 0;
}

.customerName {
   grid-column: 1 / 4;
   display: flex;
   align-items: center;
   font-size: 20px;
   font-weight: 300;
}

.customerName>span {
   cursor: pointer;
}

.action {
   grid-column: action;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: default;
}
</style>