<template>
	<div class="Input input"
		:class="inputClasses">
		<input ref="input"
			class="ipt"
			v-bind="attrs"
			:value="value"
			v-bind:style="styles"
			v-on="inputListeners"
			@input="onInput"
			@keyup.enter="$emit('enter', $event.target.value)"
			@keyup.esc="$emit('escape', $event.target.value)"
			@keyup.down="$emit('arrowDown', $event.target.value)"
			@keyup.up="$emit('arrowUp', $event.target.value)"
			@focus="onFocus"
			@blur="$emit('blur', $event.target.value, $event)"
			size="1" />
	</div>
</template>

<script>
import utils from '@SyoLab/utils'
var observer
export default {
	inheritAttrs: false, // do not inherit, does not affect style and class bindings.
	name: 'InputBasic',
	props: {
		value: {
			required: false,
		},
		options: {
			required: false,
		},
		readonly: { required: false }
	},
	data() {
		return {
			styles: {},
		}
	},
	computed: {
		inputListeners: function () {
			var vm = this
			// add all the listeners from the parent
			// custom listeners or override the behavior of some listeners.
			return Object.assign({}, this.$listeners, {
				input: function (event) {
					return
				},
				blur: function (event) {
					return
				},
			})
		},
		attrs() {
			let ret = { ...this.$attrs }
			if (this.settings.readonly) {
				ret.readonly = this.settings.readonly
			}
			return ret
		},
		inputClasses() {
			return {
				readonly: this.settings.readonly ? true : false,
			}
		},
		settings() {
			return {
				throttled: true,
				readonly: this.readonly,
				...(this.options || {}),
			}
		},
	},
	methods: {
		applyStyle() {
			let ret = {}
			for (const key in this.$el.style) {
				if (Object.prototype.hasOwnProperty.call(this.$el.style, key)) {
					if (isNaN(key) && this.$el.style[key]) {
						ret[key] = this.$el.style[key]
					}
				}
			}
			this.styles = ret
		},
		onInput(event) {
			this.setAutoWidthText(event.target.value)
			if (this.settings.throttled) {
				this.throttledEmit(event.target.value)
			} else {
				this.emit(event.target.value)
			}
		},
		setAutoWidthText() {
			if (!this.$el) return
			if (this.attrs.placeholder) {
				let plLength = this.attrs.placeholder.length
				let vLength = this.value ? String(this.value).length : 0
				this.$el.dataset.value = plLength > vLength ? this.attrs.placeholder : this.value
			} else {
				this.$el.dataset.value = this.value
			}
		},
		blur() {
			this.$refs.input.blur()
		},
		focus() {
			this.$refs.input.focus()
		},
		select() {
			this.$refs.input.select()
		},
		throttledEmit: utils.throttle(
			function (value) {
				this.emit(value)
			},
			200,
			{ leading: false, trailing: true },
		),
		emit(value) {
			this.$emit('input', value)
			this.$emit('change', value)
		},
		onFocus(event) {
			if (this.settings.selectOnFocus) this.select()
			this.$emit('focus', event.target.value, event)
		}
	},
	watch: {
		value(value) {
			this.setAutoWidthText()
		},
		attrs(value) {
			this.setAutoWidthText()
		},
	},
	mounted() {
		this.applyStyle()
		observer = new MutationObserver((mutations, obs) => {
			mutations.forEach(mutation => {
				this.applyStyle()
			})
		})

		observer.observe(this.$el, {
			attributes: true,
			attributeFilter: ['style'],
		})
		this.setAutoWidthText()
	},
	beforeDestroy() {
		if (observer && typeof observer.disonnect == 'function') {
			observer.disonnect()
		}
	},
}
</script>

<style scoped>
.input {
	display: inline-flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
	text-align: left;
	margin-bottom: -1px;
}

/* Pseudo element to sense width of text */
.input::after {
	width: auto;
	min-width: 1em;
	grid-area: 1 / 2;
	font: inherit;
	padding: 0 0.25em;
	margin: 0;
	resize: none;
	background: none;
	appearance: none;
	border: none;
	content: attr(data-value) ' ';
	white-space: nowrap;
	visibility: hidden;
	height: 1px;
}

.ipt {
	outline: none;
	border: 0;
	flex: 1 1 auto;
	max-width: 100%;
	width: 100%;
	position: relative;
	white-space: nowrap;
	text-overflow: ellipsis;
	background-color: transparent;
}

.input.datatable .ipt {
	background-color: transparent;
}

.input.center input {
	text-align: center;
}

.input.right input {
	text-align: right;
}
</style>
