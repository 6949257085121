<template>
	<div class="SplitButton"
		:class="{
			primary: isPrimary,
			loading: settings.loading,
			masterDisabled: !masterButtonIsEnabled,
			listDisabled: !listButtonIsEnabled,
			show: listShow,
			hasArrowDown: items.length > 0,
		}">
		<Icon v-if="settings.loading"
			class="spinner"
			:icon="iconSync"
			spin />
		<div class="label"
			@click="onClick">
			<span>
				<slot>{{ settings.text }}</slot>
			</span>
		</div>
		<div v-if="items.length"
			class="arrow"
			@click="onListToggle">
			<div class="wrap">
				<Icon :icon="iconArrowDown" />
			</div>
		</div>
		<div class="list">
			<div class="listItem"
				v-for="item in items"
				:key="item.click"
				:style="itemStyle(item)"
				@click="onListItemClick(item.click)">
				{{ item.text }}
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import Icon from '@icons/Icon.vue'
import { Sync, ChevronDown } from '@icons/appFabric/icons.js'
export default {
	name: 'SplitButton',
	components: { Icon },
	props: {
		text: {
			type: String,
			required: false,
			default: undefined,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		primary: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		options: {
			type: Object,
			required: false,
			default: () => {
				return {}
			},
		},
		enabled: {
			type: Boolean,
			required: false,
			default: true,
		},
		items: { required: true },
	},
	data() {
		return {
			iconSync: Sync,
			iconArrowDown: ChevronDown,
			listShow: false,
		}
	},
	methods: {
		onClick() {
			if (!this.masterButtonIsEnabled) return
			this.listShow = false
			if (!this.loading && this.enabled) {
				this.$emit('click')
			} else {
				this.$emit('cancel')
			}
		},
		onListToggle() {
			if (!this.listButtonIsEnabled) return
			if (this.items.length > 0) {
				this.listShow = !this.listShow
			}
		},
		onListItemClick(e) {
			this.listShow = false
			this.$emit('click', e)
		},
		onOutSideClick(e) {
			if (this.listShow && !this.$el.contains(e.target)) this.listShow = false
		},
		itemStyle(item) {
			let style = {}
			if (item.color) {
				style.color = item.color
			}
			if (item.backgroundColor) {
				style.backgroundColor = item.backgroundColor
			}
			return style
		},
	},
	computed: {
		settings() {
			let ret = {
				loading: this.loading,
				text: this.text,
				primary: this.primary,
				enabled: this.enabled,
				masterButtonEnabled: true,
				listButtonEnabled: true,
				...this.options,
			}
			return ret
		},
		isPrimary() {
			if (this.settings.primary !== false) return true
			return false
		},
		masterButtonIsEnabled() {
			if (!this.settings.enabled) return false
			return this.settings.masterButtonEnabled
		},
		listButtonIsEnabled() {
			if (!this.settings.enabled) return false
			return this.settings.listButtonEnabled
		},
	},

	created() {
		this.$portal.$on('documentTouchClick', this.onOutSideClick)
	},
	beforeDestroy() {
		this.$portal.$off('documentTouchClick', this.onOutSideClick)
	},
}
</script>

<style scoped>
.SplitButton {
	text-align: center;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0;
	min-width: 80px;
	height: 32px;
	background-color: rgb(255, 255, 255);
	color: rgb(50, 49, 48);
	user-select: none;
	outline: transparent;
	text-decoration: none;
	position: relative;
}

.SplitButton:not(.hasArrow) .label {
	border-width: 1px;
	border-style: solid;
	border-color: rgb(138, 136, 134);
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.SplitButton .label {
	border-width: 1px;
	border-style: solid;
	border-color: rgb(138, 136, 134);
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
	border-right-width: 0;
	margin: 0;
	padding: 0 16px;
	height: 100%;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
}

.SplitButton .arrow {
	border-width: 1px;
	border-style: solid;
	border-color: rgb(138, 136, 134);
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.SplitButton.listDisabled .arrow {
	cursor: default !important;
	background-color: white !important;
	color: rgb(185, 185, 185) !important;
	border-color: rgb(185, 185, 185) !important;
}

.SplitButton.outline {
	background-color: transparent;
}

.SplitButton.loading {
	cursor: default;
}

/* Disabled */
.SplitButton.masterDisabled .label {
	cursor: default !important;
	background-color: white !important;
	color: rgb(185, 185, 185) !important;
	border-color: rgb(185, 185, 185) !important;
}

.SplitButton.masterDisabled .label:hover {
	background-color: white !important;
	color: rgb(185, 185, 185s) !important;
}

/* default */
.SplitButton .label:hover,
.SplitButton .listItem:hover {
	background-color: rgb(243, 242, 241);
	color: rgb(32, 31, 30);
}

/* blue */
.SplitButton.blue .label,
.SplitButton.blue .arrow {
	border-color: var(--accentBlue);
	color: var(--accentBlue);
}

.SplitButton.blue .label:hover,
.SplitButton.blue .listItem:hover,
.SplitButton.blue .arrow:hover {
	color: var(--accentBlue);
	background-color: #eaeff5;
}

/* red */
.SplitButton.red label,
.SplitButton.red .arrow {
	border-color: #e73838;
	color: #e73838;
}

.SplitButton.red .label:hover,
.SplitButton.red .listItem:hover,
.SplitButton.red .arrow:hover {
	color: #e73838;
	background-color: #ffe7e7;
}

/* green */
.SplitButton.green .label,
.SplitButton.green .arrow {
	background-color: #0ba9a5;
	color: white;
}

.SplitButton.green .label:hover,
.SplitButton.green .listItem:hover,
.SplitButton.green .arrow:hover {
	background-color: #077573;
}

/* turquoise */
.SplitButton.turquoise .label,
.SplitButton.turquoise .arrow {
	background-color: var(--apleona-grün4);
	color: rgb(0 129 125);
}

.SplitButton.turquoise .label:hover,
.SplitButton.turquoise .listItem:hover,
.SplitButton.turquoise .arrow:hover {
	color: var(--accentBlue);
	background-color: #eaeff5;
}

.spinner {
	margin-right: 7px;
}

.arrow {
	height: 100%;
	width: 30px;
	font-size: 13px;
}

.arrow>.wrap {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.SplitButton .list {
	position: absolute;
	min-width: 100%;
	top: 100%;
	left: -1px;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	background-color: white;
	padding: 6px 0;
	z-index: 10;
	margin-top: 0;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease;
	transform: translate3d(0, -10px, 0);
	/*add ancor size*/
	display: flex;
	flex-direction: column;
	border-radius: 2px;
	box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
	outline: transparent;
}

.SplitButton.show .list {
	opacity: 1;
	visibility: visible;
	transition: all 0.3s ease;
	transform: translate3d(0, 2px, 0);
}

.listItem {
	white-space: nowrap;
	text-align: left;
	padding: 5px 12px;
	font-size: 14px;
	color: rgb(50, 49, 48);
}
</style>
