import { render, staticRenderFns } from "./InputBasic.vue?vue&type=template&id=57c6b642&scoped=true"
import script from "./InputBasic.vue?vue&type=script&lang=js"
export * from "./InputBasic.vue?vue&type=script&lang=js"
import style0 from "./InputBasic.vue?vue&type=style&index=0&id=57c6b642&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57c6b642",
  null
  
)

export default component.exports