import utils from "@SyoLab/utils"

export function prepareConfig(app) {

	// routes
	if (app.routes) {
		app.routes.forEach(route => {
			route.name = utils.shortId()
			route.appName = route.appName || app.name
			route.isAppRoute = true
		});
	}

	// menuItems
	extendMenuItems(app, app.menu)

}

function extendMenuItems(app, items) {
	if (!items) return
	items.forEach(menuItem => {
		menuItem._id = utils.shortId();
		menuItem.appName = menuItem.appName || app.name
		if (menuItem.items) extendMenuItems(app, menuItem.items)
	})
}



export function getAppRoutes(app) {
	if (app.name == 'timeHrAdmin') {
		console.log('getAppRoutes', app)
	}

	let routes = [];
	if (app.routes) {
		app.routes.forEach(route => {
			routes.push({
				name: route.name,
				path: route.path,
				meta: {
					appName: route.appName,
					baseApp: app.name,
					isAppRoute: true,
				}
			})
		});
	}

	addMenuRoutes(app, routes, app.menu)
	if (app.name == 'timeHrAdmin') {
		console.log('routes', routes)
	}

	return routes
}


function addMenuRoutes(app, routes, items) {
	if (!items) return
	items.forEach(menuItem => {
		if (menuItem.appName && menuItem.route) {
			let route = {
				name: menuItem._id,
				path: menuItem.route,
				meta: {
					appName: menuItem.appName,
					baseApp: app.name,
					menuItem: menuItem
				}
			}
			if (menuItem.component) route.meta.component = menuItem.component
			routes.push(route)
		}
		if (menuItem.items) addMenuRoutes(app, routes, menuItem.items)
	})
}



export function getLang(locale) {
	if (typeof locale != 'string') return
	if (locale.length == 2) return locale
	return locale.split('-')[0]
}


