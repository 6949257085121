<template>
   <div class="DataGrid">
      <div v-if="showActions"
         class="actions">
         <div class="left">
            <slot name="before"></slot>
            <template v-if="showSearch">
               <Input class="search noNotification"
                  v-model="searchText"
                  :options="{ clearable: true }">
               <template slot="before">
                  <Icon class="iconSearch"
                     :icon="$static.iconSearch" />
               </template>
               </Input>
            </template>
         </div>
         <div class="pager"
            v-if="data.length > pageSize">
            <div class="nav"
               @click="onNav('prev')">
               <Icon icon="arrowLeft" />
            </div>
            <div class="pageCurrent">{{ pageFrom }}</div>
            <span>-</span>
            <div class="pageCount">{{ pageTo }}</div>
            <span>von {{ data.length }}</span>
            <div class="nav"
               @click="onNav('next')">
               <Icon icon="arrowRight" />
            </div>
         </div>
      </div>
      <div class="grid"
         :style="gridStyle">
         <div v-if="headerGroups.length > 0"
            class="headerGroup">
            <div v-for="column in headerGroups"
               :class="[column.class || '', column.headerClass || '']"
               :style="{ gridColumnStart: column.startId, gridColumnEnd: column.endId, gridRow: 1 }"
               :key="column.name">
               <span>{{ column.text || column[$locale] }}</span>
            </div>
         </div>
         <div v-if="loader"
            class="loader">
            <Loader />
         </div>

         <div class="header">
            <div v-if="status"
               class="status"
               :style="{ gridColumn: `status` }">
               <CheckBox class="small"
                  :value="hideList.length > 0"
                  @input="hideList = []" />
            </div>
            <div v-for="column in gridColumns"
               :class="[column.class || '', column.headerClass || '']"
               :style="{ gridColumn: column.grid, gridRow: 2 }"
               :key="column.name"
               @click="onSort(column.name)">
               <span>{{ column.text || column[$locale] }}</span>
               <div class="sort"
                  v-if="column.dataSort != false">
                  <Icon icon="sortDown"
                     v-if="sort == '-' + column.name" />
                  <Icon icon="sortUp"
                     v-if="sort == column.name" />
               </div>
            </div>
         </div>

         <div class="record"
            v-for="(record, recordIndex) in pageData"
            :key="getRecordKey(record)">
            <div v-if="status"
               class="status"
               :style="{ gridColumn: `status` }">
               <CheckBox class="small"
                  @input="onRecordHide(record.persNo)" />
            </div>
            <div v-for="column in gridColumns"
               :class="[column.class || '', column.emit ? 'pointer' : '']"
               :style="{
                  gridColumn: column.grid,
                  gridRow: recordIndex + 3,
                  ...getColumnStyle(record, column),
               }"
               :key="column.name"
               @click="onRecordClick(column, record)">
               <component v-if="column.component"
                  :is="column.component"
                  :record="record"
                  :column="column"
                  v-on="$listeners" />
               <template v-else>
                  {{ getRecordValue(record, column) }}
               </template>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import utils from '@SyoLab/utils'
import CheckBox from '@components/Form/CheckBox'
import Icon from '@icons/Icon.vue'
import { SortDown, SortUp, ChevronLeft, ChevronRight, Copy, Search } from '@icons/appFabric/icons'
import Input from '@components/Form/Input.vue'
import Loader from '@components/Tools/Loader.vue'
import { DateTime, Day } from '@SyoLab/date-time'
export default {
   name: 'DataGrid',
   components: { CheckBox, Icon, Input, Loader },
   props: {
      gridColumns: { required: true },
      headerGroups: { required: false, default: () => [] },
      data: { required: true },
      status: { required: false, default: false },
      _id: { required: false, default: true },
      loader: { required: false, default: false },
      pageSize: { required: false, default(val) { return val !== undefined ? val : 20 } },
      showSearch: { required: false, default: true },
      showActions: { required: false, default: true },
   },
   data() {
      return {
         hideList: [],
         sort: null,
         pageStart: 0,
         searchText: null,
      }
   },
   static: {
      sortDown: SortDown,
      sortUp: SortUp,
      arrowLeft: ChevronLeft,
      arrowRight: ChevronRight,
      copy: Copy,
      iconSearch: Search,
   },
   methods: {
      getColumnStyle(record, column) {
         if (column.style) {
            if (typeof column.style === 'function') return column.style(record, column)
            return column.style
         }
         return {}
      },
      getRecordKey(record) {
         if (this._id === true) return record._id
         if (Array.isArray(this._id)) {
            return this._id.map(id => record[id]).join('')
         }
         return `${record.persNo}${record.month}`
      },
      getRecordValue(record, column) {
         if (column.value && typeof column.value === 'function') {
            return column.value(record, column)
         }
         return record[column.name] || null
      },
      onRecordHide(persNo) {
         this.hideList.push(persNo)
      },
      onSort(column) {
         if (this.sort == '-' + column) {
            this.sort = column
            return
         }
         if (this.sort == column) {
            this.sort = null
            return
         }
         this.sort = column
         this.pageStart = 0
      },
      onNav(direction) {
         if (direction == 'prev') {
            if (this.pageStart - this.pageSize < 0) return
            this.pageStart -= this.pageSize
         }
         if (direction == 'next') {
            if (this.pageStart + this.pageSize > this.data.length - 1) return
            this.pageStart += this.pageSize
         }
      },
      onClipboardPaste() {
         let text = this.gridColumns.map(item => item[this.$locale]).join('\t') + '\r\n'
         this.data.forEach(record => {
            text += this.gridColumns.map(item => record[item.name] || null).join('\t') + '\r\n'
         })
         navigator.clipboard.writeText(text)
      },
      onRecordClick(column, record) {
         if (column.emit) this.$emit(column.emit, record)
      },
      resetView() {
         this.hideList = []
         this.pageStart = 0
      },
      getHeaderClass(column) {
         let ret = ''
         if (column.class) ret += `${column.class} `
         if (column.headerClass) ret += column.headerClass
         return ret
      },
   },
   computed: {
      pageData() {
         if (!this.pageSize) return this.gridData
         return this.gridData.slice(this.pageStart, this.pageStart + this.pageSize)
      },
      pageFrom() {
         return this.pageStart + 1
      },
      pageTo() {
         return Math.min(this.pageStart + this.pageSize, this.data.length)
      },
      pageCount() {
         return Math.ceil(this.gridData.length / this.pageSize)
      },
      pageCurrent() {
         if (this.pageStart < this.pageSize) return 1
         return this.pageSize / this.pageStart
      },
      gridData() {
         let data = [...this.data]
         if (this.searchText) {
            let searchText = this.searchText.toLowerCase()
            data = data.filter(item => {
               if (item.customerName && item.customerName.toLowerCase().includes(searchText)) return true
               return false
            })
         }
         if (this.sort) {
            data.sort((a, b) => {
               let direction = this.sort.substring(0, 1) == '-' ? -1 : 1
               let sort = this.sort.substring(0, 1) == '-' ? this.sort.substring(1) : this.sort

               let aVal = a[sort] ? String(a[sort]) : ''
               let bVal = b[sort] ? String(b[sort]) : ''

               if (/^\d{2}.\d{2}.\d{4}$/.test(aVal) || /^\d{2}.\d{2}.\d{4}$/.test(bVal)) {
                  // sort date
                  aVal = aVal ? new Day(aVal).date : ''
                  bVal = bVal ? new Day(bVal).date : ''

               } else if (aVal && aVal.includes(':') || bVal && bVal.includes(':')) {
                  // sort time
                  aVal = aVal ? aVal.replace(':', '') : 0
                  aVal = aVal ? Number(aVal) : 0
                  bVal = bVal ? bVal.replace(':', '') : 0
                  bVal = bVal ? Number(bVal) : 0
               } else if (!isNaN(aVal) && !isNaN(bVal)) {
                  aVal = aVal ? Number(aVal) : 0
                  // sort number
                  bVal = bVal ? Number(bVal) : 0
               }

               if (aVal < bVal) return -1 * direction
               if (aVal > bVal) return 1 * direction
               return 0
            })
         }
         return data
      },
      gridStyle() {
         // set of columns
         let template = ''
         template += this.gridColumns.map(item => `[${item.grid}] ${item.width}`).join(' ')
         return {
            gridTemplateColumns: template,
         }
      },
   },
}
</script>

<style scoped>
.DataGrid {
   display: inline-flex;
   flex-direction: column;
   min-width: 0;
}

.grid {
   display: grid;
}

.header,
.headerGroup,
.record {
   display: contents;
}

.header {
   font-size: 12px;
   font-weight: 500;
   color: rgba(0, 0, 0, 0.7);
}

.headerGroup {
   font-size: 12px;
   font-weight: 700;
   color: rgba(0, 0, 0, 0.7);
}

.header>div {
   padding: 5px 10px 13px 10px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   display: flex;
   cursor: pointer;
}

.header>div.borderLeft {
   border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.headerGroup>div {
   padding: 5px 7px 7px 10px;
   display: flex;
   justify-content: center;
   position: relative;
}

/* .headerGroup > div::after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: calc(100% - 10px);
	height: 100%;
	margin-left: 3px;
	margin-right: 3px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
} */
.header>.center {
   justify-content: center;
}

.header>.status {
   cursor: default;
}

.record>div {
   padding: 7px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   display: flex;
   align-items: center;
   white-space: nowrap;
   overflow: hidden;
}

.record>div.borderLeft {
   border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.record>.left {
   justify-content: flex-start;
}

.record .pointer {
   cursor: pointer;
}

.record .firstName,
.record .lastName,
.record .persNo,
.record .status {
   justify-content: flex-start;
}

.record .center {
   justify-content: center;
}

.record .status {
   display: flex;
   align-items: center;
   margin-left: 3px;
}

.sort {
   display: flex;
   align-items: center;
   width: 18px;
   margin-right: -2px;
   margin-left: 2px;
}

.actions {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 16px;
   margin-top: 25px;
   height: 25px;
}

.pager {
   display: flex;
   align-items: center;
   font-size: 15px;
   gap: 5px;
}

.pager .nav {
   font-size: 12px;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 5px;
   cursor: pointer;
}

.pager .nav:hover {
   background-color: var(--activeHover);
}

.left {
   display: inline-flex;
   align-items: center;
   gap: 25px;
   padding-left: 10px;
   font-size: 18px;
}

.left .btn {
   padding: 5px;
}

.Input.search {
   min-width: 220px;
   font-size: 15px;
}

.Icon.iconSearch {
   font-size: 14px;
}

.grid .loader {
   display: flex;
   align-items: center;
   justify-content: center;
   grid-column: 1 /-1;
   grid-row: 3 / 5;
   min-height: 75px;
}
</style>