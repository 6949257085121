<template>
   <div class="BillingGrid">
      <div class="grid">
         <div class="gridRow header">
            <div class="customerName">Kunde</div>
            <div class="date">Datum</div>
            <div class="amount">Betrag</div>
            <div class="billedBy">Verrechnet durch</div>

            <div class="billNo">Rechnungsnummer</div>
            <div class="status">Status</div>
            <div class="open">PDF</div>
            <div class="action">Aktion</div>
         </div>
         <div v-if="bills.length == 0"
            class="noData"> Keine Daten</div>
         <div class="gridRow record"
            v-for="bill in bills"
            :key="bill.billNo">
            <div class="customerName">{{ bill.customerName }}</div>
            <div class="date">{{ toEuDate(bill.date) }}</div>
            <div class="amount">{{ toAmount(bill.value) }}</div>
            <div class="billedBy">{{ bill.billedBy }}</div>
            <div class="billNo">{{ bill.billNo }}</div>
            <div class="status">
               <div v-if="bill.status"
                  class="statusItem payed">bezahlt</div>
               <div v-else
                  class="statusItem open">offen</div>
            </div>
            <div class="open">
               <div v-if="pdfUrl"
                  class="btn hover"
                  @click="onPdfShow(bill)">
                  <Icon icon="iconOpen" />
               </div>
            </div>
            <div class="action">
               <MenuMore v-if="showMenuMore(bill)"
                  class="small"
                  orientation="right">
                  <div class="delete"
                     @click="$emit('deleteBill', bill)">
                     <Icon :icon="$static.iconDelete" />Löschen
                  </div>
               </MenuMore>
            </div>
         </div>

      </div>
   </div>
</template>

<script>
import { Day } from "@SyoLab/date-time"
import Icon from "@icons/Icon.vue"
import { OpenInNewWindow, Delete } from "@icons/appFabric/icons"
import MenuMore from '@components/Navigation/MenuMore.vue'
import { popupOpen } from "../../../components/services/components/utils"
export default {
   name: 'BillingGrid',
   components: { Icon, MenuMore },
   props: {
      bills: { required: true },
      readonly: { type: Boolean, default: false },
      pdfUrl: { required: true }
   },
   data() {
      return {
      }
   },
   static: {
      iconOpen: OpenInNewWindow,
      iconDelete: Delete,
   },
   methods: {
      toEuDate(date) {
         return new Day(date).toEuDate()
      },
      toAmount(value) {
         return Number(value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
      onPdfShow(bill) {
         popupOpen(window, `${this.pdfUrl}${bill.pdf}`, 'bill', { height: 1200, width: 1200 })
      },
      showMenuMore(bill) {
         if (this.readonly) return false
         return bill.status ? false : true
      }
   },
   computed: {},
}
</script>

<style scoped >
.BillingGrid {}

.grid {
   display: grid;
   grid-template-columns: [customerName] 270px [date] 100px [amount] 115px [billedBy] 195px [billNo] max-content [status] 75px [open] 55px [action] 75px;
}

.header>div {
   padding: 5px 10px 13px 10px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   display: flex;
   cursor: pointer;
   font-size: 12px;
   font-weight: 500;
   color: rgba(0, 0, 0, 0.7);
}

.record>div {
   padding: 7px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   display: flex;
   align-items: center;
}

.gridRow {
   display: contents;
}

.gridRow .customerName {
   grid-column: customerName;
}

.gridRow .date {
   grid-column: date;
}

.gridRow .amount {
   grid-column: amount;
   justify-content: flex-end;
}

.gridRow .billedBy {
   grid-column: billedBy;
}

.gridRow .billNo {
   grid-column: billNo;
}

.gridRow .status {
   grid-column: status;
}

.gridRow .open {
   grid-column: open;
   justify-content: center;
}

.gridRow .open .btn {
   padding: 5px;
}

.gridRow .action {
   grid-column: action;
   justify-content: center;
}

.action .delete {
   display: flex;
   align-items: center;
   column-gap: 5px;
}

.statusItem {
   display: inline-flex;
   align-items: center;
   flex-shrink: 1;
   min-width: 0px;
   max-width: 100%;
   height: 20px;
   border-radius: 10px;
   padding: 2px 7px;
   font-size: 12px;
   margin: 0px;
   user-select: none;
}

.statusItem.open {
   background-color: #d3e5ef;
   color: #183347;
   border: 1px solid #d3e5ef;
}

.statusItem.payed {
   background-color: #dbeddb;
   color: #1c3829;
   border: 1px solid #dbeddb;
}

.noData {
   grid-column: 1 / -1;
   padding: 15px;
   font-size: 12px;
   color: rgba(0, 0, 0, 0.7);
   text-align: center;
}
</style>