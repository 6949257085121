var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"AllContacts"},[_c('div',{staticClass:"filter"},[_c('Dropdown',{ref:"customerSearch",attrs:{"width":"450px","placeholder":"Kunde","options":{
            autocomplete: true,
            listItemKey: '_id',
            listItemValueKey: 'nameCity',
            returnListItem: false,
            autocompleteKeys: true,
            triangle: false,
            autocompleteClearable: true,
         },"autocomplete":_vm.onCustomerSearch},on:{"input":_vm.onCompanySelect,"autocomplete":function($event){_vm.searchString = $event}}}),_c('DatePicker',{attrs:{"placeholder":"Von","options":{ format: 'toIsoDate', clearable: true }},on:{"input":_vm.getData},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('DatePicker',{attrs:{"placeholder":"Bis","options":{ format: 'toIsoDate', clearable: true }},on:{"input":_vm.getData},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),_c('div',{staticClass:"filter"}),_c('Grid',{attrs:{"gridColumns":_vm.gridColumns,"data":_vm.gridRecords},on:{"recordClick":_vm.onRecordClick},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('Dropdown',{staticClass:"noNotification",attrs:{"placeholder":"Mitarbeiter","width":"200px","listItems":_vm.contactsList,"options":{ listItemKey: 'userId', listItemValueKey: 'userName', emptyListItem: true, clearable: true }},model:{value:(_vm.filterByUserId),callback:function ($$v) {_vm.filterByUserId=$$v},expression:"filterByUserId"}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }