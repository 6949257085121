<template>
	<div id="PortalToolbar">
		<div class="nav">
			<slot v-if="showNav"
				name="nav"></slot>
		</div>
		<div id="toolbarTitle">
			<span class="companyName">{{ company }}</span>
			<span class="portalName">{{ title }}</span>
		</div>
		<div id="toolbarButtons">
			<slot v-if="show.toolbarButtons"
				name="toolbarButtons"></slot>
			<div v-if="show.toolbarButtons"
				:class="'button ' + buttonActive(drawers.help)"
				@click="$parent.$emit('drawer', 'toggle.help')">
				<i class="ms-Icon ms-Icon--Help" />
			</div>
			<UserSwitch v-if="showUserSwitch" />
			<UserSettings v-if="authenticated"
				ref="UserSettingsAction"
				:user="userConfig" />

			<div v-if="show.toolbarButtons"
				:class="'button ' + buttonActive(drawers.settings)"
				@click="$parent.$emit('drawer', 'toggle.settings')">
				<i class="ms-Icon ms-Icon--Settings" />
			</div>
		</div>
		<div v-if="progressShow"
			class="loader"></div>
	</div>
</template>

<script type="text/javascript">
import eventBus from '../eventBus'
import UserSettings from './UserSettings.vue'
import UserSwitch from './UserSwitch.vue'
let listeners = []

import $pinia from 'src/pinia'
import { mapActions, mapState, mapWritableState } from 'pinia'
import portalStateP from '@components/Portal/portalState'
const portalState = portalStateP($pinia)

export default {
	components: {
		UserSettings,
		UserSwitch,
	},
	props: {
		drawers: {
			type: Object,
			required: false,
			default: function () {
				return {
					user: false,
					settings: false,
					help: false,
				}
			},
		},
		userConfig: {
			type: Object,
			required: true,
		},
		show: {
			type: Object,
			required: false,
			default: function () {
				return {
					toolbarNav: true,
					toolbarTitle: true,
					toolbarCenter: true,
					toolbarButtons: false,
				}
			},
		},
		showNav: { required: true },
		portalConfig: { required: true },
	},
	data: function () {
		return {
			buttonUser: {
				showSettings: false,
				showVersion: false,
			},
		}
	},
	methods: {
		...mapActions(portalStateP, ['progressStart', 'progressStop',]),
		onUserButtonClick(e) {
			if (this.authenticated !== true) return
			this.buttonUser.showVersion = !this.buttonUser.showSettings && e.ctrlKey ? true : false
			this.buttonUser.showSettings = !this.buttonUser.showSettings
		},
		buttonActive: function (drawerType) {
			if (drawerType) return 'active'
			return ''
		},
	},
	computed: {
		...mapWritableState(portalStateP, ['progressStack', 'userApps', 'authenticated']),
		company: function () {
			return this.portalConfig.companyName
		},
		title: function () {
			return this.portalConfig.title
		},
		progressShow: function () {
			return this.progressStack.length > 0
		},
		showUserSwitch() {
			if (!this.authenticated) return false
			if (this.userApps.proxy) return true
			if (portalState.proxyProvider) return true
			return false
		},
	},
}
</script>

<style scoped>
#PortalToolbar {
	display: flex;
	width: 100%;
	position: relative;
	height: var(--toolbarHeight);
	background-color: #001e41;
	color: white;
}

#PortalToolbar .nav {
	height: 64px;
	width: 64px;
	z-index: 10;
}

#toolbarTitle {
	font-family: 'Roboto', sans-serif;
	font-size: 28px;
	height: 100%;
	display: flex;
	flex-grow: 1;
	align-items: center;
	padding: 0 10px;
	container-type: inline-size;
}


#toolbarButtons {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}



#toolbarButtons .button:not(.active):hover {
	background-color: var(--apleona-blau1);
}

#toolbarTitle>.companyName {
	font-family: 'Radikal', sans-serif;
	text-transform: uppercase;
	color: #e43839;
}

.portalName {
	display: none;
	font-weight: 300;
	margin-left: 13px;
}

@container (min-width: 300px) {
	.portalName {
		display: flex;
	}
}

.button {
	height: 100%;
	width: 64px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
}

.button.active {
	background-color: var(--menuBgActive);
	color: var(--apleona-blau);
}

.button .ms-Icon {
	font-size: 16px;
}

.button.active .iconUser {
	border-color: var(--apleona-blau);
}

.loader {
	height: 4px;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	overflow: hidden;
	background-color: transparent;
}

.loader:before {
	display: block;
	position: absolute;
	content: '';
	left: -200px;
	width: 200px;
	height: 4px;
	background-color: #ce3939;
	animation: loading 2s linear infinite;
}

@keyframes loading {
	from {
		left: -200px;
		width: 30%;
	}

	50% {
		width: 30%;
	}

	70% {
		width: 30%;
	}

	80% {
		left: 50%;
	}

	95% {
		left: 120%;
	}

	to {
		left: 100%;
	}
}
</style>
