<template>
	<div
		ref="scrollWrapper"
		:class="'vue-scrollbar__wrapper' + (classes ? ' ' + classes : '')"
		:style="styles"
		@click="calculateSize"
	>
		<div
			ref="scrollArea"
			:class="'vue-scrollbar__area' + (dragging ? ' ' : ' vue-scrollbar-transition')"
			:style="{
				marginTop: top * -1 + 'px',
				marginLeft: left * -1 + 'px',
			}"
			@wheel="scroll"
			@touchstart="startDrag"
			@touchmove="onDrag"
			@touchend="stopDrag"
		>
			<slot></slot>

			<VerticalScrollbar
				v-if="ready && scrollAreaHeight > scrollWrapperHeight"
				:area="{ height: scrollAreaHeight }"
				:wrapper="{ height: scrollWrapperHeight }"
				:scrolling="vMovement"
				:dragging-from-parent="dragging"
				:on-change-position="handleChangePosition"
				:on-dragging="handleScrollbarDragging"
				:on-stop-drag="handleScrollbarStopDrag"
			>
			</VerticalScrollbar>

			<HorizontalScrollbar
				v-if="ready && scrollAreaWidth > scrollWrapperWidth"
				:area="{ width: scrollAreaWidth }"
				:wrapper="{ width: scrollWrapperWidth }"
				:scrolling="hMovement"
				:dragging-from-parent="dragging"
				:on-change-position="handleChangePosition"
				:on-dragging="handleScrollbarDragging"
				:on-stop-drag="handleScrollbarStopDrag"
			>
			</HorizontalScrollbar>
		</div>
	</div>
</template>

<script>
// https://github.com/BosNaufal/vue2-scrollbar
import VerticalScrollbar from './vertical-scrollbar.vue'
import HorizontalScrollbar from './horizontal-scrollbar.vue'
var scrollObserver
export default {
	components: {
		VerticalScrollbar,
		HorizontalScrollbar,
	},
	props: {
		classes: { type: String, required: false, default: '' },
		styles: { type: Object, required: false, default: () => {} },
		speed: {
			type: Number,
			default: 53,
		},
		onMaxScroll: { type: Function, required: false, default: () => {} },
	},

	data() {
		return {
			ready: false,
			top: 0,
			left: 0,
			scrollAreaHeight: null,
			scrollAreaWidth: null,
			scrollWrapperHeight: null,
			scrollWrapperWidth: null,
			vMovement: 0,
			hMovement: 0,
			dragging: false,
			start: { y: 0, x: 0 },
			allowBodyScroll: false,
			observed: [],
		}
	},

	mounted() {
		// Attach The Event for Responsive View~
		window.addEventListener('resize', this.calculateSize)
		this.update()
		// set listener for static elements
		this.$on('observe.start', this.observeStart)
		this.$on('observe.stop', this.observeStop)

		//setup MutationObserver and emit scroll - event on changes / scrolling
		scrollObserver = new MutationObserver((mutations, observer) => {
			this.$emit('scroll')
		})
		scrollObserver.observe(this.$refs.scrollArea, {
			childList: false,
			attributes: true,
			subtree: false, //Omit or set to false to observe only changes to the parent node.
		})
	},

	beforeDestroy() {
		this.ready = false
		// Remove Event
		window.removeEventListener('resize', this.calculateSize)
		//disconnect mutation obsrever
		this.observed.forEach(el => {
			el.observer.disconnect()
		})
		//cleanout all el
		this.observed = []
		scrollObserver = null
		this.$off()
	},

	methods: {
		//scroll with mousewheel
		scroll(e) {
			// Make sure the content height is not changed
			this.calculateSize(() => {
				// Set the wheel step
				let num = this.speed

				// DOM events
				let shifted = e.shiftKey
				let scrollY = e.deltaY > 0 ? num : -num
				let scrollX = e.deltaX > 0 ? num : -num

				// Fix Mozilla Shifted Wheel~
				if (shifted && e.deltaX == 0) scrollX = e.deltaY > 0 ? num : -num

				// Next Value
				let nextY = this.top + scrollY
				let nextX = this.left + scrollX

				// Is it Scrollable?
				let canScrollY = this.scrollAreaHeight > this.scrollWrapperHeight
				let canScrollX = this.scrollAreaWidth > this.scrollWrapperWidth

				// Vertical Scrolling
				if (canScrollY && !shifted) this.normalizeVertical(nextY)

				// Horizontal Scrolling
				if (shifted && canScrollX) this.normalizeHorizontal(nextX)
			})

			// prevent Default only if scrolled content is not at the top/bottom
			if (!this.allowBodyScroll) {
				// e.preventDefault()
				// e.stopPropagation()
			} else {
				this.$emit('overscroll', e)
			}
		},

		// DRAG EVENT JUST FOR TOUCH DEVICE~
		startDrag(e) {
			this.touchEvent = e

			const evt = e.changedTouches ? e.changedTouches[0] : e

			// Make sure the content height is not changed
			this.calculateSize(() => {
				// Prepare to drag
				this.dragging = true
				this.start = { y: evt.pageY, x: evt.pageX }
			})
		},

		onDrag(e) {
			if (this.dragging) {
				e.preventDefault()
				e.stopPropagation()

				// Prevent Click Event When it dragging
				if (this.touchEvent) {
					this.touchEvent.preventDefault()
					this.touchEvent.stopPropagation()
				}

				let evt = e.changedTouches ? e.changedTouches[0] : e

				// Invers the Movement
				let yMovement = this.start.y - evt.clientY
				let xMovement = this.start.x - evt.clientX

				// Update the last e.client
				this.start = { y: evt.clientY, x: evt.clientX }

				// The next Vertical Value will be
				let nextY = this.top + yMovement
				let nextX = this.left + xMovement

				this.normalizeVertical(nextY)
				this.normalizeHorizontal(nextX)
			}
		},

		stopDrag(e) {
			this.dragging = false
			this.touchEvent = false
		},

		scrollToY(y) {
			this.normalizeVertical(y)
		},

		scrollToX(x) {
			this.normalizeHorizontal(x)
		},

		normalizeVertical(next) {
			const elementSize = this.getSize()

			// Vertical Scrolling
			const lowerEnd = elementSize.scrollAreaHeight - elementSize.scrollWrapperHeight

			// Max Scroll Down
			const maxBottom = next > lowerEnd
			if (maxBottom) next = lowerEnd

			// Max Scroll Up
			const maxTop = next < 0
			if (maxTop) next = 0

			// Update the Vertical Value if it's needed
			const shouldScroll = this.top !== next
			this.allowBodyScroll = !shouldScroll
			if (shouldScroll) {
				this.top = next
				this.vMovement = (next / elementSize.scrollAreaHeight) * 100

				if (this.onMaxScroll && (maxTop || maxBottom)) {
					this.onMaxScroll({
						top: maxTop,
						bottom: maxBottom,
						right: false,
						left: false,
					})
				}
			}
		},

		normalizeHorizontal(next) {
			const elementSize = this.getSize()

			// Horizontal Scrolling
			const rightEnd = elementSize.scrollAreaWidth - this.scrollWrapperWidth

			// Max Scroll Right
			const maxRight = next > rightEnd
			if (maxRight) next = rightEnd

			// Max Scroll Left
			const maxLeft = next < 0
			if (next < 0) next = 0

			// Update the Horizontal Value
			const shouldScroll = this.left !== next
			this.allowBodyScroll = !shouldScroll
			if (shouldScroll) {
				this.left = next
				this.hMovement = (next / elementSize.scrollAreaWidth) * 100

				if (this.onMaxScroll && (maxRight || maxLeft)) {
					this.onMaxScroll({
						right: maxRight,
						left: maxLeft,
						top: false,
						bottom: false,
					})
				}
			}
		},

		handleChangePosition(movement, orientation) {
			// Make sure the content height is not changed
			this.calculateSize(() => {
				// Convert Percentage to Pixel
				let next = movement / 100
				if (orientation == 'vertical') this.normalizeVertical(next * this.scrollAreaHeight)
				if (orientation == 'horizontal') this.normalizeHorizontal(next * this.scrollAreaWidth)
			})
		},

		handleScrollbarDragging() {
			this.dragging = true
		},

		handleScrollbarStopDrag() {
			this.dragging = false
		},

		getSize() {
			// The Elements

			let $scrollArea = this.$refs.scrollArea
			let scrollAreaRect = $scrollArea.getBoundingClientRect()
			let $scrollWrapper = this.$refs.scrollWrapper
			let scrollAreaWidth = 0
			let scrollAreaHeight = 0

			this.observed.forEach(item => {
				if (item.el.className == 'scrollbar-vertical') return
				if (item.el.className == 'scrollbar-horizontal') return
				let itemWidth, itemHeight
				if (item.position == 'absolute') {
					//calculate position relative to scrollArea
					let sizeRec = item.el.getBoundingClientRect()
					itemWidth = sizeRec.width + sizeRec.left - scrollAreaRect.left
					itemHeight = sizeRec.height + sizeRec.top - scrollAreaRect.top
				} else {
					itemWidth = item.el.clientWidth
					itemHeight = item.el.clientHeight
				}

				if (itemWidth > scrollAreaWidth) scrollAreaWidth = itemWidth
				if (itemHeight > scrollAreaHeight) scrollAreaHeight = itemHeight
			})

			// Get new Elements Size
			let elementSize = {
				// Scroll Area Height and Width
				scrollAreaHeight: scrollAreaHeight,
				scrollAreaWidth: scrollAreaWidth,

				// Scroll Wrapper Height and Width
				scrollWrapperHeight: $scrollWrapper.clientHeight,
				scrollWrapperWidth: $scrollWrapper.clientWidth,
			}

			return elementSize
		},

		calculateSize(cb) {
			//console.log("calulateSize", this.$refs.scrollArea);
			if (!this.$refs.scrollArea) return

			if (typeof cb !== 'function') cb = null

			let elementSize = this.getSize()

			if (
				elementSize.scrollWrapperHeight !== this.scrollWrapperHeight ||
				elementSize.scrollWrapperWidth !== this.scrollWrapperWidth ||
				elementSize.scrollAreaHeight !== this.scrollAreaHeight ||
				elementSize.scrollAreaWidth !== this.scrollAreaWidth
			) {
				//console.log("calcSize");
				// Scroll Area Height and Width
				this.scrollAreaHeight = elementSize.scrollAreaHeight
				this.scrollAreaWidth = elementSize.scrollAreaWidth
				// Scroll Wrapper Height and Width
				this.scrollWrapperHeight = elementSize.scrollWrapperHeight
				this.scrollWrapperWidth = elementSize.scrollWrapperWidth
				// Make sure The wrapper is Ready, then render the scrollbar
				this.ready = true

				//vertical overscroll scroll:
				let maxVscroll = this.scrollAreaHeight - this.scrollWrapperHeight
				if (this.top > maxVscroll) {
					if (maxVscroll < 1) {
						this.top = 0
						this.vMovement = 0
					} else {
						this.top = maxVscroll
						this.vMovement = (maxVscroll / elementSize.scrollAreaHeight) * 100
					}
				}

				//horizontal overscroll:
				let maxHscroll = this.scrollAreaWidth - this.scrollWrapperWidth
				if (this.top > maxHscroll) {
					if (maxHscroll < 1) {
						this.left = 0
						this.hMovement = 0
					} else {
						this.left = maxVscroll
						this.hMovement = (maxHscroll / elementSize.scrollAreaWidth) * 100
					}
				}

				return cb ? cb() : false
			} else {
				//console.log("calcSize unchanged");
				return cb ? cb() : false
			}
		},
		update() {
			this.setupObserver()
			this.calculateSize()
		},
		setupObserver() {
			//disconnect mutation obsrever
			this.observed.forEach(el => {
				el.observer.disconnect()
			})
			//cleanout all el
			this.observed = []
			//setup upserved el
			Array.from(this.$refs.scrollArea.children).forEach(el => {
				//filter out scrollbars
				if (el.className != 'scrollbar-horizontal' && el.className != 'scrollbar-vertical') {
					this.observeStart(el)
				}
			})
		},
		observeStart(el) {
			var observer = new MutationObserver(this.calculateSize)
			observer.observe(el, {
				attributes: true,
				childList: true,
				subtree: true,
			})
			this.observed.push({
				observer: observer,
				el: el,
				position: getComputedStyle(el).position,
			})
			//console.log("observe.Start", el, getComputedStyle(el).position);
		},
		observeStop(el) {
			this.observed = this.observed.filter(item => {
				if (item.el == el) {
					item.observer.disconnect()
					return false
				}
				return true
			})
			//console.log("observe.stop", el, this.observed);
		},
	},
}
</script>

<style>
.vue-scrollbar-transition,
.vue-scrollbar__scrollbar-vertical,
.vue-scrollbar__scrollbar-horizontal {
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
}
.vue-scrollbar-transition--scrollbar {
	transition: opacity 0.5s linear;
	-moz-transition: opacity 0.5s linear;
	-webkit-transition: opacity 0.5s linear;
	-o-transition: opacity 0.5s linear;
}

.vue-scrollbar__wrapper {
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	background: inherit;
}
.vue-scrollbar__wrapper:hover .vue-scrollbar__scrollbar-vertical,
.vue-scrollbar__wrapper:hover .vue-scrollbar__scrollbar-horizontal {
	opacity: 1;
}
.vue-scrollbar__scrollbar-vertical,
.vue-scrollbar__scrollbar-horizontal {
	opacity: 0.5;
	position: absolute;
	background: transparent;
}
.vue-scrollbar__scrollbar-vertical:hover,
.vue-scrollbar__scrollbar-horizontal:hover {
	background: rgba(0, 0, 0, 0.1);
}
.vue-scrollbar__scrollbar-vertical .scrollbar,
.vue-scrollbar__scrollbar-horizontal .scrollbar {
	position: relative;
	background: rgba(0, 0, 0, 0.5);
	cursor: default;
}
.vue-scrollbar__scrollbar-vertical {
	width: 10px;
	height: 100%;
	top: 0;
	right: 0;
}
.vue-scrollbar__scrollbar-vertical .scrollbar {
	width: 10px;
}
.vue-scrollbar__scrollbar-horizontal {
	height: 10px;
	width: 100%;
	bottom: 0;
	right: 0;
}
.vue-scrollbar__scrollbar-horizontal .scrollbar {
	height: 10px;
}
</style>
