import { clone } from "@SyoLab/utils"

export function getProjectDataObject(data = {}, settings2 = {}) {
   data = clone(data)
   data.settings = { ...data.settings, ...settings2, }

   return {
      _id: null,
      type: 'project',
      project_id: null,
      projectName: null,
      customer_id: null,
      customerName: null,
      notBillable: false,
      serviceType: null,
      color: null,
      accountNo: null,
      businessDomain: null,
      // projectData
      createdBy: null,
      createdTimeStamp: null,
      userId: null,
      timeStamp: null,
      settings: {},
      order: null, // sorting order
      // billing
      billingText: null,
      ...data
   }
}