<template>
   <div class="CustomerDataDialog">
      <Dialog v-if="editCustomer"
         header="Kunde bearbeiten"
         :transferTo="transferTo"
         closeBtn="true"
         :top="top"
         :left="left"
         @close="$emit('close')"
         @outsideClick="$emit('close')">
         <div class="dlgBody">
            <div class="colRow">
               <div class="col">
                  <Input label="Vorname"
                     v-model="editCustomer.firstName" />
                  <Input label="Name"
                     v-model="editCustomer.name" />
                  <Input label="Name 2"
                     v-model="editCustomer.name2" />
                  <Input label="Strasse"
                     v-model="editCustomer.street" />
                  <Input label="Strasse 2"
                     v-model="editCustomer.street2" />
                  <div class="row">
                     <Input class="zip"
                        label="PLZ"
                        v-model="editCustomer.zip" />
                     <Input class="city"
                        label="Ort"
                        v-model="editCustomer.city" />
                  </div>
                  <Input label="Land"
                     v-model="editCustomer.country" />
               </div>
               <div class="col">
                  <Input label="Kurzname"
                     v-model="editCustomer.shortName" />
                  <div class="spacer"></div>
                  <Input label="homepage"
                     v-model="editCustomer.homepage" />
                  <Input label="Email"
                     v-model="editCustomer.email" />
                  <Input label="Telefon"
                     v-model="editCustomer.phone" />
               </div>
            </div>

            <div class="actionRow">
               <!-- <Button v-if="editContact.type != 'bch'"
                     class="red"
                     @click="onEditContactDelete">Löschen</Button> -->
               <Button class="blue"
                  :enabled="editCustomer?.name?.length > 3"
                  @click="onEditDataSave">Speichern</Button>
            </div>
         </div>
      </Dialog>
   </div>
</template>

<script>
import Dialog from '@components/Dialog/Dialog.vue';
import Input from '@components/Form/Input.vue';
import Button from '@components/Form/Button.vue';
import Dropdown from '@components/Form/Dropdown.vue';
export default {
   name: 'CustomerDataDialog',
   components: { Dialog, Input, Button, Dropdown },
   props: {
      editCustomer: { required: false },
      transferTo: { required: false },
      top: { required: false },
      left: { required: false },
   },
   data() {
      return {
      }
   },
   methods: {
      onEditDataSave() {
         this.$emit('save', this.editCustomer)
      }
   },
   computed: {},

}
</script>

<style scoped>
.CustomerDataDialog {}

.dlgBody {
   display: flex;
   flex-direction: column;
   padding-top: 20px;
}

.colRow {
   display: flex;
   gap: 35px;
}

.col {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   gap: 10px;
   width: 350px;
}

.dlgBody .actionRow {
   display: flex;
   justify-content: space-around;
   margin-top: 20px;
}

.dlgBody .typeRow {
   display: flex;
   gap: 50px;
}

.Input.zip {
   width: 65px;
}

.Input.city {
   flex-grow: 1;
}

.spacer {
   height: 74px;
   width: 100%;
}
</style>